import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { fetchVisits, fetchPatients } from "./actions/index";
import moment from "moment";

import PDF from "./forms/osteopathy.pdf";

import AddIcon from "@material-ui/icons/Add";
import MessageIcon from "@material-ui/icons/Message";
import MailIcon from "@material-ui/icons/MailOutline";
import IconButton from "@material-ui/core/IconButton";

import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import Switch from "@material-ui/core/Switch";
import SignatureImage from "./img/sig.png";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
  BlobProvider,
} from "@react-pdf/renderer";
import Invoice from "./components/invoice";

import axios from "axios";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#7200B8",
    },
    info: {
      main: "#3177EA",
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

class Queue extends Component {
  constructor(props) {
    super(props);
    this.blobData = null;
    this.blobUrl = "";
    this.classes = props;
    this.state = {
      taxRate: 1.13,
      id: null,
      recordPaymentDialogueOpen: false,
      focusedVisit: {
        _id: "",
        notes: "First Visit",
        patient: {
          name: "loading..",
          cellPhone: "loading..",
          email: null,
          firstVisit: false,
          id: "loading",
        },
        date: { start: "", end: "" },
        service: [
          {
            type: "loading...",
            price: { gross: 0, tax: 0, net: 0 },
            paid: false,
            invoice: { yn: false, link: null },
            practitioner: { id: "", name: "Loading..." },
          },
        ],
        signIn: { yn: false, time: null, signature: null },
        signOut: { yn: false, time: null, signature: null },
        blocked: false,
        __v: 0,
      },
      subtotal: 0,
      totalPrice: 0,
      invoiceYN: false,
      paymentType: "",
      totalTime: "",
      includeTaxYN: false,
      search: null,
      patients: this.props.patients,
      insuranceInfo: "",
      chosenService: null,
      serviceChanged: false,
      services: [
        "Acupuncture",
        "Osteopathy",
        "Massage",
        "Herbs",
        "Products",
        "Other",
      ],
      // blob: '',
      // bloburl: '',
      visits: this.props.visits.filter(function (visit) {
        return !visit.blocked && !visit.rescheduled;
      }),
      // visits: this.props.visits.filter(function (visit) {
      //     let date = new Date(visit.date.start);
      //     let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      //     let endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
      //     return (date >= startDate && date <= endDate && !visit.blocked && !visit.rescheduled && !visit.service[0].paid);
      // })
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSubtotalChange = this.handleSubtotalChange.bind(this);
    this.handleNewEventServicesChange =
      this.handleNewEventServicesChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleTotalTimeChange = this.handleTotalTimeChange.bind(this);
    this.handleInsuranceInfoChange = this.handleInsuranceInfoChange.bind(this);
    this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
    this.handleInvoiceChange = this.handleInvoiceChange.bind(this);
  }

  // Create styles
  docStyles = StyleSheet.create({
    page: {
      // flexDirection: 'row',
      backgroundColor: "#ffffff",
      padding: 30,
      fontSize: 10,
    },
    section: {
      flexGrow: 1,
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginTop: 30,
      borderTop: "1px solid #E4E4E4",
      padding: 20,
    },
    table: {
      display: "table",
      width: "auto",
      // borderStyle: "solid",
      // borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      textAlign: "left",
    },
    tableRow: {
      // margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "25%",
      // borderStyle: "solid",
      // borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      // margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
  });

  returnAdminInfo = (id, _name) => {
    const admin = this.props.admins.find(({ _id }) => _id === id);
    const adminByName = this.props.admins.find(({ name }) => name === _name);
    if (admin) {
      const adminDetails = {
        name: admin.name,
        registration: admin.description,
        title: admin.title,
        hst: admin.hst ? true : false,
      };
      return adminDetails;
    } else if (adminByName) {
      const adminDetails = {
        name: adminByName.name,
        registration: adminByName.description,
        title: adminByName.title,
        hst: adminByName.hst ? true : false,
      };
      return adminDetails;
    } else {
      return {
        name: "Annie Zhu",
        title:
          "Registered Acupuncturist & Traditional Chinese Medicine Practitioner",
        hst: false,
        registration: "Reg#784",
      };
    }
  };

  returnPDF = (visit) => {
    console.log("return PDF", visit);
    const updatedVisit = {
      ...visit,
      service: [
        {
          ...visit.service[0],
          type: this.state.chosenService,
          price: {
            ...visit.service[0].price,
            gross: this.state.subtotal,
            net: this.state.totalPrice,
          },
          invoice: {
            ...visit.service[0].invoice,
            yn: this.state.invoiceYN,
            paymentMethod: this.state.paymentType,
            totalTime: this.state.totalTime,
            insuranceInfo: this.state.insuranceInfo,
          },
        },
      ],
    };

    return (
      <PDFDownloadLink
        style={{ textDecoration: "none", color: "#002BB8" }}
        document={
          <Invoice
            visit={updatedVisit}
            signature={this.returnAdminInfo(
              visit.service[0].practitioner.id,
              visit.service[0].practitioner.name
            )}
          />
        }
        fileName={"reciept-" + visit.patient.name + ".pdf"}
      >
        {({ blob, url, loading, error }) => {
          this.blobData = blob;
          this.blobUrl = url;
          // console.log(blob, url)
          return error ? String(error) : "Preview";
        }}
      </PDFDownloadLink>
    );
  };

  componentDidMount() {
    this.props.fetchVisits(
      moment().format("YYYY-MM-DD"),
      moment().add(1, "days").format("YYYY-MM-DD")
    );

    this.props.fetchPatients();
  }

  componentWillReceiveProps(nextState) {
    this.setState({
      visits: nextState.visits.filter(function (visit) {
        return !visit.blocked && !visit.rescheduled;
      }),
    });
  }

  MyDocument = () => (
    <Document>
      <Page size="A4" style={this.docStyles.page}>
        <View style={{ textAlign: "right" }}>
          <Text style={{ fontSize: 30 }}>Invoice</Text>
          <Text style={{ marginTop: 20 }}>Appleby Wellness Centre</Text>
          <Text style={{ paddingTop: 5 }}>
            4265 Thomas Alton Blvd,. Unit 208,. Burlington,. Ont L7M 0M9
          </Text>
          <Text style={{ paddingTop: 5 }}>+1 (289) 812-5518</Text>
        </View>
        <div style={this.docStyles.flex}>
          <View style={this.docStyles.section}>
            <Text style={{ opacity: 0.5, fontSize: 12 }}>BILL TO</Text>
            <Text style={{ paddingTop: 5 }}>
              {this.state.focusedVisit.patient.name}
            </Text>
            <Text style={{ paddingTop: 5 }}>
              {this.state.focusedVisit.patient.cellPhone}
            </Text>
            {/* <Text style={{ paddingTop: 5 }}>{this.state.focusedVisit.patient.email}</Text> */}
            {/* <Text>{new Date()}</Text> */}
            {/* <Text>{this.state.focusedVisit.service[0].type}</Text>
                        <Text>{this.state.focusedVisit.service[0].practitioner.name}</Text>
                        <Text>{this.state.subtotal}</Text>
                        <Text>{this.state.totalPrice}</Text>
                        <Text>{this.state.totalTime}</Text>
                        <Text>{this.state.insuranceInfo}</Text> */}
          </View>
        </div>
        <div style={[this.docStyles.flex, { padding: 0, paddingTop: 20 }]}>
          <View style={this.docStyles.section}>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                backgroundColor: "#424242",
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 20,
              }}
            >
              Date
            </Text>
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              {this.state.focusedVisit.date.start.slice(0, 10)}
            </Text>
          </View>
          <View style={this.docStyles.section}>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                backgroundColor: "#424242",
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 20,
              }}
            >
              Items
            </Text>
            {/* <Text style={{ paddingTop: 5, paddingLeft: 20 }}>{this.state.focusedVisit.service[0].type}</Text>
             */}
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              {this.state.chosenService}
            </Text>
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              ({this.state.totalTime} Mins)
            </Text>
          </View>
          <View style={this.docStyles.section}>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                backgroundColor: "#424242",
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 20,
              }}
            >
              Unit Price
            </Text>
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              ${this.state.subtotal}
            </Text>
          </View>
        </div>
        <div
          style={{
            marginTop: 50,
            borderTop: "1px solid #E4E4E4",
            padding: 20,
            textAlign: "right",
          }}
        >
          <View style={this.docStyles.table}>
            <View style={this.docStyles.tableRow}>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>Payment Type: </Text>
              </View>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>
                  {this.state.paymentType}
                </Text>
              </View>
            </View>
            {this.state.includeTaxYN && (
              <View style={this.docStyles.tableRow}>
                <View style={this.docStyles.tableCol}>
                  <Text style={this.docStyles.tableCell}>Tax (13%): </Text>
                </View>
                <View style={this.docStyles.tableCol}>
                  <Text style={this.docStyles.tableCell}>
                    $
                    {(
                      Math.round(
                        (this.state.totalPrice - this.state.subtotal) * 100
                      ) / 100
                    ).toFixed(2)}
                  </Text>
                </View>
              </View>
            )}
            <View style={this.docStyles.tableRow}>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>Subtotal: </Text>
              </View>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>
                  ${this.state.subtotal}
                </Text>
              </View>
            </View>
            <View style={this.docStyles.tableRow}>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>Total (CAD): </Text>
              </View>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>
                  ${this.state.totalPrice}
                </Text>
              </View>
            </View>
          </View>
        </div>

        <View
          style={{
            marginTop: 20,
            borderTop: "1px solid #E4E4E4",
            padding: 20,
            textAlign: "left",
          }}
        >
          {this.state.insuranceInfo && (
            <Text style={{ paddingTop: 5 }}>
              CC #: {this.state.insuranceInfo}
            </Text>
          )}

          {this.state.practitioner === "Lin Lin Wang" ? (
            <>
              <Text style={{ paddingTop: 5 }}>Lin Lin Wang (Reg#6807)</Text>
              <Text style={{ paddingTop: 5 }}>
                Registered Acupuncturist & Traditional Chinese Medicine
                Practitioner
              </Text>
              <Text style={{ paddingTop: 5 }}></Text>
              <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
              <Text style={{ paddingTop: 5 }}></Text>
            </>
          ) : this.state.practitioner === "Yanzhi Xu" ? (
            <>
              <Text style={{ paddingTop: 5 }}>Yanzhi Xu (Reg#7228)</Text>
              <Text style={{ paddingTop: 5 }}>
                Registered Acupuncturist & Traditional Chinese Medicine
                Practitioner
              </Text>
              <Text style={{ paddingTop: 5 }}></Text>
              <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
              <Text style={{ paddingTop: 5 }}>HST#: 860950526 RT0001</Text>
              <Text style={{ paddingTop: 5 }}></Text>
            </>
          ) : this.state.practitioner === "Naitong Guo" ? (
            <>
              <Text style={{ paddingTop: 5 }}>
                Naitong Guo (Reg No. B-3669)
              </Text>
              <Text style={{ paddingTop: 5 }}>
                Registered Massage Therapist
              </Text>
              <Text style={{ paddingTop: 5 }}></Text>
              <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
              <Text style={{ paddingTop: 5 }}>HST#: 860950526 RT0001</Text>
              <Text style={{ paddingTop: 5 }}></Text>
            </>
          ) : (
            <>
              {(this.state.chosenService === "Osteopathy" ||
                this.state.chosenService === "Massage") && (
                <>
                  <Text style={{ paddingTop: 5 }}>Li Gang Wang</Text>
                  {this.state.chosenService === "Osteopathy" ? (
                    <>
                      <Text style={{ paddingTop: 5 }}>
                        Osteopathic Manual Practitioner, RMT,.
                      </Text>
                      <Text style={{ paddingTop: 5 }}>
                        Reg No. DOMP-0316441 (O.O.A.M.A)
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={{ paddingTop: 5 }}>
                        Registered Massage Therapist
                      </Text>
                      <Text style={{ paddingTop: 5 }}>Reg No. P591</Text>
                    </>
                  )}
                  <Image style={{ width: 200 }} src={SignatureImage} />
                </>
              )}
              {this.state.chosenService === "Acupuncture" && (
                <>
                  <Text style={{ paddingTop: 5 }}>Annie YW. Zhu (Reg#784)</Text>
                  <Text style={{ paddingTop: 5 }}>
                    Registered Acupuncturist & Traditional Chinese Medicine
                    Practitioner
                  </Text>
                  <Text style={{ paddingTop: 5 }}></Text>
                </>
              )}
              {(this.state.chosenService === "Herbs" ||
                this.state.chosenService === "Products" ||
                this.state.chosenService === "Other") && (
                <>
                  <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
                  <Text style={{ paddingTop: 5 }}></Text>
                </>
              )}
              <Text style={{ paddingTop: 5 }}>HST#: 860950526 RT0001</Text>
            </>
          )}
          {/* {(this.state.focusedVisit.service[0].type === "Osteopathy" || this.state.focusedVisit.service[0].type === "Massage") ? */}
        </View>
      </Page>
    </Document>
  );

  handleSubtotalChange = (arg) => {
    this.setState({
      subtotal: arg.target.value,
    });
    if (this.state.includeTaxYN) {
      let tax = arg.target.value * this.state.taxRate;
      this.setState({
        totalPrice: (Math.round(tax * 100) / 100).toFixed(2),
      });
    } else {
      this.setState({
        totalPrice: arg.target.value,
      });
    }
  };

  handleTaxChange = (arg) => {
    this.setState({
      includeTaxYN: arg.target.checked,
    });
    if (this.state.totalPrice != 0) {
      if (arg.target.checked) {
        let tax = this.state.subtotal * this.state.taxRate;
        this.setState({
          totalPrice: (Math.round(tax * 100) / 100).toFixed(2),
        });
      } else {
        this.setState({
          totalPrice: this.state.subtotal,
        });
      }
    }
  };

  handleTotalTimeChange = (arg) => {
    this.setState({
      totalTime: arg.target.value,
    });
  };
  handleInsuranceInfoChange = (arg) => {
    this.setState({
      insuranceInfo: arg.target.value,
    });
  };
  handlePaymentTypeChange = (arg) => {
    this.setState({
      paymentType: arg.target.value,
    });
  };
  handleInvoiceChange = (arg) => {
    this.setState({
      invoiceYN: arg.target.checked,
    });
  };

  handleSearchChange = (arg) => {
    // console.log(arg)
    this.setState({ search: arg });
    if (arg) {
      this.props.history.push("/patient/" + arg._id);
    }
  };

  handleRecordPaymentClick = (visitID) => {
    // console.log(visitID)
    const selectedEvent = this.state.visits.find(({ _id }) => _id === visitID);
    console.log("selected Event", selectedEvent);
    this.setState({
      recordPaymentDialogueOpen: true,
      focusedVisit: this.state.visits.find(({ _id }) => _id === visitID),
      chosenService: this.state.visits.find(({ _id }) => _id === visitID)
        .service[0].type,
      subtotal: selectedEvent.service[0].price.gross,
      totalPrice: selectedEvent.service[0].price.net,
      invoiceYN: selectedEvent.service[0].invoice.yn,
      paymentType: selectedEvent.service[0].invoice.paymentMethod,
      totalTime: selectedEvent.service[0].invoice.totalTime,
      includeTaxYN: selectedEvent.service[0].price.taxYn,
      insuranceInfo: selectedEvent.service[0].invoice.insuranceInfo,
      practitioner: selectedEvent.service[0].practitioner.name,
    });
    console.log(selectedEvent.service[0].practitioner.name);
  };

  handleRecordPaymentClose = () => {
    this.setState({
      recordPaymentDialogueOpen: false,
      focusedVisit: {
        _id: "",
        notes: "First Visit",
        patient: {
          name: "loading..",
          cellPhone: "loading..",
          email: null,
          firstVisit: false,
          id: "loading",
        },
        date: { start: "", end: "" },
        service: [
          {
            type: "loading...",
            price: { gross: 0, tax: 0, net: 0 },
            paid: false,
            invoice: { yn: false, link: null },
            practitioner: { id: "", name: "Loading..." },
          },
        ],
        signIn: { yn: false, time: null, signature: null },
        signOut: { yn: false, time: null, signature: null },
        blocked: false,
        __v: 0,
      },
      includeTaxYN: null,
      subtotal: null,
      totalPrice: null,
      insuranceInfo: "",
      paymentType: null,
      totalTime: null,
      practitioner: null,
    });
  };

  handleViewReciept = () => {
    // ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
  };

  handleNewEventServicesChange = (arg) => {
    this.setState((prevState) => ({
      chosenService: arg,
      serviceChanged: true,
      // focusedVisit: {
      //     service[0]: {

      //     }
      //     ...prevState.focusedVisit,
      //     type: clickedDate.start,
      //     endTime: clickedDate.end,
      //     date: clickedDate.date,
      // }
    }));
  };

  blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  waitForBlob() {
    // blob behaves unpredictably - this can be improved.
    if (this.blobData !== null) {
      // console.log("new blob", this.blobData)
      this.blobToBase64(this.blobData).then((res) => {
        // do what you wanna do
        // console.log(res); // res is base64 now
        this.recordPaymentEvent(res);
        return this.blobData;
      });
    } else {
      // console.log("no new blob")
      setTimeout(this.waitForBlob, 250);
    }
  }

  handleRecordPaymentSubmit = () => {
    // turn out i don't need the pdf, can just rebuilt it when I need it with the data I have.
    this.waitForBlob();
    // this.recordPaymentEvent(pdfblob)
    // create submit payload and post
    // const blob = pdf(this.MyDocument).toBlob();
  };

  recordPaymentEvent(pdf) {
    console.log("hit payment event", this.state.focusedVisit);
    var promise = new Promise((resolve) => {
      var payload = {
        _id: this.state.focusedVisit._id,
        service: this.state.chosenService,
        email: this.state.focusedVisit.patient.email,
        price: {
          taxYn: this.state.includeTaxYN,
          gross: this.state.subtotal,
          tax: (
            Math.round((this.state.totalPrice - this.state.subtotal) * 100) /
            100
          ).toFixed(2),
          net: this.state.totalPrice,
        },
        invoice: {
          yn: this.state.invoiceYN,
          link: null,
          insuranceInfo: this.state.insuranceInfo,
          paymentMethod: this.state.paymentType,
          totalTime: this.state.totalTime,
        },
        pdf: pdf,
      };
      this.paymentEventReq(resolve, payload);
    });
    return promise;
  }

  paymentEventReq(resolve, payload) {
    // console.log("payload", payload)
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/payment", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // this.props.fetchVisits()
        this.props.fetchVisits(
          moment().format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD")
        );

        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("payment failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
          // console.log(response);
          // console.log('payment successful')
          this.setState({
            recordPaymentDialogueOpen: false,
            focusedVisit: {
              _id: "",
              notes: "First Visit",
              patient: {
                name: "loading..",
                cellPhone: "loading..",
                email: null,
                firstVisit: false,
                id: "loading",
              },
              date: { start: "", end: "" },
              service: [
                {
                  type: "loading...",
                  price: { gross: 0, tax: 0, net: 0 },
                  paid: false,
                  invoice: { yn: false, link: null },
                  practitioner: { id: "", name: "Loading..." },
                },
              ],
              signIn: { yn: false, time: null, signature: null },
              signOut: { yn: false, time: null, signature: null },
              blocked: false,
              __v: 0,
            },
            includeTaxYN: null,
            subtotal: null,
            totalPrice: null,
            insuranceInfo: "",
            paymentType: null,
            totalTime: null,
          });
          this.props.fetchVisits(
            moment().format("YYYY-MM-DD"),
            moment().add(1, "days").format("YYYY-MM-DD")
          );
          this.props.fetchPatients();
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row-reverse"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Autocomplete
                id="searchAuto"
                size="small"
                options={this.props.patients}
                getOptionLabel={(option) =>
                  option.intake.general.firstName +
                  " " +
                  option.intake.general.lastName
                }
                renderOption={(option) => (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1">
                        {option.intake.general.firstName +
                          " " +
                          option.intake.general.lastName}
                      </Typography>
                      <Typography variant="caption">
                        {option.intake.general.cellPhone}
                      </Typography>
                    </div>
                  </React.Fragment>
                )}
                style={{ width: 250 }}
                renderInput={(params) => (
                  <TextField {...params} label="Search" variant="outlined" />
                )}
                onChange={(event, newValue) =>
                  this.handleSearchChange(newValue)
                }
                value={this.state.search}
              />
            </Grid>
          </Grid>
          <hr
            style={{
              borderTopColor: "#cccccc",
              borderTop: 1,
              marginTop: 40,
              marginBottom: 40,
            }}
          />

          <Typography variant="overline" style={{ float: "left" }}>
            Unpaid Queue for: {moment().format("DD-MM-YYYY")}{" "}
          </Typography>

          {this.state.visits ? (
            <Grid container spacing={5} style={{ width: "100%" }}>
              {this.state.visits.filter((item) => !item.service[0].paid)
                .length > 0 ? (
                <>
                  {this.state.visits
                    .filter((item) => !item.service[0].paid)
                    .sort((a, b) =>
                      a.patient.name.localeCompare(b.patient.name)
                    )
                    .map((visit) => (
                      <Grid
                        key={visit.id}
                        item
                        xs={12}
                        style={{ width: "100%" }}
                      >
                        <Grid item xs={12} style={{ width: "100%" }}>
                          <Card
                            className={classes.root}
                            variant="outlined"
                            style={{ borderRadius: 20 }}
                          >
                            <CardContent style={{ textAlign: "left" }}>
                              <Typography
                                style={{ textAlign: "left", padding: 15 }}
                                variant="h4"
                                component="h2"
                              >
                                {visit.patient.name}
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  style={{ marginLeft: 20, float: "right" }}
                                  onClick={() =>
                                    this.handleRecordPaymentClick(visit._id)
                                  }
                                >
                                  Record Payment
                                </Button>
                              </Typography>
                              <div
                                style={{
                                  width: "100%",
                                  flexGrow: 1,
                                  textAlign: "left",
                                  padding: 15,
                                }}
                              >
                                <Grid container spacing={3}>
                                  <Grid item xs>
                                    <div>
                                      <Typography variant="overline">
                                        Service{" "}
                                      </Typography>
                                      <br />
                                      <br />
                                      <Typography variant="body1">
                                        {visit.service[0].type}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="overline">
                                      Practitioner
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography variant="body1">
                                      {visit.service[0].practitioner.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="overline">
                                      Price{" "}
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography variant="body1">~</Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="overline">
                                      Paid{" "}
                                    </Typography>
                                    <br />
                                    <br />
                                    {visit.service[0].paid ? (
                                      <CheckIcon style={{ color: "#00AD26" }} />
                                    ) : (
                                      <ClearIcon style={{ color: "#D20000" }} />
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    ))}
                </>
              ) : (
                <Typography
                  variant="body1"
                  style={{ marginLeft: 20, marginBottom: 20 }}
                >
                  All of today's patients have paid.
                </Typography>
              )}

              {this.state.visits.filter((item) => item.service[0].paid).length >
                0 && (
                <Typography
                  variant="overline"
                  style={{ marginLeft: 20, width: "100%" }}
                >
                  Paid Patients{" "}
                </Typography>
              )}

              {this.state.visits
                .filter((item) => item.service[0].paid)
                .sort((a, b) => a.patient.name.localeCompare(b.patient.name))
                .map((visit) => (
                  <Grid key={visit.id} item xs={12} style={{ width: "100%" }}>
                    <Grid item xs={12} style={{ width: "100%" }}>
                      <Card
                        className={classes.root}
                        variant="outlined"
                        style={{ borderRadius: 20 }}
                      >
                        <CardContent style={{ textAlign: "left" }}>
                          <Typography
                            style={{ textAlign: "left", padding: 15 }}
                            variant="h4"
                            component="h2"
                          >
                            {visit.patient.name}
                            <Button
                              style={{ marginLeft: 20, float: "right" }}
                              onClick={() =>
                                this.handleRecordPaymentClick(visit._id)
                              }
                            >
                              Edit Payment
                            </Button>
                          </Typography>
                          <div
                            style={{
                              width: "100%",
                              flexGrow: 1,
                              textAlign: "left",
                              padding: 15,
                            }}
                          >
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <div>
                                  <Typography variant="overline">
                                    Service{" "}
                                  </Typography>
                                  <br />
                                  <br />
                                  <Typography variant="body1">
                                    {visit.service[0].type}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs>
                                <Typography variant="overline">
                                  Practitioner
                                </Typography>
                                <br />
                                <br />
                                <Typography variant="body1">
                                  {visit.service[0].practitioner.name}
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <Typography variant="overline">
                                  Price{" "}
                                </Typography>
                                <br />
                                <br />
                                <Typography variant="body1">
                                  ${visit.service[0].price.net}
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <Typography variant="overline">
                                  Paid{" "}
                                </Typography>
                                <br />
                                <br />
                                {visit.service[0].paid ? (
                                  <CheckIcon style={{ color: "#00AD26" }} />
                                ) : (
                                  <ClearIcon style={{ color: "#D20000" }} />
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={5}
              style={{ width: "100%", marginLeft: 0 }}
            >
              <br />
              <br />
              <Typography variant="body1">
                There are no patients scheduled today.
              </Typography>
            </Grid>
          )}

          {/* Conditional Content */}
          <div>
            <Dialog
              open={this.state.recordPaymentDialogueOpen}
              onClose={this.handleRecordPaymentClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {this.state.focusedVisit.patient.name}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.state.services}
                      getOptionLabel={(option) => option}
                      style={{ width: 195 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Service"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleNewEventServicesChange(newValue)
                      }
                      value={this.state.chosenService}
                    />
                    {/* <Typography variant="overline">Service</Typography>
                                        <Typography variant="body1">{this.state.focusedVisit.service[0].type}</Typography> */}
                  </Grid>
                  <Grid item xs>
                    <Typography variant="overline">Practitioner</Typography>
                    <Typography variant="body1">
                      {this.state.focusedVisit.service[0].practitioner.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Typography variant="overline">Subtotal ($)</Typography>
                    <TextField
                      onChange={this.handleSubtotalChange}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      value={this.state.subtotal}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="overline">Include Tax?</Typography>
                    <br />
                    <Switch
                      checked={this.state.includeTaxYN}
                      onChange={this.handleTaxChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Typography variant="overline">
                      Total Time (mins)
                    </Typography>
                    <TextField
                      onChange={this.handleTotalTimeChange}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      value={this.state.totalTime}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="overline">Payment Method</Typography>
                    <br />
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.paymentType}
                        // onChange={}
                        label="Age"
                        onChange={this.handlePaymentTypeChange}
                      >
                        {/* <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem> */}
                        <MenuItem value="Debit">Debit</MenuItem>
                        <MenuItem value="Credit">Credit</MenuItem>
                        <MenuItem value="Cash">Cash</MenuItem>
                        <MenuItem value="Insurance">Insurance</MenuItem>
                        <MenuItem value="Insurance and Credit">
                          Insurance + Credit
                        </MenuItem>
                        <MenuItem value="Insurance and Debit">
                          Insurance + Debit
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Typography variant="overline">Total</Typography>
                    <Typography variant="body1">
                      {this.state.totalPrice}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="overline">Invoice?</Typography>
                    <br />
                    <Switch
                      checked={this.state.invoiceYN}
                      onChange={this.handleInvoiceChange}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Grid>
                </Grid>
                {(this.state.paymentType == "Insurance" ||
                  this.state.paymentType == "Insurance and Credit" ||
                  this.state.paymentType == "Insurance and Debit") && (
                  <>
                    <Typography variant="overline">Insurance Info</Typography>{" "}
                    <br />
                    <TextField
                      onChange={this.handleInsuranceInfoChange}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={this.state.insuranceInfo}
                    />
                  </>
                )}
                <DialogContentText id="alert-dialog-description"></DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* <IconButton color="primary" aria-label="add receipt">
                                    <AddIcon />
                                </IconButton> */}
                <Button color="primary">
                  {/* <a href={PDF} target="_blank" style={{ textDecoration: 'none' }}>
                                        Preview
                                 </a> */}

                  {/* <BlobProvider style={{ textDecoration: 'none', color: '#002BB8' }}  document={this.MyDocument}>
                                        {({ url }) => (
                                            <a href={(url != null) ? url : ""} target="_blank">Preview</a>
                                            // <a href={url} target="_blank">Preview</a>
                                        )}
                                    </BlobProvider> */}
                  {this.returnPDF(this.state.focusedVisit)}
                  {/* <PDFDownloadLink
                    style={{ textDecoration: "none", color: "#002BB8" }}
                    document={
                      <Invoice
                        visit={this.state.focusedVisit}
                        signature={this.returnAdminInfo(
                          this.state.focusedVisit.service[0].practitioner.id
                        )}
                      />
                    }
                    fileName={
                      "reciept-" + this.state.focusedVisit.patient.name + ".pdf"
                    }
                  >
                    {({ blob, url, loading, error }) => {
                      this.blobData = blob;
                      this.blobUrl = url;
                      // console.log(blob, url)
                      return error ? String(error) : "Preview";
                    }}
                  </PDFDownloadLink> */}
                </Button>
                {/* <Button onClick={this.handleRecordPaymentClose} color="primary">
                                    Send Receipt
                                </Button> */}
                <Button
                  variant="outlined"
                  onClick={this.handleRecordPaymentSubmit}
                  color="primary"
                >
                  {/* Submit */}
                  <BlobProvider document={this.MyDocument}>
                    {({ blob, url, loading, error }) => {
                      return <div>Submit</div>;
                    }}
                  </BlobProvider>
                </Button>
                <Button onClick={this.handleRecordPaymentClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ submitLogin: submitLogin, isLoggedIn: isLoggedIn },
//         dispatch);
// }

function mapStateToProps(state) {
  return {
    visits: state.visits,
    patients: state.patients,
    admins: state.admins,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
  })
)(withRouter(Queue));
