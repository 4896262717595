import React, { useState } from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import logo from "./img/logo.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Unsubscribe() {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/unsubscribe`,
        { email }
      );
      setMessage("You have been successfully unsubscribed!");
      setSeverity("success");
      setEmail("");
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setSeverity("error");
    }
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          my: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: "150px", height: "auto" }}
        />
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          Unsubscribe
        </Typography>
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          variant="body1"
          gutterBottom
        >
          Enter your email address to unsubscribe from our mailing list.
        </Typography>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" variant="outlined" color="primary" fullWidth>
            Unsubscribe
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default Unsubscribe;
