import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { Route } from "react-router-dom";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/AssignmentInd";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ReactExport from "react-export-excel-xlsx-fix";
import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import randomColor from "randomcolor";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { red, blue } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { CSVLink, CSVDownload } from "react-csv";

import Moment from "react-moment";

import { fetchVisits, fetchPatients, fetchAdmins } from "./actions/index";

const service_list = [
  {
    Title: "Acupuncture",
    Type: "Treatment",
    Principal: "Annie Zhu",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
  {
    Title: "Massage",
    Type: "Treatment",
    Principal: "Raymond Wang",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
  {
    Title: "Osteopathy",
    Type: "Treatment",
    Principal: "Raymond Wang",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
  {
    Title: "Herbs",
    Type: "Product",
    Principal: "Raymond Wang",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
  {
    Title: "Products",
    Type: "Product",
    Principal: "Raymond Wang",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
  {
    Title: "Physiotherapy",
    Type: "Treatment",
    Principal: "Raymond Wang",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
  {
    Title: "Chiropractic Therapy",
    Type: "Treatment",
    Principal: "Raymond Wang",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
  {
    Title: "Natural Herb Homeopathy",
    Type: "Treatment",
    Principal: "Raymond Wang",
    CostFor30Mins: 65,
    CostFor45Mins: 85,
    CostFor60Mins: 120,
  },
];

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#7200B8",
    },
    info: {
      main: "#3177EA",
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 500,
    minWidth: 350,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: blue[500],
  },
});

class Services extends Component {
  csvLink = React.createRef();
  ExcelLink = React.createRef();

  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      downloadReady: false,
      open: false,
      search: null,
      patients: this.props.patients,
      visits: this.props.visits,
      sheet: [],
      acupunctureSheet: [],
      osteopathySheet: [],
      massageSheet: [],
      herbsSheet: [],
      productsSheet: [],
      otherSheet: [],
      actions: [],
      columns: [
        "Visit ID",
        "Patient ID",
        "Patient Name",
        "Date",
        "Practitioner",
        "Service",
        "Price Gross",
        "Price Tax",
        "Price Net",
        "Paid?",
        "Invoice?",
      ],
      columnsPat: ["First Name", "Last Name", "Phone", "Email"],
      patientsCSV: [],
      counter: 0,
      colors: randomColor({
        count: 100,
        hue: "purple",
        luminosity: "light",
      }),
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleSearchChange = (arg) => {
    // // console.log(arg)
    this.setState({ search: arg });
    if (arg) {
      this.props.history.push("/patient/" + arg._id);
    }
  };

  getVisitData = () => {
    // reset excel download sheets
    this.setState({
      sheet: [],
      acupunctureSheet: [],
      osteopathySheet: [],
      massageSheet: [],
      herbsSheet: [],
      productsSheet: [],
      otherSheet: [],
    });
    // console.log("visits fired", this.props.visits.length);
    var visitsClean = new Promise((resolve, reject) => {
      this.props.visits.forEach((value, index, array) => {
        this.visitsToExcelConversion(value);
        if (index === this.props.visits.length - 1) resolve();
      });
    });

    visitsClean.then(() => {
      // console.log("All done!");
      this.setState({
        downloadReady: true,
      });
      // this.ExcelLink.click();
    });
  };

  getPatientData = () => {
    var patientsClean = new Promise((resolve, reject) => {
      this.props.patients.forEach((value, index, array) => {
        this.patientToCSVConversion(value);
        if (index === this.props.patients.length - 1) resolve();
      });
    });

    patientsClean.then(() => {
      // console.log("All done!");
      this.csvLink.current.link.click();
    });
  };

  componentDidMount() {
    this.props.fetchPatients(); //.then(() => {this.patientsPrePro()})
    this.props.fetchVisits(); //.then(() => {this.visitsPrePro()})
  }

  componentWillReceiveProps(nextState) {
    console.log("next state", nextState.visits);
    this.setState({
      patients: nextState.patients,
      visits: nextState.visits,
    });
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
    // // console.log(this.state.sheetPat);
    // // console.log(this.props.visits);
    // if (this.state.sheetPat) {
    //     // console.log("hit")
    //     this.patientsPrePro()
    // }
    // if(!this.props.visits){
    //     this.visitsPrePro()
    // }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row-reverse"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Autocomplete
                id="searchAuto"
                size="small"
                options={this.props.patients}
                getOptionLabel={(option) =>
                  option.intake.general.firstName +
                  " " +
                  option.intake.general.lastName
                }
                renderOption={(option) => (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1">
                        {option.intake.general.firstName +
                          " " +
                          option.intake.general.lastName}
                      </Typography>
                      <Typography variant="caption">
                        {option.intake.general.cellPhone}
                      </Typography>
                    </div>
                  </React.Fragment>
                )}
                style={{ width: 250 }}
                renderInput={(params) => (
                  <TextField {...params} label="Search" variant="outlined" />
                )}
                onChange={(event, newValue) =>
                  this.handleSearchChange(newValue)
                }
                value={this.state.search}
              />
            </Grid>
          </Grid>
          <hr
            style={{
              borderTopColor: "#cccccc",
              borderTop: 1,
              marginTop: 40,
              marginBottom: 40,
            }}
          />

          <Grid
            container
            justify="left"
            spacing={5}
            style={{ paddingLeft: 70, paddingRight: 70 }}
          >
            {service_list.map((service) => (
              <Grid key={service.name} item>
                {/* {
                    Title: "Massage",
                    Type: "Treatment",
                    Principal: "Raymond Wang",
                    CostFor30Mins: 65,
                    CostFor45Mins: 85,
                    CostFor60Mins: 120,
                }, */}
                <Card className={classes.root} variant="outlined">
                  <CardHeader
                    avatar={
                      <Avatar aria-label="default" className={classes.avatar}>
                        {service.Title[0]}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={service.Title}
                    subheader={service.Type}
                  />
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography>Principal Practitioner</Typography>
                      <Typography>{service.Principal}</Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography>Cost for 30 Mins</Typography>
                      <Typography>{service.CostFor30Mins}</Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography>Cost for 45 Mins</Typography>
                      <Typography>{service.CostFor45Mins}</Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography>Cost for 60 Mins</Typography>
                      <Typography>{service.CostFor60Mins}</Typography>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {/* <Button
                      //   onClick={}
                      size="small"
                    >
                      Edit
                    </Button> */}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ submitLogin: submitLogin, isLoggedIn: isLoggedIn },
//         dispatch);
// }

function mapStateToProps(state) {
  return {
    patients: state.patients,
    visits: state.visits,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
  })
)(withRouter(Services));
