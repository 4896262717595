import React, { useState } from "react";
import axios from "axios"; // Import Axios

function Administrator() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    console.log("Email: ", email);
    console.log("Subject: ", subject);
    try {
      let payload = {
        email: email,
        email_subject: subject,
      };
      axios
        .post(process.env.REACT_APP_DOMAIN + "/admins/send-email", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          // Should send a response of 200 ***
          // resolve(response);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          var errorMsg = "Missing data";
          // Should send a response of 400 ***
          // resolve(errorMsg);
        });
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Email Address:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Subject:</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </div>
      <button type="submit">Send Email</button>
    </form>
  );
}

export default Administrator;
