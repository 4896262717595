import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";
import SpeedDial from "@material-ui/lab/SpeedDial";
import axios from "axios";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import SignatureCanvas from "react-signature-canvas";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useParams,
} from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";

import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

import Moment from "react-moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#7200B8",
    },
    info: {
      main: "#3177EA",
    },
  },
});

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

class VisitLog extends Component {
  constructor(props) {
    // console.log(
    //     "Hit Visit Log"
    // )
    super(props);
    this.classes = props;
    this.state = {
      id: null,
      search: null,
      moreInfoDialogueOpen: false,
      visits: "",
      patients: "",
      patient: "",
    };
  }
  componentDidMount() {
    this.setState({
      id: this.props.match.params.id,
    });
    // console.log("Prop", this.props.match.params.id)

    // get all visits for a particular patient
    axios
      .get(
        process.env.REACT_APP_DOMAIN +
          `/visits/patient/${this.props.match.params.id}`
      )
      .then(({ data }) => {
        // console.log("visits", data)
        this.setState({
          visits: data.filter(function (visit) {
            return !visit.blocked && !visit.rescheduled && !visit.noshow;
          }),
        });
      });
  }

  componentWillReceiveProps(nextState) {
    // console.log('next state', nextState.visits)
    // this.setState({
    //     patient: nextState.patients.find(({ _id }) => _id === this.props.match.params.id),
    //     visits: nextState.visits.filter(({ patient }) => patient.id === this.props.match.params.id).filter(function (visit) {
    //         return (!visit.blocked && !visit.rescheduled && !visit.noshow);
    //     }),
    //     // visits: nextState.visits.filter(function (visit) {
    //     //     return (!visit.blocked && !visit.rescheduled && !visit.noshow && (visit.patient.id === this.props.match.params.id));
    //     // })
    // })
  }

  handleBackClick = (arg) => {
    this.props.history.push("/patient/" + this.props.match.params.id);
    // this.props.history.push("/admin/intake/" + this.state.focusedVisit.patient.id + "/admin" );
    // this.setState({
    //     moreInfoDialogueOpen: true
    // })
  };

  handleVisitLogPress = (arg) => {
    //navigate to page with visit log data
  };

  render() {
    const { classes } = this.props;

    // console.log(this.state.visits)
    return (
      <>
        {!this.state.visits ? (
          <div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: 500,
                height: 200,
                marginTop: -100,
                marginLeft: -250,
                textAlign: "center",
              }}
            >
              <Typography component="h1" style={{ fontSize: 25 }}>
                Welcome to Appleby Wellness
              </Typography>
              <Typography component="p" style={{ fontWeight: "lighter" }}>
                Loading in your information.
              </Typography>
              <CircularProgress style={{ margin: "3rem" }} />
            </div>
          </div>
        ) : (
          <>
            <div>
              <ThemeProvider theme={theme}>
                <div style={{ margin: 30 }}>
                  <Typography
                    style={{ textAlign: "left" }}
                    variant="h4"
                    id="title"
                  >
                    Patient Log
                  </Typography>
                  <Button
                    onClick={this.handleBackClick}
                    style={{ float: "right", marginTop: -40 }}
                    variant="outlined"
                    color="primary"
                    disableElevation
                  >
                    Back
                  </Button>

                  <TableContainer>
                    <Table
                      aria-label="simple table"
                      style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Service</TableCell>
                          <TableCell>Sign In Time</TableCell>
                          <TableCell>Signature</TableCell>
                          <TableCell>Sign Out Time</TableCell>
                          <TableCell>Signature</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.visits.map((visit) => (
                          <TableRow key={visit._id}>
                            <TableCell component="th" scope="row">
                              {visit.date.start.slice(0, 10)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {visit.service[0].type}
                            </TableCell>
                            {visit.signIn.yn ? (
                              <TableCell>
                                {new Date(visit.signIn.time).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour12: false,
                                  }
                                )}
                              </TableCell>
                            ) : (
                              <TableCell>NA</TableCell>
                            )}
                            {visit.signIn.yn ? (
                              <TableCell>
                                {/* <SignatureCanvas penColor='black' ref={(ref) => { this.sigCanvas2 = ref }}
                                                                    canvasProps={{ width: 240, height: 240, className: 'sigCanvas2' }} />
                                                                {this.sigCanvas2.fromDataURL(visit.signIn.signature, { width: 240, height: 240 })} */}
                                <img
                                  src={visit.signIn.signature}
                                  style={{ width: 260, height: 120 }}
                                />
                              </TableCell>
                            ) : (
                              <TableCell>NA</TableCell>
                            )}
                            {visit.signOut.yn ? (
                              <TableCell>
                                {new Date(
                                  visit.signOut.time
                                ).toLocaleTimeString("en-US", {
                                  hour12: false,
                                })}
                              </TableCell>
                            ) : (
                              <TableCell>NA</TableCell>
                            )}
                            {visit.signOut.yn ? (
                              <TableCell>
                                {" "}
                                <img
                                  src={visit.signOut.signature}
                                  style={{ width: 260, height: 120 }}
                                />
                              </TableCell>
                            ) : (
                              <TableCell>NA</TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </ThemeProvider>
            </div>
          </>
        )}
      </>
    );
  }
}

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ submitLogin: submitLogin, isLoggedIn: isLoggedIn },
//         dispatch);
// }

function mapStateToProps(state) {
  return {
    visits: state.visits,
    patients: state.patients,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(withRouter(VisitLog));
