import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grid from "@material-ui/core/Grid";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  fetchVisits,
  fetchAdmins,
  fetchPatients,
  getBackendURL,
  successfulAuth,
} from "./actions/index";

let url = process.env.REACT_APP_DOMAIN;

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      email: "",
      password: "",
      error: "",
      name: "",
      key: "",
      displayError: false,
      userVerified: false,
      admin: this.props.admin,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.signUp = this.signUp.bind(this);
    this.signUpReq = this.signUpReq.bind(this);
  }

  componentWillMount = () => {
    // Sets the values of tags in the head
    document.title = "Sign Up";
    let metaTags = document.getElementsByTagName("meta");
    for (let i = 0; i < metaTags.length; i++) {
      if (metaTags[i].name === "description") {
        metaTags[i].content = "Sign Up to Organica CMS";
      }
    }
  };

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleKeyChange(event) {
    this.setState({ key: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  /* Attempt to log in the user upon submit. */
  handleSubmit(event) {
    this.signUp().then((errorMsg) => {
      // console.log("submit hit");
      // Redirect and save username is no errors with sign up.
    });
  }

  signUp() {
    var promise = new Promise((resolve) => {
      var payload = {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        key: this.state.key,
      };
      this.signUpReq(resolve, payload);
    });
    return promise;
  }

  signUpReq(resolve, payload) {
    axios
      .post(url + "/users", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("signup failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
          this.setState({
            displayError: true,
          });
        } else {
          resolve("");
          // console.log(response);
          // console.log('signup succeeded!')
          this.props.successfulAuth(
            response.data.user.email,
            response.data.user._id,
            response.data.user.name
          );
          localStorage.setItem("token", response.data.token);
          // set user info to redux
          this.setState({
            userVerified: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
        this.setState({
          displayError: true,
        });
      });
  }

  render() {
    if (this.state.userVerified === true) {
      return <Redirect to="/calendar" />;
    } else {
      return (
        <div>
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: 100, padding: 20 }}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                  WELCOME TO
                </Typography>
                <Typography
                  variant="h4"
                  style={{ textAlign: "left", marginTop: 20 }}
                >
                  Appleby Wellness Clinic
                </Typography>
                <br />
                <hr style={{ opacity: "20%" }} />
                <br />
                <TextField
                  ref="name"
                  label="Full Name"
                  id="margin-none"
                  className="name"
                  onChange={this.handleNameChange}
                  style={{ width: "100%", marginTop: 30 }}
                  variant={"outlined"}
                />

                <TextField
                  ref="email"
                  label="Email"
                  id="margin-none"
                  className="email"
                  onChange={this.handleEmailChange}
                  style={{ width: "100%", marginTop: 20 }}
                  variant={"outlined"}
                />
                <br />
                <TextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  type={this.state.showPassword ? "text" : "password"}
                  label="Password"
                  value={this.state.password}
                  className="password"
                  onChange={this.handlePasswordChange}
                  style={{ width: "100%", borderRadius: 20, marginTop: 20 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  ref="key"
                  label="Key"
                  id="margin-none"
                  className="key"
                  onChange={this.handleKeyChange}
                  style={{ width: "100%", marginTop: 20 }}
                  variant={"outlined"}
                />
                <br />

                {this.state.displayError && (
                  <p style={{ color: "red" }}>Please check your information.</p>
                )}

                <Button
                  style={{
                    marginTop: 30,
                    width: 120,
                    borderRadius: 20,
                    color: "002BB8",
                  }}
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  Sign Up
                </Button>
                <br />
                <br />
                <hr style={{ opacity: "20%" }} />
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}
export default connect(mapStateToProps, {
  fetchVisits,
  fetchAdmins,
  fetchPatients,
  successfulAuth,
})(SignUp);
