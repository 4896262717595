import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Route } from "react-router-dom";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/AssignmentInd";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ReactExport from "react-export-excel-xlsx-fix";
import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import randomColor from "randomcolor";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { red, blue } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { CSVLink, CSVDownload } from "react-csv";

import Moment from "react-moment";

import { fetchVisits, fetchPatients, fetchAdmins } from "./actions/index";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";

import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";
import Invoice from "./components/invoice";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#7200B8",
    },
    info: {
      main: "#3177EA",
    },
  },
});

const userTypes = [
  {
    value: "User",
  },
  {
    value: "Administrator",
  },
  {
    value: "Developer",
  },
];

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 400,
    // minWidth: 350,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: blue[500],
  },
});

class Practioners extends Component {
  csvLink = React.createRef();
  ExcelLink = React.createRef();
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      downloadReady: false,
      open: false,
      search: null,
      patients: this.props.patients,
      visits: this.props.visits,
      sheet: [],
      acupunctureSheet: [],
      osteopathySheet: [],
      massageSheet: [],
      herbsSheet: [],
      productsSheet: [],
      otherSheet: [],
      actions: [],
      columns: [
        "Visit ID",
        "Patient ID",
        "Patient Name",
        "Date",
        "Practitioner",
        "Service",
        "Price Gross",
        "Price Tax",
        "Price Net",
        "Paid?",
        "Invoice?",
      ],
      columnsPat: ["First Name", "Last Name", "Phone", "Email"],
      patientsCSV: [],
      counter: 0,
      colors: randomColor({
        count: 100,
        hue: "purple",
        luminosity: "bright",
      }),
      createPractitionerDialog: false,
      editPractitionerDialog: false,
      dropdownValues: "User",
      editAdmin: {
        id: "",
        name: "",
        phone: null,
        permissions: "",
        title: "",
        registration: "",
        inactive: false,
        hst: "",
      },
      addAdmin: {
        email: "",
        name: "",
        phone: null,
        permissions: "",
        registration: "",
        title: "",
        pwd: "", // pwd defined during admin adding.
        pwdConfirm: "",
        hst: "",
      },
      inactive_admins: [],
      snackbar: false,
      snackbarMessage: "",
      snackbarVariant: "success",
      demoVisit: {
        _id: {
          $oid: "63162cd831bd6ed57d01b7f3",
        },
        notes: null,
        patient: {
          name: "Johnny Appleseed",
          cellPhone: "(123) 456-7890",
          email: null,
          firstVisit: true,
          id: "63162cd831bd6ed57d01b7f2",
        },
        date: {
          start: "2022-09-12T15:00:00.000Z",
          end: "2022-09-12T16:00:00.000Z",
        },
        service: [
          {
            type: "Acupuncture",
            price: {
              gross: 100,
              tax: 20,
              net: 120,
            },
            paid: true,
            invoice: {
              yn: false,
              link: null,
            },
            practitioner: {
              id: "5eb4ee1d5dbe6e7975aacfa8",
              name: "Jane Doe",
            },
          },
        ],
        signIn: {
          yn: false,
          time: null,
          signature: null,
        },
        signOut: {
          yn: false,
          time: null,
          signature: null,
        },
        blocked: false,
        __v: 0,
      },
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.createPractitionerDialogPress =
      this.createPractitionerDialogPress.bind(this);
    this.editPractitionerDialogPress =
      this.editPractitionerDialogPress.bind(this);
    this.dropdownValuesEvent.bind(this);
    this.handleEditAdminNameChange = this.handleEditAdminNameChange.bind(this);
    this.handleEditAdminTitleChange =
      this.handleEditAdminTitleChange.bind(this);
    this.handleEditAdminPhoneChange =
      this.handleEditAdminPhoneChange.bind(this);
    this.handleEditAdminRegistrationChange =
      this.handleEditAdminRegistrationChange.bind(this);
    this.handleEditAdminPermissionsChange =
      this.handleEditAdminPermissionsChange.bind(this);

    this.handleAddAdminNameChange = this.handleAddAdminNameChange.bind(this);
    this.handleAddAdminTitleChange = this.handleAddAdminTitleChange.bind(this);
    this.handleAddAdminPwdChange = this.handleAddAdminPwdChange.bind(this);
    this.handleAddAdminPwdConfirmChange =
      this.handleAddAdminPwdConfirmChange.bind(this);

    this.handleAddAdminPhoneChange = this.handleAddAdminPhoneChange.bind(this);
    this.handleAddAdminRegistrationChange =
      this.handleAddAdminRegistrationChange.bind(this);
    this.handleAddAdminEmailChange = this.handleAddAdminEmailChange.bind(this);
  }

  handleAddAdminHstChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        hst: stringVal,
      },
    }));
  };

  handleEditAdminHstChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      editAdmin: {
        ...prevState.editAdmin,
        hst: stringVal,
      },
    }));
  };

  handleEditAdminNameChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      editAdmin: {
        ...prevState.editAdmin,
        name: stringVal,
      },
    }));
  };

  handleEditAdminTitleChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      editAdmin: {
        ...prevState.editAdmin,
        title: stringVal,
      },
    }));
  };

  handleEditAdminPhoneChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      editAdmin: {
        ...prevState.editAdmin,
        phone: stringVal,
      },
    }));
  };

  handleEditAdminRegistrationChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      editAdmin: {
        ...prevState.editAdmin,
        registration: stringVal,
      },
    }));
  };

  handleEditAdminPermissionsChange = (event) => {
    console.log("open");
    this.setState((prevState) => ({
      editAdmin: {
        ...prevState.editAdmin,
        permissions: event.target.value,
      },
    }));
  };

  handleAddAdminNameChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        name: stringVal,
      },
    }));
  };

  handleAddAdminTitleChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        title: stringVal,
      },
    }));
  };

  handleAddAdminPwdChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        pwd: stringVal,
      },
    }));
  };

  handleAddAdminPwdConfirmChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        pwdConfirm: stringVal,
      },
    }));
  };

  handleAddAdminEmailChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        email: stringVal,
      },
    }));
  };

  handleAddAdminPhoneChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        phone: stringVal,
      },
    }));
  };

  handleAddAdminRegistrationChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        registration: stringVal,
      },
    }));
  };

  dropdownValuesEvent = (event) => {
    console.log(event.target.value);
    this.setState((prevState) => ({
      addAdmin: {
        ...prevState.addAdmin,
        permissions: event.target.value,
      },
    }));
  };

  editAdminSaveClick = () => {
    console.log("edit admin state", this.state.editAdmin);
    var promise = new Promise((resolve) => {
      var payload = {
        id: this.state.editAdmin.id,
        name: this.state.editAdmin.name,
        phone: this.state.editAdmin.phone,
        permissions: this.state.editAdmin.permissions,
        description: this.state.editAdmin.registration,
        title: this.state.editAdmin.title,
        hst: this.state.editAdmin.hst,
      };
      console.log(payload);
      this.editAdminReq(resolve, payload);
    });
    return promise;
  };

  editAdminReq(resolve, payload) {
    console.log("URL", process.env.REACT_APP_DOMAIN + "/admins/edit");
    console.log("payload", payload);
    axios
      .post(process.env.REACT_APP_DOMAIN + "/admins/edit", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.editPractitionerDialogPress();
        console.log(response);

        this.props.fetchAdmins();
        if (response.data.successful === false) {
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  deleteAdminSaveClick = () => {
    console.log("DELETE ADMIN STATE", this.state.editAdmin);
    var promise = new Promise((resolve) => {
      var payload = {
        id: this.state.editAdmin.id,
      };
      console.log(payload);
      this.deleteAdminReq(resolve, payload);
    });
    return promise;
  };

  deleteAdminReq(resolve, payload) {
    console.log("URL", process.env.REACT_APP_DOMAIN + "/admins/create");
    console.log("payload", payload);
    axios
      .post(process.env.REACT_APP_DOMAIN + "/admins/delete", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.editPractitionerDialogPress();
        console.log(response);

        this.props.fetchAdmins();
        this.getInactiveAdmins();
        if (response.data.successful === false) {
          var errorMsg = response.data.reason;
          this.setSnackbarState(true, "Error! Please try again.", "error");
          resolve(errorMsg);
        } else {
          resolve(response);
          this.setSnackbarState(true, "Practitioner Removed!", "success");
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        this.setSnackbarState(true, "Error! Please try again.", "error");

        resolve(errorMsg);
      });
  }

  setSnackbarState = (arg, message, variant) => {
    this.setState({
      snackbar: arg,
      snackbarMessage: message,
      snackbarVariant: variant,
    });
  };

  reactivateAdminSaveClick = () => {
    console.log("REACTIVATE ADMIN STATE", this.state.editAdmin);
    var promise = new Promise((resolve) => {
      var payload = {
        id: this.state.editAdmin.id,
      };
      console.log(payload);
      this.reactivateAdminReq(resolve, payload);
    });
    return promise;
  };

  reactivateAdminReq(resolve, payload) {
    console.log("URL", process.env.REACT_APP_DOMAIN + "/admins/reactivate");
    console.log("payload", payload);
    axios
      .post(process.env.REACT_APP_DOMAIN + "/admins/reactivate", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.editPractitionerDialogPress();
        console.log(response);

        this.props.fetchAdmins();
        this.getInactiveAdmins();
        if (response.data.successful === false) {
          var errorMsg = response.data.reason;
          this.setSnackbarState(true, "Error! Please try again.", "error");
          resolve(errorMsg);
        } else {
          this.setSnackbarState(true, "Practitioner Reactivated", "success");
          resolve(response);
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        this.setSnackbarState(true, "Error! Please try again.", "error");
        resolve(errorMsg);
      });
  }

  // CREATE ADMIN
  addAdminSaveClick = () => {
    console.log("add admin state", this.state.addAdmin);
    var promise = new Promise((resolve) => {
      var payload = {
        email: this.state.addAdmin.email,
        name: this.state.addAdmin.name,
        phone: this.state.addAdmin.phone,
        permissions: this.state.addAdmin.permissions,
        description: this.state.addAdmin.registration,
        password: this.state.addAdmin.pwd,
        title: this.state.addAdmin.title,
        hst: this.state.addAdmin.hst,
      };
      console.log(payload);
      this.addAdminReq(resolve, payload);
    });
    return promise;
  };

  addAdminReq(resolve, payload) {
    console.log("URL", process.env.REACT_APP_DOMAIN + "/admins/create");
    console.log("payload", payload);
    axios
      .post(process.env.REACT_APP_DOMAIN + "/admins/create", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.createPractitionerDialogPress();
        console.log(response);

        this.props.fetchAdmins();
        if (response.data.successful === false) {
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  handleSearchChange = (arg) => {
    // // console.log(arg)
    this.setState({ search: arg });
    if (arg) {
      this.props.history.push("/patient/" + arg._id);
    }
  };

  createPractitionerDialogPress = (arg) => {
    console.log("open");
    this.setState({
      createPractitionerDialog: !this.state.createPractitionerDialog,
    });
  };

  editPractitionerDialogPress = (arg, inactive) => {
    console.log("open");
    if (this.state.editPractitionerDialog) {
      // forget edit admin context
      this.setState({
        editAdmin: {
          id: "",
          name: "",
          phone: null,
          permissions: "",
          registration: "",
          title: "",
          hst: "",
        },
      });
    } else {
      // add current admin context
      console.log(arg);
      this.setState({
        editAdmin: {
          id: arg._id,
          name: arg.name,
          phone: arg.phone,
          permissions: arg.permissions,
          registration: arg.description,
          title: arg.title,
          hst: arg.hst,
          inactive: inactive,
        },
      });
    }
    this.setState({
      editPractitionerDialog: !this.state.editPractitionerDialog,
    });
  };

  getVisitData = () => {
    // reset excel download sheets
    this.setState({
      sheet: [],
      acupunctureSheet: [],
      osteopathySheet: [],
      massageSheet: [],
      herbsSheet: [],
      productsSheet: [],
      otherSheet: [],
    });
    // console.log("visits fired", this.props.visits.length);
    var visitsClean = new Promise((resolve, reject) => {
      this.props.visits.forEach((value, index, array) => {
        this.visitsToExcelConversion(value);
        if (index === this.props.visits.length - 1) resolve();
      });
    });

    visitsClean.then(() => {
      // console.log("All done!");
      this.setState({
        downloadReady: true,
      });
      // this.ExcelLink.click();
    });
  };

  getPatientData = () => {
    var patientsClean = new Promise((resolve, reject) => {
      this.props.patients.forEach((value, index, array) => {
        this.patientToCSVConversion(value);
        if (index === this.props.patients.length - 1) resolve();
      });
    });

    patientsClean.then(() => {
      // console.log("All done!");
      this.csvLink.current.link.click();
    });
  };

  componentDidMount() {
    this.props.fetchPatients(); //.then(() => {this.patientsPrePro()})
    this.props.fetchVisits(); //.then(() => {this.visitsPrePro()})
    this.getInactiveAdmins();
  }

  componentWillReceiveProps(nextState) {
    // console.log("next state", nextState.visits);
    this.setState({
      patients: nextState.patients,
      visits: nextState.visits,
    });
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  getInactiveAdmins = () => {
    axios
      .get(process.env.REACT_APP_DOMAIN + `/admins/get-inactive`)
      .then(({ data }) => {
        console.log("inactive admins", data);
        this.setState({
          inactive_admins: data,
        });
      });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
    // // console.log(this.state.sheetPat);
    // // console.log(this.props.visits);
    // if (this.state.sheetPat) {
    //     // console.log("hit")
    //     this.patientsPrePro()
    // }
    // if(!this.props.visits){
    //     this.visitsPrePro()
    // }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row-reverse"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Autocomplete
                id="searchAuto"
                size="small"
                options={this.props.patients}
                getOptionLabel={(option) =>
                  option.intake.general.firstName +
                  " " +
                  option.intake.general.lastName
                }
                renderOption={(option) => (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1">
                        {option.intake.general.firstName +
                          " " +
                          option.intake.general.lastName}
                      </Typography>
                      <Typography variant="caption">
                        {option.intake.general.cellPhone}
                      </Typography>
                    </div>
                  </React.Fragment>
                )}
                style={{ width: 250 }}
                renderInput={(params) => (
                  <TextField {...params} label="Search" variant="outlined" />
                )}
                onChange={(event, newValue) =>
                  this.handleSearchChange(newValue)
                }
                value={this.state.search}
              />
            </Grid>
          </Grid>
          <hr
            style={{
              borderTopColor: "#cccccc",
              borderTop: 1,
              marginTop: 40,
              marginBottom: 40,
            }}
          />
          <div style={{ width: "100%" }}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="row"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1} style={{ textAlign: "left" }}>
                <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
                  Appleby Wellness
                </Typography>
                <Typography variant="h3">Practitioners</Typography>
              </Box>
              <Box p={1}>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  endIcon={<AddIcon />}
                  onClick={this.createPractitionerDialogPress}
                >
                  Add Practitioner
                </Button>
              </Box>
            </Box>
          </div>
          <hr
            style={{
              borderTopColor: "#cccccc",
              borderTop: 1,
              marginTop: 40,
              // marginBottom: 40,
            }}
          />
          <br />
          <br />
          <br />

          <Grid container justify="left" spacing={5} style={{}}>
            {this.props.admins.map((practitioner) => (
              <Grid key={practitioner.name} item xs={4} xl={3}>
                <Card className={classes.root} variant="outlined">
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">
                        <EditIcon
                          onClick={() =>
                            this.editPractitionerDialogPress(practitioner)
                          }
                          style={{ color: "white" }}
                        />
                      </IconButton>
                    }
                    style={{
                      color: "white",
                      backgroundColor: randomColor({
                        seed: practitioner.name,
                        hue: "random",
                        luminosity: "dark",
                      }),
                    }}
                    title={practitioner.name}
                    subheader={
                      practitioner.permissions
                        ? practitioner.permissions
                        : "No Permissions Specified"
                    }
                    subheaderTypographyProps={{ color: "white", opacity: 0.56 }}
                  />
                  <CardContent>
                    {/* <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      
                    </Grid> */}
                    {/* <br /> */}
                    <Typography variant="overline">Title</Typography>
                    <Typography variant="body1">
                      {practitioner.title
                        ? practitioner.title
                        : "No title added"}
                    </Typography>
                    <br />
                    <Typography variant="overline">Email</Typography>
                    <Typography variant="body1">
                      {practitioner.email
                        ? practitioner.email
                        : "No email added"}
                    </Typography>
                    <br />
                    <Typography variant="overline">Phone #</Typography>
                    <Typography variant="body1">
                      {practitioner.phone
                        ? practitioner.phone
                        : "No phone added"}
                    </Typography>
                    <br />
                    <Typography variant="overline">Registration</Typography>
                    <Typography variant="body1">
                      {practitioner.description
                        ? practitioner.description
                        : "Registration # goes here."}
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button
                      //   onClick={}
                      size="small"
                    >
                      Edit
                    </Button>
                  </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>

          <Typography variant="h5" style={{ marginTop: 40 }}>
            Inactive Practitioners
          </Typography>
          <br />

          <Grid container justify="left" spacing={5} style={{}}>
            {this.state.inactive_admins.map((practitioner) => (
              <Grid key={practitioner.name} item xs={4} xl={3}>
                <Card className={classes.root} variant="outlined">
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">
                        <EditIcon
                          onClick={() =>
                            this.editPractitionerDialogPress(practitioner, true)
                          }
                          style={{ color: "black" }}
                        />
                      </IconButton>
                    }
                    style={{
                      color: "black",
                      backgroundColor: "#EAEAEA",
                    }}
                    title={practitioner.name}
                    subheader={"Inactive: " + practitioner.permissions}
                    // subheaderTypographyProps={{ color: "white", opacity: 0.56 }}
                  />
                  <CardContent>
                    <Typography variant="overline">Title</Typography>
                    <Typography variant="body1">
                      {practitioner.title
                        ? practitioner.title
                        : "No title added"}
                    </Typography>
                    <br />
                    <Typography variant="overline">Email</Typography>
                    <Typography variant="body1">
                      {practitioner.email
                        ? practitioner.email
                        : "No email added"}
                    </Typography>
                    <br />
                    <Typography variant="overline">Phone #</Typography>
                    <Typography variant="body1">
                      {practitioner.phone
                        ? practitioner.phone
                        : "No phone added"}
                    </Typography>
                    <br />
                    <Typography variant="overline">Registration</Typography>
                    <Typography variant="body1">
                      {practitioner.description
                        ? practitioner.description
                        : "Registration # goes here."}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={this.state.createPractitionerDialog}
            onClose={this.createPractitionerDialogPress}
          >
            <DialogTitle>Add a Practitioner</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fill in the information below to add a new practitioner. They
                will be sent an email with their login credentials.
              </DialogContentText>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    id="name add"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    // style={{ marginBottom: "10px" }}
                    onChange={this.handleAddAdminNameChange}
                    value={this.state.addAdmin.name}
                  />
                </Grid>
                <Grid item xs={6} spacing={2}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    label="Role"
                    value={this.state.addAdmin.permissions}
                    onChange={this.dropdownValuesEvent}
                    fullWidth
                    // style={{ marginBottom: "10px" }}
                  >
                    {userTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                  <TextField
                    id="email add"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                    size="normal"
                    onChange={this.handleAddAdminEmailChange}
                    value={this.state.addAdmin.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="phone add"
                    label="Phone #"
                    type="tel"
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                    size="normal"
                    onChange={this.handleAddAdminPhoneChange}
                    value={this.state.addAdmin.phone}
                  />
                </Grid>
              </Grid>
              <TextField
                id="title add"
                label="Full Title"
                type="text"
                fullWidth
                variant="outlined"
                style={{ marginTop: "10px" }}
                onChange={this.handleAddAdminTitleChange}
                value={this.state.addAdmin.title}
              />

              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                  <TextField
                    id="registration add"
                    label="Registration #"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleAddAdminRegistrationChange}
                    value={this.state.addAdmin.registration}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    id="hst add"
                    label="HST #"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleAddAdminHstChange}
                    value={this.state.addAdmin.hst}
                  />
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.addAdmin.hst}
                        onChange={this.handleAddAdminHstChange}
                        name="hst"
                        color="primary"
                      />
                    }
                    label="Include HST #"
                  /> */}
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                  <TextField
                    id="pwd add"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleAddAdminPwdChange}
                    value={this.state.addAdmin.pwd}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="pwd add"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleAddAdminPwdConfirmChange}
                    value={this.state.addAdmin.pwdConfirm}
                  />
                </Grid>
              </Grid>

              {this.state.addAdmin.pwdConfirm.length > 4 &&
                this.state.addAdmin.pwdConfirm !== this.state.addAdmin.pwd && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Passwords do not match!
                  </Typography>
                )}
              {this.state.addAdmin.pwdConfirm.length > 4 &&
                this.state.addAdmin.pwdConfirm === this.state.addAdmin.pwd && (
                  <Typography variant="caption" style={{ color: "green" }}>
                    Passwords match!
                  </Typography>
                )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.createPractitionerDialogPress}>
                Cancel
              </Button>
              {this.state.addAdmin.name && this.state.addAdmin.title ? (
                <PDFDownloadLink
                  style={{
                    textDecoration: "none",
                    color: "#000",
                  }}
                  document={
                    <Invoice
                      visit={this.state.demoVisit}
                      signature={{
                        name: this.state.addAdmin.name,
                        registration: this.state.addAdmin.registration,
                        title: this.state.addAdmin.title,
                        hst: this.state.addAdmin.hst,
                      }}
                    />
                  }
                  fileName={"demo-receipt.pdf"}
                >
                  {({ error }) => {
                    return error ? String(error) : "PREVIEW INVOICE";
                  }}
                </PDFDownloadLink>
              ) : (
                <Button disabled onClick={this.addAdminPreviewClick}>
                  Preview Invoice
                </Button>
              )}
              {this.state.addAdmin.pwdConfirm.length > 4 &&
              this.state.addAdmin.pwdConfirm === this.state.addAdmin.pwd &&
              this.state.addAdmin.email &&
              this.state.addAdmin.name &&
              this.state.addAdmin.permissions &&
              this.state.addAdmin.phone &&
              this.state.addAdmin.title ? (
                <Button onClick={this.addAdminSaveClick}>Create</Button>
              ) : (
                <Button disabled onClick={this.addAdminSaveClick}>
                  Create
                </Button>
              )}
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.editPractitionerDialog}
            onClose={this.editPractitionerDialogPress}
          >
            <DialogTitle>Edit Practitioner</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Here you can edit details and permissions linked to a
                practitioner. Please note that "User" permissions will only
                allow the practitioner to see the Calendar and Patients tabs.
              </DialogContentText>
              <br />

              <TextField
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
                onChange={this.handleEditAdminNameChange}
                value={this.state.editAdmin.name}
                disabled={this.state.editAdmin.inactive}
              />
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                label="Role"
                onChange={this.handleEditAdminPermissionsChange}
                value={this.state.editAdmin.permissions}
                // value={this.state.dropdownValues}
                // onChange={this.dropdownValuesEvent}
                fullWidth
                style={{ marginBottom: "10px" }}
                disabled={this.state.editAdmin.inactive}
              >
                {userTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="phone"
                label="Phone #"
                type="tel"
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
                size="normal"
                onChange={this.handleEditAdminPhoneChange}
                value={this.state.editAdmin.phone}
                disabled={this.state.editAdmin.inactive}
              />

              <TextField
                id="name"
                label="Full Title"
                type="text"
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
                onChange={this.handleEditAdminTitleChange}
                value={this.state.editAdmin.title}
                disabled={this.state.editAdmin.inactive}
              />
              <Grid container spacing={2} style={{}}>
                <Grid item xs={6}>
                  <TextField
                    id="registration add"
                    label="Registration #"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleEditAdminRegistrationChange}
                    value={this.state.editAdmin.registration}
                    disabled={this.state.editAdmin.inactive}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    id="hst add"
                    label="HST #"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleEditAdminHstChange}
                    value={this.state.editAdmin.hst}
                    disabled={this.state.editAdmin.inactive}
                  />
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.editAdmin.hst}
                        onChange={this.handleEditAdminHstChange}
                        name="hst"
                        color="primary"
                      />
                    }
                    label="Include HST #"
                  /> */}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {this.state.editAdmin.inactive ? (
                <Button
                  onClick={this.reactivateAdminSaveClick}
                  style={{ color: "green" }}
                >
                  REACTIVATE
                </Button>
              ) : (
                <Button
                  onClick={this.deleteAdminSaveClick}
                  style={{ color: "red" }}
                >
                  DELETE
                </Button>
              )}
              {this.state.editAdmin.name && this.state.editAdmin.title ? (
                <PDFDownloadLink
                  style={{
                    textDecoration: "none",
                    color: "#000",
                  }}
                  document={
                    <Invoice
                      visit={this.state.demoVisit}
                      signature={{
                        name: this.state.editAdmin.name,
                        registration: this.state.editAdmin.registration,
                        title: this.state.editAdmin.title,
                        hst: this.state.editAdmin.hst,
                      }}
                    />
                  }
                  fileName={"demo-receipt.pdf"}
                >
                  {({ error }) => {
                    return error ? String(error) : "PREVIEW INVOICE";
                  }}
                </PDFDownloadLink>
              ) : (
                <Button disabled>Preview Invoice</Button>
              )}
              <Button onClick={this.editPractitionerDialogPress}>Cancel</Button>
              <Button
                disabled={this.state.editAdmin.inactive}
                onClick={this.editAdminSaveClick}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.snackbar}
            autoHideDuration={3000}
            onClose={() => {
              this.setSnackbarState(false);
            }}
          >
            <this.Alert severity={this.state.snackbarVariant}>
              {this.state.snackbarMessage}
            </this.Alert>
          </Snackbar>
        </ThemeProvider>
      </div>
    );
  }
}

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ submitLogin: submitLogin, isLoggedIn: isLoggedIn },
//         dispatch);
// }

function mapStateToProps(state) {
  return {
    patients: state.patients,
    visits: state.visits,
    admins: state.admins,
    backendURL: state.url,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
    fetchAdmins,
  }),
  withRouter
)(Practioners);
