import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  fetchVisits,
  fetchAdmins,
  fetchPatients,
  getBackendURL,
  successfulAuth,
} from "./actions/index";

let url = process.env.REACT_APP_DOMAIN;

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      email: "",
      password: "",
      error: "",
      isAuthenticated: false,
      badAuth: false,
      admin: this.props.admin,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.login = this.login.bind(this);
    this.loginReq = this.loginReq.bind(this);
  }

  componentWillMount = () => {
    // Sets the values of tags in the head
    document.title = "Login";
    let metaTags = document.getElementsByTagName("meta");
    for (let i = 0; i < metaTags.length; i++) {
      if (metaTags[i].name === "description") {
        metaTags[i].content = "Login to Organica CMS";
      }
    }
  };

  componentWillReceiveProps(nextState) {
    // load in all calendar events on init
    // console.log('next state', nextState.admin)
    this.setState({
      admin: nextState.admin,
    });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  /* Attempt to log in the user upon submit. */
  handleSubmit(event) {
    this.login().then((errorMsg) => {
      // console.log("submit hit");
      // Redirect and save username if no errors with login.
    });
  }

  login() {
    var promise = new Promise((resolve) => {
      var payload = {
        email: this.state.email,
        password: this.state.password,
      };
      this.loginReq(resolve, payload);
    });
    return promise;
  }

  handleTempSubmit = () => {
    const { history } = this.props;
    history.push("/calendar");
  };

  loginReq(resolve, payload) {
    axios
      .post(url + "/users/login", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.successful === false) {
          // console.log("login failed")
          this.setState({ badAuth: true });
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          // console.log(response);
          this.props.successfulAuth(
            response.data.user.email,
            response.data.user._id,
            response.data.user.name,
            response.data.user.permissions
          );
          localStorage.setItem("token", response.data.token);
          this.setState({ isAuthenticated: true });
          // set user info to redux
          // console.log('login succeeded')
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        this.setState({ badAuth: true });
        resolve(errorMsg);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.admin.loggedIn && <Redirect to="/calendar" />}
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginTop: 100, padding: 20 }}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                WELCOME TO
              </Typography>
              <Typography
                variant="h4"
                style={{ textAlign: "left", marginTop: 20 }}
              >
                Appleby Wellness Clinic
              </Typography>
              <br />
              <hr style={{ opacity: "20%" }} />
              <br />
              <TextField
                ref="email"
                label="Email"
                id="margin-none"
                className="email"
                onChange={this.handleEmailChange}
                style={{ width: "100%", marginTop: 50 }}
                variant={"outlined"}
              />
              <br />
              <br />
              <br />
              <TextField
                id="outlined-adornment-password"
                variant="outlined"
                type={this.state.showPassword ? "text" : "password"}
                label="Password"
                value={this.state.password}
                className="password"
                onChange={this.handlePasswordChange}
                style={{ width: "100%", borderRadius: 20 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              {this.state.badAuth && (
                <p style={{ color: "red" }}>Invalid Information</p>
              )}

              <Button
                style={{
                  marginTop: 50,
                  width: 120,
                  borderRadius: 20,
                  color: "002BB8",
                }}
                type="submit"
                variant="contained"
                disableElevation
                color="primary"
                onClick={this.handleSubmit}
              >
                Log in
              </Button>
              <br />
              <br />
              <hr style={{ opacity: "20%" }} />
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}
export default connect(mapStateToProps, {
  fetchVisits,
  fetchAdmins,
  fetchPatients,
  successfulAuth,
})(Login);
