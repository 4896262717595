import React from "react";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";
import SignatureImage from "../img/sig.png";

// Create styles
const docStyles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    backgroundColor: "#ffffff",
    padding: 30,
    fontSize: 10,
  },
  section: {
    flexGrow: 1,
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 30,
    borderTop: "1px solid #E4E4E4",
    padding: 20,
  },
  table: {
    display: "table",
    width: "auto",
    // borderStyle: "solid",
    // borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    textAlign: "left",
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    // borderStyle: "solid",
    // borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
});

const Invoice = ({ visit, signature }) => (
  <Document>
    <Page size="A4" style={docStyles.page}>
      <View style={{ textAlign: "right" }}>
        <Text style={{ fontSize: 30 }}>Invoice</Text>
        <Text style={{ marginTop: 20 }}>Appleby Wellness Centre</Text>
        <Text style={{ paddingTop: 5 }}>
          4265 Thomas Alton Blvd,. Unit 208,. Burlington,. Ont L7M 0M9
        </Text>
        <Text style={{ paddingTop: 5 }}>+1 (289) 812-5518</Text>
      </View>
      <div style={docStyles.flex}>
        <View style={docStyles.section}>
          <Text style={{ opacity: 0.5, fontSize: 12 }}>BILL TO</Text>
          <Text style={{ paddingTop: 5 }}>{visit.patient.name}</Text>
          <Text style={{ paddingTop: 5 }}>{visit.patient.cellPhone}</Text>
        </View>
      </div>
      <div style={[docStyles.flex, { padding: 0, paddingTop: 20 }]}>
        <View style={docStyles.section}>
          <Text
            style={{
              color: "white",
              fontSize: 12,
              backgroundColor: "#424242",
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 20,
            }}
          >
            Date
          </Text>
          <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
            {visit.date.start.slice(0, 10)}
          </Text>
        </View>
        <View style={docStyles.section}>
          <Text
            style={{
              color: "white",
              fontSize: 12,
              backgroundColor: "#424242",
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 20,
            }}
          >
            Items
          </Text>
          <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
            {visit.service[0].type}
          </Text>
          <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
            ({visit.service[0].invoice.totalTime} Mins)
          </Text>
        </View>
        <View style={docStyles.section}>
          <Text
            style={{
              color: "white",
              fontSize: 12,
              backgroundColor: "#424242",
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 20,
            }}
          >
            Unit Price
          </Text>
          <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
            ${visit.service[0].price.gross}
          </Text>
        </View>
      </div>
      <div
        style={{
          marginTop: 50,
          borderTop: "1px solid #E4E4E4",
          padding: 20,
          textAlign: "right",
        }}
      >
        <View style={docStyles.table}>
          <View style={docStyles.tableRow}>
            <View style={docStyles.tableCol}>
              <Text style={docStyles.tableCell}>Payment Type: </Text>
            </View>
            <View style={docStyles.tableCol}>
              <Text style={docStyles.tableCell}>
                {visit.service[0].invoice.paymentMethod}
              </Text>
            </View>
          </View>
          <View style={docStyles.tableRow}>
            <View style={docStyles.tableCol}>
              <Text style={docStyles.tableCell}>Subtotal: </Text>
            </View>
            <View style={docStyles.tableCol}>
              <Text style={docStyles.tableCell}>
                ${visit.service[0].price.gross}
              </Text>
            </View>
          </View>
          {visit.service[0].price.taxYn && (
            <View style={docStyles.tableRow}>
              <View style={docStyles.tableCol}>
                <Text style={docStyles.tableCell}>Tax (13%): </Text>
              </View>
              <View style={docStyles.tableCol}>
                <Text style={docStyles.tableCell}>
                  ${visit.service[0].price.tax}
                </Text>
              </View>
            </View>
          )}
          <View style={docStyles.tableRow}>
            <View style={docStyles.tableCol}>
              <Text style={docStyles.tableCell}>Total (CAD): </Text>
            </View>
            <View style={docStyles.tableCol}>
              <Text style={docStyles.tableCell}>
                ${visit.service[0].price.net}
              </Text>
            </View>
          </View>
        </View>
      </div>

      <View
        style={{
          marginTop: 20,
          borderTop: "1px solid #E4E4E4",
          padding: 20,
          textAlign: "left",
        }}
      >
        <>
          {visit.service[0].invoice.insuranceInfo && (
            <Text style={{ paddingTop: 5 }}>
              CC #: {visit.service[0].invoice.insuranceInfo}
            </Text>
          )}
          {visit.service[0].practitioner.name != "Annie Zhu" &&
          visit.service[0].practitioner.name != "Raymond Wang" ? (
            <>
              <Text style={{ paddingTop: 5 }}>
                {signature.name}
                {signature.registration ? (
                  <> - {`(${signature.registration})`}</>
                ) : (
                  ""
                )}
              </Text>
              <Text style={{ paddingTop: 5 }}>
                {signature.title ? <>{signature.title}</> : ""}
              </Text>
              <Text style={{ paddingTop: 5 }}></Text>
              <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
              {signature.hst && (
                <Text style={{ paddingTop: 5 }}>HST#: {signature.hst} </Text>
              )}
              <Text style={{ paddingTop: 5 }}></Text>
            </>
          ) : (
            <>
              {(visit.service[0].type === "Osteopathy" ||
                visit.service[0].type === "Massage") && (
                <>
                  <Text style={{ paddingTop: 5 }}>Li Gang Wang</Text>
                  {visit.service[0].type === "Osteopathy" ? (
                    <>
                      <Text style={{ paddingTop: 5 }}>
                        Osteopathic Manual Practitioner, RMT,.
                      </Text>
                      <Text style={{ paddingTop: 5 }}>
                        Reg No. DOMP-0316441 (O.O.A.M.A)
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={{ paddingTop: 5 }}>
                        Registered Massage Therapist
                      </Text>
                      <Text style={{ paddingTop: 5 }}>Reg No. P591</Text>
                    </>
                  )}
                  <Image style={{ width: 200 }} src={SignatureImage} />
                </>
              )}
              {visit.service[0].type === "Acupuncture" && (
                <>
                  <Text style={{ paddingTop: 5 }}>Annie YW. Zhu (Reg#784)</Text>
                  <Text style={{ paddingTop: 5 }}>
                    Registered Acupuncturist & Traditional Chinese Medicine
                    Practitioner
                  </Text>
                  <Text style={{ paddingTop: 5 }}></Text>
                </>
              )}
              {(visit.service[0].type === "Herbs" ||
                visit.service[0].type === "Products" ||
                visit.service[0].type === "Other") && (
                <>
                  <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
                  <Text style={{ paddingTop: 5 }}></Text>
                </>
              )}
              <Text style={{ paddingTop: 5 }}>HST#: 860950526 RT0001</Text>
            </>
          )}
        </>
      </View>
    </Page>
  </Document>
);

export default Invoice;
