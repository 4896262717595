import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import ZoomIcon from "@material-ui/icons/ZoomIn";
import axios from "axios";
import MessageIcon from "@material-ui/icons/Message";
import MailIcon from "@material-ui/icons/MailOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import TrashIcon from "@material-ui/icons/DeleteOutline";
import QueueIcon from "@material-ui/icons/Queue";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useState } from "react";

import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { TimePicker } from "@material-ui/pickers";

import Chip from "@material-ui/core/Chip";

import MaskedInput from "react-text-mask";
import InputMask from "react-input-mask";

import { fetchVisits, fetchPatients, fetchAdmins } from "./actions/index";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

const eventColors = [
  "#FFA726",
  "#EF0E78",
  "#388e3c",
  "#002BB8",
  "#9F00D7",
  "#B74E5B",
  "#F2786C",
  "#75B9C1",
  "#E89363",
];

// const eventColors = [
//   "#C247FC",
//   "#FCBE62",
//   "#EF0E78",
//   "#F1519C",
//   "#6FAC72",
//   "#4766CA",
//   "#F2786C",
//   "#75B9C1",
//   "#E89363",
// ];
const eventColorsAccented = [
  "#7501AC",
  "#CA7903",
  "#A71057",
  "#265F29",
  "#031B6B",
  "#A62B1F",
  "#193C40",
];

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    // height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    // <MaskedInput
    //     {...other}
    //     ref={(ref) => {
    //         inputRef(ref ? ref.inputElement : null);
    //     }}
    //     mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    //     placeholderChar={'\u2000'}
    //     showMask
    // />
    <MaskedInput
      {...other}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholder="Enter a phone number"
      guide={false}
      id="my-input-id"
      // onBlur={() => { }}
      // onChange={() => { }}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.props.fetchPatients();
    this.classes = props;
    this.state = {
      SMSSuccessOpen: false,
      url: this.props.url,
      open: false,
      search: null,
      blockedModalOpen: false,
      eventDialogueOpen: false,
      eventDialogueID: "",
      eventDialogueName: "",
      eventDialoguePhone: "",
      eventDialogueStartTime: "",
      eventDialogueEndTime: "",
      eventDialogueTreatment: [],
      eventDialoguePractitioner: [],
      eventDialogueEmail: "",
      eventDialogueDescription: "",
      eventDialogueReassessment: false,
      calendarWeekends: true,
      calendarEvents: [],
      masterEvents: [],
      visits: this.props.visits,
      newEventModal: false,
      checkedNewPatient: false,
      newPatientName: "",
      patients: this.props.patients,
      services: [
        "Acupuncture",
        "Osteopathy",
        "Massage",
        "Herbs",
        "Products",
        "Physiotherapy",
        "Chiropractic Therapy",
        "Natural Herb Homeopathy",
        "Other",
      ],
      practitioners: [],
      admins: this.props.admins,
      newEvent: {
        newPatientName: "",
        newPatientPhone: "",
        date: null,
        startTime: null,
        endTime: null,
        services: [],
        patient: null,
        practitioner: [],
        notes: null,
        reassessment: false,
      },
      eventIDToEdit: null,
      setColor: 0,
      currentDate: new Date(),
    };

    this.handleChangeCheckedNewPatient =
      this.handleChangeCheckedNewPatient.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleNewPatientNameChange =
      this.handleNewPatientNameChange.bind(this);
    this.handleNewPatientPhoneChange =
      this.handleNewPatientPhoneChange.bind(this);
    this.handleNewEventDateChange = this.handleNewEventDateChange.bind(this);
    this.handleNewEventStartTimeChange =
      this.handleNewEventStartTimeChange.bind(this);
    this.handleNewEventEndTimeChange =
      this.handleNewEventEndTimeChange.bind(this);
    this.handleNewEventServicesChange =
      this.handleNewEventServicesChange.bind(this);
    this.handleNewEventPatientChange =
      this.handleNewEventPatientChange.bind(this);
    this.handleNewEventPractitionerChange =
      this.handleNewEventPractitionerChange.bind(this);
    this.handleNewEventNotesChange = this.handleNewEventNotesChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  calendarComponentRef = React.createRef();

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleCheckBoxChange = (event) => {
    console.log("clicked", event.target.checked);
    let boolvar = event.target.checked;
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        reassessment: boolvar,
      },
    }));
  };

  populateAdmins = (admin) => {
    // console.log("hit admin prepro", admin);
    this.setState((prevState) => ({
      practitioners: [
        ...prevState.practitioners,
        {
          id: admin._id,
          title: admin.name,
        },
      ],
    }));
  };

  adminsPrePro(admins) {
    admins.forEach(this.populateAdmins);
  }

  populateCalendar = (value, index, array) => {
    // // console.log("resourceID: ", value.resourceId)
    if (value.blocked) {
      this.setState((prevState) => ({
        calendarEvents: [
          ...prevState.calendarEvents,
          {
            id: value.id,
            title: value.title,
            start: value.startTime,
            end: value.endTime,
            backgroundColor: value.color,
            borderColor: "#F8F8F8",
            resourceId: value.resourceId,
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        calendarEvents: [
          ...prevState.calendarEvents,
          {
            id: value.id,
            title: value.name + " " + value.phone,
            start: value.startTime,
            end: value.endTime,
            backgroundColor: value.color,
            borderColor: "#F8F8F8",
            resourceId: value.resourceId,
          },
        ],
      }));
    }

    // // console.log(this.state.calendarEvents)
    // // console.log(value)
  };

  determineColor(visit) {
    if (visit.noshow) {
      return "#8599DD";
    }
    // else if (visit.patient.firstVisit) {
    //   // always assign the default colour. If it is a first visit, then append some text to the event title instead.
    //   if (visit.service[0].type === "Acupuncture") {
    //     return eventColorsAccented[1];
    //   } else if (visit.service[0].type === "Massage") {
    //     return eventColorsAccented[2];
    //   } else if (visit.service[0].type === "Osteopathy") {
    //     return eventColorsAccented[3];
    //   } else if (visit.service[0].type === "Herbs") {
    //     return eventColorsAccented[4];
    //   } else if (visit.service[0].type === "Products") {
    //     return eventColorsAccented[5];
    //   } else if (visit.service[0].type === "Chiropractic Therapy") {
    //     return eventColorsAccented[6];
    //   } else {
    //     return eventColorsAccented[0];
    //   }
    // }
    else {
      if (visit.service[0].type === "Acupuncture") {
        return eventColors[1];
      } else if (visit.service[0].type === "Massage") {
        return eventColors[2];
      } else if (visit.service[0].type === "Osteopathy") {
        return eventColors[3];
      } else if (visit.service[0].type === "Herbs") {
        return eventColors[4];
      } else if (visit.service[0].type === "Products") {
        return eventColors[5];
      } else if (visit.service[0].type === "Chiropractic Therapy") {
        return eventColors[6];
      } else if (visit.service[0].type === "Physiotherapy") {
        return eventColors[7];
      } else if (visit.service[0].type === "Natural Herb Homeopathy") {
        return eventColors[8];
      } else {
        return eventColors[0];
      }
    }
    // services: [
    //   "Acupuncture",
    //   "Osteopathy",
    //   "Massage",
    //   "Herbs",
    //   "Products",
    //   "Physiotherapy",
    //   "Chiropractic Therapy",
    //   "Natural Herb Homeopathy",
    //   "Other",
    // ],
    // else if (
    //   visit.service[0].type === "Osteopathy" &&
    //   visit.patient.firstVisit
    // ) {
    //   color = eventColors[3];
    // } else if (visit.service[0].type === "Osteopathy") {
    //   color = eventColors[3];
    // } else if (visit.service[0].type === "Acupuncture") {
    //   color = eventColors[1];
    // } else if (visit.service[0].type === "Massage") {
    //   color = eventColors[2];
    // } else {
    //   color = eventColors[0];
    // }
  }

  calendarPrePro(visits) {
    let transformedEvents = [];
    visits.forEach((visit) => {
      // // console.log("visit in prepro", visit)
      if (this.state.admins.length > 0) {
        // only process visits related to the User if User is not Admin
        if (
          this.props.admin.permissions === "Administrator" ||
          this.props.admin.permissions === "Developer" ||
          (this.props.admin.permissions === "User" &&
            this.props.admin.name === visit.service[0].practitioner.name)
        ) {
          if (visit.blocked) {
            // special blocked event consideration

            //find associated admin to event
            //   console.log("there are admins", this.state.admins);
            let resourceID = this.state.admins.find(
              (o) => o.name === visit.service[0].practitioner.name
            )._id;
            transformedEvents.push({
              id: visit._id,
              title: "Blocked - " + visit.service[0].practitioner.name,
              startTime: visit.date.start,
              endTime: visit.date.end,
              color: "#454545",
              resourceId: resourceID,
              description: visit.notes,
              blocked: true,
            });
            // this.setState(prevState => ({
            //     calendarEvents: [...prevState.calendarEvents, {
            //         id: visit._id,
            //         title: "Blocked - " + visit.service[0].practitioner.name,
            //         start: visit.date.start,
            //         end: visit.date.end,
            //         backgroundColor: "#454545",
            //         resourceId: visit.service[0].practitioner.id,
            //         description: visit.notes,
            //     }]
            // }))
          } else if (!visit.rescheduled) {
            // // console.log(visit)
            let services = [];
            visit.service.forEach((service) => {
              services.push(service.type);
            });
            let practitioners = [];
            visit.service.forEach((service) => {
              practitioners.push(service.practitioner);
            });
            let color = this.determineColor(visit);
            if (visit) {
              console.log(visit);

              try {
                let resourceID = this.state.admins.find(
                  (o) => o.name === visit.service[0].practitioner.name
                )._id;
                if (visit.initial) {
                  // prepend that the session is the first
                  transformedEvents.push({
                    id: visit._id,
                    name: "[INITIAL] - " + visit.patient.name,
                    patientID: visit.patient.id,
                    phone: visit.patient.cellPhone,
                    email: visit.patient.email,
                    treatment: services,
                    practitioner: practitioners,
                    description: visit.notes,
                    startTime: visit.date.start,
                    endTime: visit.date.end,
                    color: color,
                    resourceId: resourceID,
                    blocked: false,
                    reassessment: false,
                  });
                } else if (visit.reassessment) {
                  // prepend that the session is a reassessment
                  transformedEvents.push({
                    id: visit._id,
                    name: "[RE] - " + visit.patient.name,
                    patientID: visit.patient.id,
                    phone: visit.patient.cellPhone,
                    email: visit.patient.email,
                    treatment: services,
                    practitioner: practitioners,
                    description: visit.notes,
                    startTime: visit.date.start,
                    endTime: visit.date.end,
                    color: color,
                    resourceId: resourceID,
                    blocked: false,
                    reassessment: true,
                  });
                } else {
                  transformedEvents.push({
                    id: visit._id,
                    name: visit.patient.name,
                    patientID: visit.patient.id,
                    phone: visit.patient.cellPhone,
                    email: visit.patient.email,
                    treatment: services,
                    practitioner: practitioners,
                    description: visit.notes,
                    startTime: visit.date.start,
                    endTime: visit.date.end,
                    color: color,
                    resourceId: resourceID,
                    blocked: false,
                    reassessment: false,
                  });
                }
              } catch (exceptionVar) {
                let resourceID = "NO_ID";
                if (visit.initial) {
                  // prepend that the session is the first
                  transformedEvents.push({
                    id: visit._id,
                    name: "[INITIAL] - " + visit.patient.name,
                    patientID: visit.patient.id,
                    phone: visit.patient.cellPhone,
                    email: visit.patient.email,
                    treatment: services,
                    practitioner: practitioners,
                    description: visit.notes,
                    startTime: visit.date.start,
                    endTime: visit.date.end,
                    color: color,
                    resourceId: resourceID,
                    blocked: false,
                    reassessment: false,
                  });
                } else if (visit.reassessment) {
                  // prepend that the session is a reassessment
                  transformedEvents.push({
                    id: visit._id,
                    name: "[RE] - " + visit.patient.name,
                    patientID: visit.patient.id,
                    phone: visit.patient.cellPhone,
                    email: visit.patient.email,
                    treatment: services,
                    practitioner: practitioners,
                    description: visit.notes,
                    startTime: visit.date.start,
                    endTime: visit.date.end,
                    color: color,
                    resourceId: resourceID,
                    blocked: false,
                    reassessment: true,
                  });
                } else {
                  transformedEvents.push({
                    id: visit._id,
                    name: visit.patient.name,
                    patientID: visit.patient.id,
                    phone: visit.patient.cellPhone,
                    email: visit.patient.email,
                    treatment: services,
                    practitioner: practitioners,
                    description: visit.notes,
                    startTime: visit.date.start,
                    endTime: visit.date.end,
                    color: color,
                    resourceId: resourceID,
                    blocked: false,
                    reassessment: false,
                  });
                }
              }
            }
          }
        }
      }
    });

    // transform events into FullCalendar format
    // // console.log("transformed events", transformedEvents)
    transformedEvents.forEach(this.populateCalendar);

    this.setState({
      masterEvents: transformedEvents,
    });
  }

  componentWillMount() {
    // this.adminsPrePro(this.state.admins);
    // this.calendarPrePro(this.state.visits);
    // // console.log("admins", this.state.admins)
  }

  componentDidMount() {
    this.props.fetchAdmins();
  }

  componentWillReceiveProps(nextState) {
    // load in all calendar events on init
    // console.log("next state", nextState.visits);
    this.setState({
      calendarEvents: [],
    });

    // // console.log('visits', this.state.visits)
    // // console.log('new admins', nextState.admins)
    this.adminsPrePro(nextState.admins);
    // console.log("visits received", nextState.visits);
    this.calendarPrePro(nextState.visits);
    this.setState({
      patients: nextState.patients,
      admins: nextState.admins,
      visits: nextState.visits,
    });
  }

  createNewEvent(visit) {
    console.log("visit", visit);
    var promise = new Promise((resolve) => {
      var payload = visit;
      this.newEventReq(resolve, payload);
    });
    return promise;
  }

  newEventReq(resolve, payload) {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // // console.log(response)
        this.props.fetchVisits(
          moment(payload.date.start).subtract(7, "days").format("YYYY-MM-DD"),
          moment(payload.date.start).add(7, "days").format("YYYY-MM-DD")
        );
        // this.props.fetchVisits()
        this.props.fetchPatients();
        // // console.log("post request")
        if (response.data.successful === false) {
          // // console.log("new event creation failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
          // this.setState({
          //     displayError: true
          // })
        } else {
          resolve(response);
          // // console.log(response);
          // // console.log('new event created')

          // localStorage.setItem("token", response.data.token)
          // set user info to redux
          // this.setState({
          //     userVerified: true
          // })
        }
      })
      .catch((error) => {
        // // console.log(error)
        // // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
        // this.setState({
        //     displayError: true
        // })
      });
  }

  editEvent(id, visit) {
    console.log("edit this event", visit);
    var promise = new Promise((resolve) => {
      var payload = {
        _id: id,
        visit: visit,
      };
      this.editEventReq(resolve, payload);
    });
    return promise;
  }

  editEventReq(resolve, payload) {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/edit", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        console.log("payload", payload);

        this.props.fetchVisits(
          moment(payload.visit.date.start)
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment(payload.visit.date.start).add(7, "days").format("YYYY-MM-DD")
        );
        this.props.fetchPatients();
        // // console.log("post request")
        if (response.data.successful === false) {
          // // console.log("new event creation failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
          // this.setState({
          //     displayError: true
          // })
        } else {
          resolve(response);
          // console.log(response);
          // console.log('new event created')

          // localStorage.setItem("token", response.data.token)
          // set user info to redux
          // this.setState({
          //     userVerified: true
          // })
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
        // this.setState({
        //     displayError: true
        // })
      });
  }

  noShowEvent(id) {
    this.handleClose();
    var promise = new Promise((resolve) => {
      var payload = { _id: id };
      this.noShowReq(resolve, payload);
    });
    return promise;
  }

  noShowReq(resolve, payload) {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/noshow", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log(this.state.eventDialogueStartTime);
        this.props.fetchVisits(
          moment(this.state.eventDialogueStartTime)
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment(this.state.eventDialogueStartTime)
            .add(7, "days")
            .format("YYYY-MM-DD")
        );

        this.props.fetchPatients();
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("no show failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
          // this.setState({
          //     displayError: true
          // })
        } else {
          resolve(response);
          // console.log(response);
          // console.log('no show successful')

          // localStorage.setItem("token", response.data.token)
          // set user info to redux
          // this.setState({
          //     userVerified: true
          // })
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
        // this.setState({
        //     displayError: true
        // })
      });
  }

  gotoPast = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
  };

  handleDateClick = (arg) => {
    // console.log("new event")
    // console.log(this.state.newEvent)
    // // console.log(arg.date)

    // only allow event creation if admin
    console.log("Date Clicked: ", arg.date, this.dateToString(arg.date));
    if (
      this.props.admin.permissions === "Administrator" ||
      this.props.admin.permissions === "Developer"
    ) {
      let clickedDate = this.dateToString(arg.date);
      // // console.log(clickedDate.start)
      // // console.log(clickedDate.end)
      // // console.log(clickedDate.date)
      if (arg.allDay) {
        // console.log("Clicked All Day")
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.changeView("resourceTimeGridDay", clickedDate.date);
        // FullCalendar.changeView('timeGridDay');
      } else {
        this.setState((prevState) => ({
          newEventModal: true,
          newEvent: {
            ...prevState.newEvent,
            startTime: clickedDate.start,
            endTime: clickedDate.end,
            date: clickedDate.date,
          },
        }));
      }
    }
  };

  handleNewPatientNameChange = (arg) => {
    let stringVal = arg.target.value; // not sure why this needs to be set this way.
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        newPatientName: stringVal,
      },
    }));
    // console.log(stringVal)
  };

  handleChangeCheckedNewPatient = (arg) => {
    this.setState({
      // add new event data
      checkedNewPatient: arg.target.checked,
    });
  };
  handleNewPatientPhoneChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        newPatientPhone: stringVal,
      },
    }));
  };

  handleNewEventDateChange = (arg) => {
    // console.log("Date: " + arg.target.value)
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        date: stringVal,
      },
    }));
  };
  handleNewEventStartTimeChange = (arg) => {
    // console.log("Start Time: " + arg.target.value);
    console.log("Start Time: " + moment(arg).format("HH:mm"));

    let stringVal = moment(arg).format("HH:mm");
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        startTime: stringVal,
      },
    }));
  };
  handleNewEventEndTimeChange = (arg) => {
    let stringVal = moment(arg).format("HH:mm");
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        endTime: stringVal,
      },
    }));
  };
  handleNewEventServicesChange = (arg) => {
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        // services: [...this.state.newEvent.services, arg],
        services: [arg],
      },
    }));
  };
  handleNewEventPatientChange = (arg) => {
    console.log(arg);
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        patient: arg,
      },
    }));
  };
  handleNewEventPractitionerChange = (arg) => {
    console.log(arg);
    // console.log(
    //   "All Info",
    //   this.state.newEvent.date,
    //   this.state.newEvent.services[0],
    //   this.state.newEvent.patient,
    //   this.state.newEvent.startTime,
    //   this.state.newEvent.endTime,
    //   this.state.newEvent.practitioner
    // );
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        practitioner: [arg],
      },
    }));
  };
  handleNewEventNotesChange = (arg) => {
    let stringVal = arg.target.value;
    this.setState((prevState) => ({
      newEvent: {
        ...prevState.newEvent,
        notes: stringVal,
      },
    }));
  };

  handleColorChange = (arg) => {
    if (arg === this.state.setColor) {
      this.setState({
        // add new event data
        setColor: 0,
      });
    } else {
      this.setState({
        // add new event data
        setColor: arg,
      });
    }
    console.log(arg);
  };
  handleNewEventClick = (arg) => {
    // console.log("new event")
    // console.log(this.state.newEvent)
    this.setState({
      // add new event data
      newEventModal: true,
    });
  };

  createNewPatient(patient) {
    var promise = new Promise((resolve) => {
      var payload = patient;
      this.newPatientReq(resolve, payload);
    });
    return promise;
  }

  newPatientReq(resolve, payload) {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/patients", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("post request")
        this.props.fetchPatients();
        if (response.data.successful === false) {
          // console.log("new patient creation failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
          // this.setState({
          //     displayError: true
          // })
        } else {
          resolve(response.data.patient._id);
          // console.log(response);
          // console.log('new patient created')
          // console.log(response.data.patient._id)
          // localStorage.setItem("token", response.data.token)
          // set user info to redux
          // this.setState({
          //     userVerified: true
          // })
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
        // this.setState({
        //     displayError: true
        // })
      });
  }

  handleBlockNewEventClickSubmit = () => {
    // block off time - no params
    let grabEventState = this.state.newEvent;
    // console.log(grabEventState)
    // console.log("block time edit: " + this.state.eventIDToEdit)

    // create new dummy event
    let newEvent = {
      notes: grabEventState.notes,
      patient: {
        id: "NOPATIENT",
      },
      date: {
        start: this.stringToDate(grabEventState.date, grabEventState.startTime),
        end: this.stringToDate(grabEventState.date, grabEventState.endTime),
      },
      service: [
        {
          practitioner: {
            id: grabEventState.practitioner[0]._id,
            name: grabEventState.practitioner[0].name,
          },
        },
      ],
      signIn: null,
      signOut: null,
      blocked: true,
      reassessment: false,
    };
    // create calendar item
    this.createNewEvent(newEvent);
    // this.setState({
    //     masterEvents: [...this.state.masterEvents, newEvent]
    // })
    this.setState({ newEventModal: false });
  };

  handleNewEventClickSubmit = (arg) => {
    let grabEventState = this.state.newEvent;
    console.log("grabEventState", grabEventState);
    // console.log("edit: " + this.state.eventIDToEdit)
    if (this.state.eventIDToEdit) {
      // console.log("hit edit path")
      let newServices = [];
      let i = 0;
      grabEventState.services.forEach((service) => {
        newServices.push({
          type: service,
          price: {
            gross: 0,
            tax: 0,
            net: 0,
          },
          paid: false,
          invoice: {
            yn: false,
            link: null,
          },
          practitioner: {
            //TODO: alter to allow any practitioner.
            id: grabEventState.practitioner[i]._id,
            name: grabEventState.practitioner[i].name,
          },
        });
        i = i + 1;
      });
      // create new visit
      // console.log("new visit pat id: " + grabEventState.patient._id)
      let newVisit = {
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        notes: grabEventState.notes,
        patient: {
          id: grabEventState.patient._id,
          name:
            grabEventState.patient.intake.general.firstName +
            " " +
            grabEventState.patient.intake.general.lastName,
          cellPhone: grabEventState.patient.intake.general.cellPhone,
          email: grabEventState.patient.intake.general.email,
          firstVisit: false,
        },
        date: {
          start: this.stringToDate(
            grabEventState.date,
            grabEventState.startTime
          ),
          end: this.stringToDate(grabEventState.date, grabEventState.endTime),
        },
        service: newServices,
        signIn: {
          yn: false,
          time: null,
          signature: null,
        },
        signOut: {
          yn: false,
          time: null,
          signature: null,
        },
        blocked: false,
        reassessment: grabEventState.reassessment,
      };

      this.editEvent(this.state.eventIDToEdit, newVisit);
      this.setState({
        eventIDToEdit: null,
      });
    } else if (grabEventState.newPatientName) {
      // TODO: Must create a new patient
      let newPatient = {
        intake: {
          general: {
            dateFilledOut: null,
            title: null,
            firstName: grabEventState.newPatientName.substr(
              0,
              grabEventState.newPatientName.indexOf(" ")
            ),
            lastName: grabEventState.newPatientName.substr(
              grabEventState.newPatientName.indexOf(" ") + 1
            ),
            dob: "",
            addressStreet: "",
            addressCity: "",
            addressPostal: "",
            gender: "",
            occupation: "",
            cellPhone: grabEventState.newPatientPhone,
            homePhone: "",
            emergencyContact: "",
            emergencyContactTel: "",
            familyPhysicianName: "",
            familyPhysicianPhone: "",
            familyPhysicianAddress: "",
            referred: "",
            maritalStatus: "",
            noDependants: "",
            insurance: "",
            otherTreatment: "",
            healthConditionRequiringTreatment: "",
            onMedication: "",
            travel: "",
            allergies: "",
            surgery: "",
            injury: "",
            // checkboxes
            // cardiovascular
            pacemaker: false,
            metal: false,
            highBloodPressure: false,
            lowBloodPressure: false,
            chronicCongestiveHeartFailure: false,
            heartAttack: false,
            phlebitis: false,
            heartSurgery: false,
            heartDisease: false,
            stroke: false,
            strokeWhen: "",
            poorCirculation: false,
            familyHistoryCario: false,
            // respiratory
            chronicCough: false,
            shortnessOfBreath: false,
            bronchitis: false,
            asthma: false,
            familyHistoryResp: false,
            // digestion
            poorDigestion: false,
            IBS: false,
            constipation: false,
            liver: false,
            kidney: false,
            // head/neck
            headaches: false,
            migraines: false,
            visionProblems: false,
            visionLoss: false,
            earProblems: false,
            hearingLoss: false,
            // muscle and joint
            pain: false,
            stiffness: false,
            swelling: false,
            limitedMotion: false,
            fatigue: false,
            osteoarthritis: false,
            backPainUpper: false,
            backPainMid: false,
            backPainLower: false,
            shoulderPain: false,
            // infections
            hepA: false,
            hepB: false,
            hepC: false,
            TB: false,
            HIV: false,
            herpes: false,
            infectionsOther: "",
            // skin conditions
            sensitiveSkin: false,
            rashes: false,
            acne: false,
            coldSores: false,
            bleedingDisorder: false,
            bruiseEasily: false,
            varicoseVeins: false,
            deepVeinThrombosis: false,
            eczema: false,
            tattoos: false,
            // other conditions
            lossOfSensation: "",
            diabetes: "",
            epilepsy: false,
            cancer: "",
            multipleSclerosis: false,
            arthritis: false,
            familyHistoryArthritis: false,
            // women
            pregnant: "",
            menstruation: "",
            menopause: "",
            // general health
            generalHealthStatus: "",
            generalStressLevels: "",
            regularMeals: false,
            irregularEating: false,
            caffeine: false,
            smoke: false,
            exercise: "",
            previousMassage: false,
            previousChiro: false,
            previousPhysio: false,
            previousAcu: false,
            previousOsteo: false,
            previousPsycho: false,
            dateOfLastFullPhysical: "",
            otherMedicalConditions: "",
            internalPins: "",
            // other
            dentalSurgery: "",
            ofSpecialNote: "",
            painReliefDiagram: "",
            additionalInfo: "",
            email: "",
            //misc
            displayError: false,
            // consent Acu
            consentAcupuncture: false,
            consentBleedEasily: false,
            consentPregnant: false,
            consentHIV: false,
            consentPacemaker: false,
            consentMetal: false,
            // consent Osteo
            consentOsteopathy: false,
            consentMassage: false,
            consentTelus: false,
            consentAcceptTerms: false,
            primaryCoverage: null,
            planMember: null,
            policyNumber: null,
            coverageCert: null,
            canadaLife: null,
          },
        },
      };

      this.createNewPatient(newPatient).then((patientID) => {
        // console.log("New event attempt", grabEventState, patientID)
        let newServices = [];
        let i = 0;
        grabEventState.services.forEach((service) => {
          newServices.push({
            type: service,
            price: {
              gross: 0,
              tax: 0,
              net: 0,
            },
            paid: false,
            invoice: {
              yn: false,
              link: null,
            },
            practitioner: {
              //TODO: alter to allow any practitioner.
              id: grabEventState.practitioner[i]._id,
              name: grabEventState.practitioner[i].name,
            },
          });
          i = i + 1;
        });
        // create new visit
        let newVisit = {
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          notes: grabEventState.notes,
          patient: {
            name: grabEventState.newPatientName,
            cellPhone: grabEventState.newPatientPhone,
            email: null,
            firstVisit: true,
            id: patientID,
          },
          date: {
            start: this.stringToDate(
              grabEventState.date,
              grabEventState.startTime
            ),
            end: this.stringToDate(grabEventState.date, grabEventState.endTime),
          },
          service: newServices,
          signIn: {
            yn: false,
            time: null,
            signature: null,
          },
          signOut: {
            yn: false,
            time: null,
            signature: null,
          },
          blocked: false,
          reassessment: grabEventState.reassessment,
        };

        let services = [];
        grabEventState.services.forEach((service) => {
          services.push(service);
        });
        let practitioners = [];
        grabEventState.practitioner.forEach((practitioner) => {
          practitioners.push(practitioner);
        });
        // new calendar item - TODO: verify Obsolescence.
        let newCalEvent = {
          id: newVisit.id,
          name: newVisit.patient.name,
          patientID: patientID,
          phone: newVisit.patient.cellPhone,
          email: null,
          treatment: services,
          practitioner: practitioners,
          description: newVisit.notes,
          startTime: newVisit.date.start,
          endTime: newVisit.date.end,
          reassessment: newVisit.reassessment,
        };
        this.populateCalendar(newCalEvent);
        this.setState({
          masterEvents: [...this.state.masterEvents, newCalEvent],
        });
        this.createNewEvent(newVisit);
      });

      // create a new visit and a new calendar item.

      // let newCalEvent =
      // {
      //     id: visit.id,
      //     name: visit.patient.name,
      //     patientID: visit.patient.id,
      //     phone: visit.patient.cellPhone,
      //     email: visit.patient.email,
      //     treatment: services,
      //     practitioner: practitioners,
      //     description: visit.notes,
      //     startTime: visit.date.start,
      //     endTime: visit.date.end
      // }
    } else {
      // create a new visit and a new calendar item.
      let newServices = [];
      let i = 0;
      grabEventState.services.forEach((service) => {
        newServices.push({
          type: service,
          price: {
            gross: 0,
            tax: 0,
            net: 0,
          },
          paid: false,
          invoice: {
            yn: false,
            link: null,
          },
          practitioner: {
            //TODO: alter to allow any practitioner.
            id: grabEventState.practitioner[i]._id,
            name: grabEventState.practitioner[i].name,
          },
        });
        i = i + 1;
      });
      // create new visit
      // console.log("new visit pat id: " + grabEventState.patient._id)
      let newVisit = {
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        notes: grabEventState.notes,
        patient: {
          id: grabEventState.patient._id,
          name:
            grabEventState.patient.intake.general.firstName +
            " " +
            grabEventState.patient.intake.general.lastName,
          cellPhone: grabEventState.patient.intake.general.cellPhone,
          email: grabEventState.patient.intake.general.email,
          firstVisit: false,
        },
        date: {
          start: this.stringToDate(
            grabEventState.date,
            grabEventState.startTime
          ),
          end: this.stringToDate(grabEventState.date, grabEventState.endTime),
        },
        service: newServices,
        signIn: {
          yn: false,
          time: null,
          signature: null,
        },
        signOut: {
          yn: false,
          time: null,
          signature: null,
        },
        blocked: false,
        reassessment: grabEventState.reassessment,
      };

      let services = [];
      grabEventState.services.forEach((service) => {
        services.push(service);
      });
      let practitioners = [];
      grabEventState.practitioner.forEach((practitioner) => {
        practitioners.push(practitioner);
      });
      // new calendar item
      let newCalEvent = {
        id: newVisit.id,
        name: newVisit.patient.name,
        patientID: newVisit.patient.id,
        phone: newVisit.patient.cellPhone,
        email: newVisit.patient.email,
        treatment: services,
        practitioner: practitioners,
        description: newVisit.notes,
        startTime: newVisit.date.start,
        endTime: newVisit.date.end,
        reassessment: newVisit.reassessment,
      };
      this.populateCalendar(newCalEvent);
      this.setState({
        masterEvents: [...this.state.masterEvents, newCalEvent],
      });
      this.createNewEvent(newVisit);
    }

    // // console.log("new event")
    this.setState({
      newEventModal: false,
      newEvent: {
        newPatientName: "",
        newPatientPhone: "",
        date: null,
        startTime: null,
        endTime: null,
        services: [],
        patient: null,
        practitioner: [],
        notes: null,
        reassessment: false,
      },
    });
  };

  getPatientByID(patientID) {
    var promise_id = new Promise((resolve) => {
      var payload = patientID;
      this.getPatientReq(resolve, payload);
    });
    return promise_id;
  }

  getPatientReq(resolve, payload) {
    // console.log(payload)
    axios
      .get(process.env.REACT_APP_DOMAIN + "/patients/" + payload)
      .then((response) => {
        // console.log(response)
        // console.log("get request")
        if (response.data.successful === false) {
          // console.log("could not get patient")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
          // this.setState({
          //     displayError: true
          // })
        } else {
          resolve(response);
          // console.log(response);
          // console.log('got patient')
          // localStorage.setItem("token", response.data.token)
          // set user info to redux
          // this.setState({
          //     userVerified: true
          // })
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("get request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
        // this.setState({
        //     displayError: true
        // })
      });
  }

  handleGoToProfileClick = () => {
    const selectedEvent = this.state.masterEvents.find(
      ({ id }) => id === this.state.eventDialogueID
    );
    let patient = this.getPatientByID(selectedEvent.patientID);
    this.setState({
      eventDialogueOpen: false,
    });
    // console.log(patient)
    patient.then((patient) => {
      this.props.history.push("/patient/" + patient.data._id);
    });
  };

  handleNoShowClick = () => {
    this.noShowEvent(this.state.eventDialogueID);
    this.setState({
      eventDialogueOpen: false,
    });
  };

  handleEventClickCopy = () => {
    // take all event state from currently selected event and place it into 'new event'
    // console.log(this.state.eventDialogueID)
    // get event id and find corresponding event from master list
    // console.log(this.state.masterEvents)
    const selectedEvent = this.state.masterEvents.find(
      ({ id }) => id === this.state.eventDialogueID
    );

    this.handleClose();
    // set appropriate states for dialogue with content from master list
    // console.log(selectedEvent)
    // console.log(this.state.newEvent.services)
    // console.log(selectedEvent.treatment)

    // copied information can never be for a new patient. Should be able to get real patient IDs

    // get patient by ID
    // console.log(selectedEvent.patientID)
    let patient = this.getPatientByID(selectedEvent.patientID);

    patient.then((patient) => {
      console.log(selectedEvent.practitioner[0].name);
      console.log(
        this.state.practitioners.find(
          ({ name }) => name === selectedEvent.practitioner[0].name
        )
      );
      console.log(this.state.practitioners);
      console.log(patient.data);

      this.setState({
        newEvent: {
          newPatientName: null,
          newPatientPhone: null,
          date: null,
          startTime: null,
          endTime: null,
          services: [...new Set(selectedEvent.treatment)],
          patient: patient.data,
          // practitioner: [selectedEvent.practitioner],
          practitioner: [...new Set(selectedEvent.practitioner)],
          // practitioner: [
          //   this.state.practitioners.find(
          //     ({ name }) => name === selectedEvent.practitioner[0].name
          //   ),
          // ],
          notes: selectedEvent.description,
          reassessment: selectedEvent.reassessment,
        },
        eventDialogueOpen: false,
      });
    });
  };

  stringToDate = (date, time) => {
    let year = Number(date.slice(0, 4));
    let month = Number(date.slice(5, 7) - 1);
    let day = Number(date.slice(8));
    let hour = Number(time.slice(0, 2));
    let minute = Number(time.slice(3));
    // console.log(year, month, day, hour, minute)
    return new Date(year, month, day, hour, minute);
  };

  dateToString = (date) => {
    let year = this.attachZero(String(date.getFullYear()));
    let month = this.attachZero(String(date.getMonth() + 1));
    let day = this.attachZero(String(date.getDate()));
    let hour = this.attachZero(String(date.getHours()));
    let nextHour = this.attachZero(String(date.getHours() + 1)); // TODO: doesn't work for 11pm but fine for clinics
    let minute = this.attachZero(String(date.getMinutes()));
    // console.log(day)
    let stringDateObj = {
      date: year + "-" + month + "-" + day,
      start: hour + ":" + minute,
      end: nextHour + ":" + minute,
    };
    return stringDateObj;
  };

  attachZero = (str) => {
    // console.log(str.length)
    if (str.length < 2) {
      return "0" + str;
    } else {
      return str;
    }
  };

  handleEventClick = (arg) => {
    // console.log(arg.event.id)
    // get event id and find corresponding event from master list
    // console.log("event", arg.event)
    // console.log(this.state.masterEvents)
    const selectedEvent = this.state.masterEvents.find(
      ({ id }) => id === arg.event.id
    );
    console.log("Clicked Start Time: ", selectedEvent.startTime);
    // set appropriate states for dialogue with content from master list
    // console.log(selectedEvent)
    if (!(arg.event.backgroundColor === "#454545")) {
      this.setState({
        eventDialogueID: selectedEvent.id,
        eventDialogueName: selectedEvent.name,
        eventDialoguePhone: selectedEvent.phone,
        eventDialogueStartTime: selectedEvent.startTime,
        eventDialogueEndTime: selectedEvent.endTime,
        eventDialogueTreatment: [...new Set(selectedEvent.treatment)],
        eventDialoguePractitioner: [...new Set(selectedEvent.practitioner)],
        eventDialogueEmail: selectedEvent.email,
        eventDialogueDescription: selectedEvent.description,
        eventDialogueOpen: true,
        eventDialogueReassessment: selectedEvent.reassessment,
      });
    } else {
      this.setState({
        eventDialogueName: selectedEvent.title,
        eventDialogueID: selectedEvent.id,
        eventDialogueDescription: selectedEvent.description,
        blockedModalOpen: true,
      });
    }
  };

  handleNewEventClickClose = () => {
    // console.log(this.state.newEvent)
    this.setState({
      newEventModal: false,
    });
  };

  handleEventClickClose = () => {
    this.setState({
      eventDialogueOpen: false,
    });
  };

  handleEventBlockedClickClose = () => {
    this.setState({
      blockedModalOpen: false,
    });
  };

  handleSMSClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      SMSSuccessOpen: false,
    });
  };

  sendSMS(visit) {
    // console.log(visit);
    let patient = this.getPatientByID(visit.patientID);
    return patient.then((patient) => {
      console.log(patient);
      return new Promise((resolve, reject) => {
        var payload = {
          date: new Date(visit.startTime).toLocaleDateString([], {
            month: "long",
            day: "numeric",
          }),
          startTime: new Date(visit.startTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          endTime: new Date(visit.endTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          phone:
            "+1" +
            visit.phone.slice(1, 4) +
            visit.phone.slice(6, 9) +
            visit.phone.slice(10),
          name:
            patient.data.intake.general.firstName +
            " " +
            patient.data.intake.general.lastName,
        };
        console.log("Send SMS", payload);
        this.sendSMSReq(resolve, reject, payload);
      });
    });
  }

  sendSMSReq(resolve, reject, payload) {
    console.log("Send SMS Reminder", payload);

    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/sms", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("sms send failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("sms send succeeded");
          this.setState({
            SMSSuccessOpen: true,
          });
          // console.log(response);
          // console.log('sms send succeeded')
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  handleEventClickSMS = (arg) => {
    const selectedEvent = this.state.masterEvents.find(
      ({ id }) => id === this.state.eventDialogueID
    );
    // console.log(selectedEvent)
    this.sendSMS(selectedEvent).then(() => {
      this.setState({
        eventDialogueOpen: false,
        open: false,
      });
    });
  };

  sendEmail(visit) {
    var promise = new Promise((resolve) => {
      var payload = {
        date: visit.startTime.slice(0, 10),
        startTime: new Date(visit.startTime).toLocaleTimeString(),
        endTime: new Date(visit.endTime).toLocaleTimeString(),
        email: visit.email,
      };
      this.sendEmailReq(resolve, payload);
    });
    return promise;
  }

  sendEmailReq(resolve, payload) {
    console.log("Send email reminder");
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/email", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("email send failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("email send succeeded");
          // console.log(response);
          // console.log('email send succeeded')
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  handleEventClickEmail = (arg) => {
    const selectedEvent = this.state.masterEvents.find(
      ({ id }) => id === this.state.eventDialogueID
    );
    // console.log(selectedEvent)
    this.sendEmail(selectedEvent).then(() => {
      this.setState({
        eventDialogueOpen: false,
        open: false,
      });
    });
  };

  handleEventClickEdit = (arg) => {
    const selectedEvent = this.state.masterEvents.find(
      ({ id }) => id === this.state.eventDialogueID
    );

    // set appropriate states for dialogue with content from master list
    // console.log(selectedEvent)
    // console.log(this.state.newEvent.services)
    // console.log(selectedEvent.treatment)

    // copied information can never be for a new patient. Should be able to get real patient IDs

    // get patient by ID
    // console.log(selectedEvent.patientID)
    let patient = this.getPatientByID(selectedEvent.patientID);

    patient.then((patient) => {
      // console.log(patient.data)
      // console.log(selectedEvent.startTime.slice(0, 10));
      // console.log(selectedEvent.startTime.slice(11, 16));
      // console.log(selectedEvent.endTime.slice(11, 16));
      // console.log(moment(selectedEvent.startTime).format("HH:mm"));
      console.log("this is the event for editing", selectedEvent);
      this.setState({
        newEvent: {
          newPatientName: null,
          newPatientPhone: null,
          date: selectedEvent.startTime.slice(0, 10),
          // off by 5 hours by default, using moment to correct
          startTime: moment(selectedEvent.startTime).format("HH:mm"),
          endTime: moment(selectedEvent.endTime).format("HH:mm"),
          services: selectedEvent.treatment,
          patient: patient.data,
          practitioner: [...new Set(selectedEvent.practitioner)],
          notes: selectedEvent.description,
          reassessment: selectedEvent.reassessment,
        },
        eventDialogueOpen: false,
        newEventModal: true,
        open: false,
        eventIDToEdit: this.state.eventDialogueID,
      });
    });
  };

  handleSearchChange = (arg) => {
    // console.log(arg)
    this.setState({ search: arg });
    if (arg) {
      this.props.history.push("/patient/" + arg._id);
    }
  };

  viewChanged = (info) => {
    // this is always called on render.
    console.log("info", info);
    this.setState({
      currentDate: new Date(info.view.currentStart),
    });
    this.props.fetchVisits(
      moment(info.view.currentStart).format("YYYY-MM-DD"),
      moment(info.view.currentEnd).format("YYYY-MM-DD")
    );
    // console.log("NEW VIEW");
  };

  handleEventClickDelete = () => {
    // console.log(this.state.eventDialogueID)
    this.handleClose();
    this.handleEventBlockedClickClose();

    axios
      .delete(process.env.REACT_APP_DOMAIN + "/visits/delete", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: this.state.eventDialogueID,
        },
      })
      .then((response) => {
        // console.log(response)
        // error prone if deletion is outside of range.
        this.props.fetchVisits(
          moment().subtract(14, "days").format("YYYY-MM-DD"),
          moment().add(14, "days").format("YYYY-MM-DD")
        );

        // console.log("delete request")
        if (response.data.successful === false) {
          // console.log("visit deletion failed")
          var errorMsg = response.data.reason;
          // resolve(errorMsg);
        } else {
          // resolve("");
          // console.log(response);
          // console.log('visit deleted')

          let filteredArray = this.state.calendarEvents.filter(
            (item) => item.id !== this.state.eventDialogueID
          );
          this.setState({
            calendarEvents: filteredArray,
            eventDialogueOpen: false,
          });
          // remove from state
          //
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        // resolve(errorMsg);
        // this.setState({
        //     displayError: true
        // })
      });
  };

  render() {
    const { classes } = this.props;
    let maxForward = new Date();
    maxForward.setDate(maxForward.getDate() + 14);
    let maxBackward = new Date();
    maxBackward.setDate(maxBackward.getDate() - 7);

    let canNavigateBack = this.state.currentDate >= maxBackward;

    let canNavigateForward = this.state.currentDate <= maxForward;

    let navigationButtons =
      (canNavigateBack ? "prev," : "") +
      (canNavigateForward ? "next " : "") +
      " today";

    return (
      <>
        <ThemeProvider theme={theme}>
          <div>
            <Grid
              container
              direction="row-reverse"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Autocomplete
                  id="searchAuto"
                  size="small"
                  options={this.props.patients}
                  getOptionLabel={(option) =>
                    option.intake.general.firstName +
                    " " +
                    option.intake.general.lastName
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      <div>
                        <Typography variant="body1">
                          {option.intake.general.firstName +
                            " " +
                            option.intake.general.lastName}
                        </Typography>
                        <Typography variant="caption">
                          {option.intake.general.cellPhone}
                        </Typography>
                      </div>
                    </React.Fragment>
                  )}
                  style={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search" variant="outlined" />
                  )}
                  onChange={(event, newValue) =>
                    this.handleSearchChange(newValue)
                  }
                  value={this.state.search}
                />
              </Grid>
            </Grid>

            <hr
              style={{
                borderToColor: "#cccccc",
                borderTop: 1,
                marginTop: 40,
                marginBottom: 20,
              }}
            />

            <div style={{ width: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                p={1}
                m={1}
              >
                <Box p={1} flexGrow={1} style={{ textAlign: "left" }}>
                  <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
                    WELCOME TO
                  </Typography>
                  <Typography variant="h3">Appleby Wellness</Typography>
                </Box>
                {(this.props.admin.permissions === "Administrator" ||
                  this.props.admin.permissions === "Developer") && (
                  <Box p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<AddIcon />}
                      disableElevation
                      onClick={this.handleNewEventClick}
                      style={{ float: "right" }}
                    >
                      Add Event
                    </Button>
                  </Box>
                )}
              </Box>
            </div>
            <hr
              style={{
                borderToColor: "#cccccc",
                borderTop: 1,
                marginTop: 20,
                marginBottom: 40,
              }}
            />
            {/* <div style={{ width: "100%", maxHeight: "100vh" }}>
              
            </div> */}
            <FullCalendar
              defaultView="timeGridWeek"
              header={{
                left:
                  this.props.admin.permissions === "Administrator" ||
                  this.props.admin.permissions === "Developer"
                    ? "prev,next today"
                    : navigationButtons,
                center: "title",
                right:
                  this.props.admin.permissions === "Administrator" ||
                  this.props.admin.permissions === "Developer"
                    ? "dayGridMonth,timeGridWeek,resourceTimeGridDay,listWeek"
                    : "timeGridWeek, timeGridDay",
              }}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceTimeGridPlugin,
              ]}
              height={"auto"}
              stickyHeaderDates={true}
              resources={this.state.practitioners}
              allDayText=""
              schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
              ref={this.calendarComponentRef}
              weekends={this.state.calendarWeekends}
              events={this.state.calendarEvents}
              dateClick={this.handleDateClick}
              eventClick={this.handleEventClick}
              datesRender={(info) => {
                this.viewChanged(info);
              }}
              locale={"en-GB"}
              views={{
                day: {
                  titleFormat: {
                    month: "long",
                    year: "numeric",
                    day: "numeric",
                    weekday: "short",
                  },
                },
              }}
            />
          </div>
          {/* Conditional Content */}
          <div>
            <Dialog
              open={this.state.blockedModalOpen}
              onClose={this.handleEventBlockedClickClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {this.state.eventDialogueName}
              </DialogTitle>
              <DialogContent style={{ margin: 0, paddingBottom: 0 }}>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ paddingBottom: 0, margin: 0 }}
                >
                  This timeslot has been blocked off intentionally. Please do
                  not schedule any events for this practitioner during this
                  time.
                  <br /> <br />
                  <b>Notes</b>: {this.state.eventDialogueDescription}
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ marginBottom: 20, marginRight: 12, marginLeft: 12 }}
              >
                <IconButton
                  color="primary"
                  onClick={this.handleEventClickDelete}
                  aria-label="delete"
                >
                  <TrashIcon />
                </IconButton>
                <Button
                  onClick={this.handleEventBlockedClickClose}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog
              open={this.state.eventDialogueOpen}
              onClose={this.handleEventClickClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={"sm"} // 'lg', 'md', 'sm', 'xl', 'xs', or false
            >
              <DialogTitle id="alert-dialog-title">
                {this.state.eventDialogueName +
                  " | " +
                  this.state.eventDialoguePhone}
              </DialogTitle>
              <DialogContent style={{ margin: 0, paddingBottom: 0 }}>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ paddingBottom: 0, margin: 0 }}
                >
                  Time:{" "}
                  <b>
                    {new Date(this.state.eventDialogueStartTime)
                      .toString()
                      .slice(0, 24)}{" "}
                    {this.state.eventDialogueEndTime && (
                      <>
                        -{" "}
                        {new Date(this.state.eventDialogueEndTime)
                          .toString()
                          .slice(15, 24)}
                      </>
                    )}{" "}
                  </b>
                  <br />
                  <br />
                  Treatment Type:{" "}
                  <b>
                    {this.state.eventDialogueTreatment.map((treatment) => (
                      <Chip
                        label={treatment}
                        variant="outlined"
                        style={{ marginLeft: 2, marginRight: 2 }}
                      />
                    ))}
                  </b>{" "}
                  <br />
                  <br />
                  Practitioner:{" "}
                  <b>
                    {this.state.eventDialoguePractitioner.map((prac) => (
                      <Chip
                        label={prac.name}
                        color="primary"
                        variant="outlined"
                        style={{ marginLeft: 2, marginRight: 2 }}
                      />
                    ))}
                  </b>{" "}
                  <br />
                  <br />
                  Client Email: <b>{this.state.eventDialogueEmail}</b> <br />
                  <br />
                  Description: <b>{this.state.eventDialogueDescription}</b>{" "}
                  <br />
                  <br />
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{ marginBottom: 20, marginRight: 12, marginLeft: 12 }}
              >
                {/* <IconButton color="primary" onClick={this.handleEventClickCopy} aria-label="copy">
                                    <QueueIcon />
                                </IconButton>
                                <IconButton color="primary" onClick={this.handleEventClickDelete} aria-label="delete">
                                    <TrashIcon />
                                </IconButton>
                                <IconButton color="primary" aria-label="send message">
                                    <MessageIcon />
                                </IconButton>
                                <IconButton color="primary" aria-label="send email">
                                    <MailIcon />
                                </IconButton>
                                <Button onClick={this.handleEventClickEdit} color="primary" variant="contained" disableElevation>
                                    Edit
                            </Button> */}
                {/* <div> */}
                <SpeedDial
                  ariaLabel="SpeedDial"
                  hidden={false}
                  icon={<SpeedDialIcon />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  direction={"right"}
                  style={{ position: "absolute", left: 20 }}
                >
                  {(this.props.admin.permissions === "Administrator" ||
                    this.props.admin.permissions === "Developer") &&
                    [
                      {
                        key: "Edit Event",
                        icon: <EditIcon />,
                        tooltipTitle: "Edit Event",
                        onClick: this.handleEventClickEdit,
                      },
                      {
                        key: "Send Mail Reminder",
                        icon: <MailIcon />,
                        tooltipTitle: "Send Mail Reminder",
                        onClick: this.handleEventClickEmail,
                      },
                      {
                        key: "Send SMS Reminder",
                        icon: <MessageIcon />,
                        tooltipTitle: "Send SMS Reminder",
                        onClick: this.handleEventClickSMS,
                      },
                      {
                        key: "Delete Event",
                        icon: <TrashIcon />,
                        tooltipTitle: "Delete Event",
                        onClick: this.handleEventClickDelete,
                      },
                      {
                        key: "Copy Event Details",
                        icon: <QueueIcon />,
                        tooltipTitle: "Copy Event Details",
                        onClick: this.handleEventClickCopy,
                      },
                      {
                        key: "No Show",
                        icon: <RemoveCircleIcon />,
                        tooltipTitle: "No Show",
                        onClick: this.handleNoShowClick,
                      },
                    ].map((action) => (
                      <SpeedDialAction
                        key={action.key}
                        icon={action.icon}
                        tooltipPlacement={"bottom"}
                        tooltipTitle={action.tooltipTitle}
                        onClick={action.onClick}
                      />
                    ))}
                  <SpeedDialAction
                    key="Go to Profile"
                    icon={<ZoomIcon />}
                    tooltipPlacement={"bottom"}
                    tooltipTitle="Go to Profile"
                    onClick={this.handleGoToProfileClick}
                  />
                </SpeedDial>
                {/* </div> */}

                <Button
                  onClick={this.handleEventClickClose}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog
              open={this.state.newEventModal}
              onClose={this.handleNewEventClickClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Schedule an Appointment
              </DialogTitle>
              <DialogContent>
                <hr style={{ opacity: 0.5 }} />
                {/* <br /> */}
                <Typography variant="overline">New Patient</Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      ref="newPatientName"
                      label="New Patient Name"
                      id="margin-none"
                      className="newPatientName"
                      onChange={this.handleNewPatientNameChange}
                      style={{ width: "98%" }}
                      variant={"outlined"}
                      value={this.state.newEvent.newPatientName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputMask
                      mask="(999) 999-9999"
                      value={this.state.newEvent.newPatientPhone}
                      onChange={this.handleNewPatientPhoneChange}
                      disabled={false}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant={"outlined"}
                          label="New Patient Phone"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <br />
                <hr style={{ opacity: 0.5 }} />
                <br />
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      id="date"
                      label="Date"
                      type="date"
                      defaultValue="2020-00-00"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "98%" }}
                      onChange={this.handleNewEventDateChange}
                      value={this.state.newEvent.date}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <TimePicker
                          id="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          renderInput={(props) => <TextField {...props} />}
                          ampm={false} // This is not needed if you are using localization
                          label="Start Time"
                          onChange={this.handleNewEventStartTimeChange}
                          value={moment(
                            this.state.newEvent.date +
                              this.state.newEvent.startTime,
                            "YYYY MM DD HH:mm"
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TimePicker
                          id="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          renderInput={(props) => <TextField {...props} />}
                          ampm={false} // This is not needed if you are using localization
                          label="Start Time"
                          onChange={this.handleNewEventEndTimeChange}
                          value={moment(
                            this.state.newEvent.date +
                              this.state.newEvent.endTime,
                            "YYYY MM DD HH:mm"
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Autocomplete
                  id="combo-box-demo"
                  options={this.state.services}
                  getOptionLabel={(option) => option}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Service" variant="outlined" />
                  )}
                  onChange={(event, newValue) =>
                    this.handleNewEventServicesChange(newValue)
                  }
                  value={this.state.newEvent.services[0]}
                />
                <br />
                <Grid container>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.state.patients}
                      getOptionLabel={(option) =>
                        option.intake.general.firstName +
                        " " +
                        option.intake.general.lastName
                      }
                      renderOption={(option) => (
                        <React.Fragment>
                          <div>
                            <Typography variant="body1">
                              {option.intake.general.firstName +
                                " " +
                                option.intake.general.lastName}
                            </Typography>
                            <Typography variant="caption">
                              {option.intake.general.cellPhone}
                            </Typography>
                          </div>
                        </React.Fragment>
                      )}
                      style={{ width: "98%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Patient"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleNewEventPatientChange(newValue)
                      }
                      value={this.state.newEvent.patient}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.state.admins}
                      getOptionLabel={(option) => option.name}
                      style={{ width: "98%", float: "right" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Practitioner"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleNewEventPractitionerChange(newValue)
                      }
                      value={this.state.newEvent.practitioner[0]}
                    />
                  </Grid>
                </Grid>
                <br />
                <TextField
                  ref="Notes"
                  label="Notes"
                  id="margin-none"
                  className="newPatientName"
                  onChange={this.handleNewEventNotesChange}
                  style={{ width: "100%" }}
                  variant={"outlined"}
                  value={this.state.newEvent.notes}
                />

                <DialogContentText id="alert-dialog-description"></DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid
                  flexGrow
                  container
                  style={{
                    width: "100%",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  <Grid item xs={6}>
                    <FormControlLabel
                      style={{ float: "Left" }}
                      control={
                        <Checkbox
                          checked={this.state.newEvent.reassessment}
                          onChange={this.handleCheckBoxChange}
                          name="reassessment"
                          color="primary"
                          value={this.state.newEvent.reassessment}
                        />
                      }
                      label="Reassessment"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    spacing={1}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="space-between"
                  >
                    {!(
                      this.state.newEvent.startTime &&
                      this.state.newEvent.endTime &&
                      this.state.newEvent.practitioner[0]
                    ) ? (
                      <IconButton
                        disabled
                        onClick={this.handleBlockNewEventClickSubmit}
                        color="primary"
                        aria-label="Block Out Time"
                      >
                        <BlockIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={this.handleBlockNewEventClickSubmit}
                        color="primary"
                        aria-label="Block Out Time"
                      >
                        <BlockIcon />
                      </IconButton>
                    )}

                    {/* <IconButton disabled color="primary" aria-label="send message">
                                    <MessageIcon />
                                </IconButton>
                                <IconButton disabled color="primary" aria-label="send email">
                                    <MailIcon />
                                </IconButton> */}
                    {/* Lazy solution */}
                    {(this.state.newEvent.date &&
                      this.state.newEvent.services[0] &&
                      this.state.newEvent.patient &&
                      this.state.newEvent.startTime &&
                      this.state.newEvent.endTime &&
                      this.state.newEvent.practitioner[0]) ||
                    (this.state.newEvent.newPatientName &&
                      this.state.newEvent.services[0] &&
                      this.state.newEvent.newPatientPhone &&
                      this.state.newEvent.date &&
                      this.state.newEvent.startTime &&
                      this.state.newEvent.endTime &&
                      this.state.newEvent.practitioner[0]) ? (
                      <Button
                        onClick={this.handleNewEventClickSubmit}
                        color="primary"
                        variant="contained"
                        disableElevation
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        disabled
                        onClick={this.handleNewEventClickSubmit}
                        color="primary"
                        variant="contained"
                        disableElevation
                      >
                        Submit
                      </Button>
                    )}

                    <Button
                      onClick={this.handleNewEventClickClose}
                      color="primary"
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={this.state.SMSSuccessOpen}
              autoHideDuration={6000}
              onClose={this.handleSMSClose}
            >
              <MuiAlert onClose={this.handleSMSClose} severity="success">
                Message sent successfully!
              </MuiAlert>
            </Snackbar>
          </div>
        </ThemeProvider>
      </>
    );
  }
}

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ submitLogin: submitLogin, isLoggedIn: isLoggedIn },
//         dispatch);
// }

function mapStateToProps(state) {
  return {
    patients: state.patients,
    visits: state.visits,
    admins: state.admins,
    admin: state.admin,
    backendUrl: state.url,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
    fetchAdmins,
  })
)(withRouter(Calendar));
