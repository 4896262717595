import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grid from "@material-ui/core/Grid";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { fetchVisits, fetchPatients } from "../actions/index";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SignatureCanvas from "react-signature-canvas";
import PainRelief from "../img/painReliefDiagram.jpg";
import InputMask from "react-input-mask";

let url = process.env.REACT_APP_DOMAIN;

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});
const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

class IntakeForm extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      // admin view
      admin: false,
      // intake
      // input
      firstName: "",
      lastName: "",
      dob: "",
      addressStreet: "",
      addressCity: "",
      addressPostal: "",
      gender: "",
      occupation: "",
      cellPhone: "",
      homePhone: "",
      emergencyContact: "",
      emergencyContactTel: "",
      familyPhysicianName: "",
      familyPhysicianPhone: "",
      familyPhysicianAddress: "",
      referred: "",
      maritalStatus: "",
      noDependants: "",
      insurance: "",
      otherTreatment: "",
      healthConditionRequiringTreatment: "",
      onMedication: "",
      travel: "",
      allergies: "",
      surgery: "",
      injury: "",
      // checkboxes
      // cardiovascular
      pacemaker: false,
      metal: false,
      highBloodPressure: false,
      lowBloodPressure: false,
      chronicCongestiveHeartFailure: false,
      heartAttack: false,
      phlebitis: false,
      heartSurgery: false,
      heartDisease: false,
      stroke: false,
      strokeWhen: "",
      poorCirculation: false,
      familyHistoryCario: false,
      // respiratory
      chronicCough: false,
      shortnessOfBreath: false,
      bronchitis: false,
      asthma: false,
      familyHistoryResp: false,
      // digestion
      poorDigestion: false,
      IBS: false,
      constipation: false,
      liver: false,
      kidney: false,
      // head/neck
      headaches: false,
      migraines: false,
      visionProblems: false,
      visionLoss: false,
      earProblems: false,
      hearingLoss: false,
      // muscle and joint
      pain: false,
      stiffness: false,
      swelling: false,
      limitedMotion: false,
      fatigue: false,
      osteoarthritis: false,
      backPainUpper: false,
      backPainMid: false,
      backPainLower: false,
      shoulderPain: false,
      // infections
      hepA: false,
      hepB: false,
      hepC: false,
      TB: false,
      HIV: false,
      herpes: false,
      infectionsOther: "",
      // skin conditions
      sensitiveSkin: false,
      rashes: false,
      acne: false,
      coldSores: false,
      bleedingDisorder: false,
      bruiseEasily: false,
      varicoseVeins: false,
      deepVeinThrombosis: false,
      eczema: false,
      tattoos: false,
      // other conditions
      lossOfSensation: "",
      diabetes: "",
      epilepsy: false,
      cancer: "",
      multipleSclerosis: false,
      arthritis: false,
      familyHistoryArthritis: false,
      // women
      pregnant: "",
      menstruation: "",
      menopause: "",
      // general health
      generalHealthStatus: "",
      generalStressLevels: "",
      regularMeals: false,
      irregularEating: false,
      caffeine: false,
      smoke: false,
      exercise: "",
      previousMassage: false,
      previousChiro: false,
      previousPhysio: false,
      previousAcu: false,
      previousOsteo: false,
      previousPsycho: false,
      dateOfLastFullPhysical: "",
      otherMedicalConditions: "",
      internalPins: "",
      // other
      dentalSurgery: "",
      ofSpecialNote: "",
      painReliefDiagram: "",
      additionalInfo: "",
      email: "",
      //misc
      displayError: false,
      // consent Acu
      consentAcupuncture: false,
      consentBleedEasily: false,
      consentPregnant: false,
      consentHIV: false,
      consentPacemaker: false,
      consentMetal: false,
      // consent Osteo
      consentOsteopathy: false,
      consentMassage: false,
      consentTelus: false,
      consentAcceptTerms: false,
      primaryCoverage: null,
      planMember: null,
      policyNumber: null,
      coverageCert: null,
      canadaLife: null,
      //signatures
      AcuSig: "",
      OsteoSig: "",
      MassSig: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount = () => {
    this.setState({
      sigCanvas: {
        canvasWidth: 800,
        canvasHeight: 600,
      },
    });

    // Sets the values of tags in the head
    document.title = "Intake Form";
    let metaTags = document.getElementsByTagName("meta");
    for (let i = 0; i < metaTags.length; i++) {
      if (metaTags[i].name === "description") {
        metaTags[i].content = "";
      }
    }
  };

  componentDidMount() {
    this.setState({
      id: this.props.match.params.id,
    });
    // console.log("id", this.props.match.params.id)
    const context = this.sigCanvas.getCanvas().getContext("2d");
    if (
      this.props.match.params.admin === "admin" ||
      this.props.match.params.admin === "reassessment"
    ) {
      console.log("THIS IS THE ADMIN VIEW");
      let patient = this.getPatientByID(this.props.match.params.id);
      patient.then((patient) => {
        let general;
        console.log(this.props.match.params.index);
        console.log(patient.data.intake);

        if (this.props.match.params.index) {
          general = patient.data.intake.history[this.props.match.params.index];
        } else {
          general = patient.data.intake.general;
        }

        console.log("gotten patient", general);

        this.setState({
          // intake
          firstName: general.firstName,
          lastName: general.lastName,
          email: general.email,
          dob: general.dob,
          addressStreet: general.addressStreet,
          addressCity: general.addressCity,
          addressPostal: general.addressPostal,
          gender: general.gender,
          occupation: general.occupation,
          cellPhone: general.cellPhone,
          homePhone: general.homePhone,
          emergencyContact: general.emergencyContact,
          emergencyContactTel: general.emergencyContactTel,
          familyPhysicianName: general.familyPhysicianName,
          familyPhysicianPhone: general.familyPhysicianPhone,
          familyPhysicianAddress: general.familyPhysicianAddress,
          referred: general.referred,
          maritalStatus: general.maritalStatus,
          noDependants: general.noDependants,
          insurance: general.insurance,
          otherTreatment: general.otherTreatment,
          healthConditionRequiringTreatment:
            general.healthConditionRequiringTreatment,
          onMedication: general.onMedication,
          travel: general.travel,
          allergies: general.allergies,
          surgery: general.surgery,
          injury: general.injury,
          pacemaker: general.pacemaker,
          metal: general.metal,
          highBloodPressure: general.highBloodPressure,
          lowBloodPressure: general.lowBloodPressure,
          chronicCongestiveHeartFailure: general.chronicCongestiveHeartFailure,
          heartAttack: general.heartAttack,
          phlebitis: general.phlebitis,
          heartSurgery: general.heartSurgery,
          heartDisease: general.heartDisease,
          stroke: general.stroke,
          strokeWhen: general.strokeWhen,
          poorCirculation: general.poorCirculation,
          familyHistoryCario: general.familyHistoryCario,
          chronicCough: general.chronicCough,
          shortnessOfBreath: general.shortnessOfBreath,
          bronchitis: general.bronchitis,
          asthma: general.asthma,
          familyHistoryResp: general.familyHistoryResp,
          poorDigestion: general.poorDigestion,
          IBS: general.IBS,
          constipation: general.constipation,
          liver: general.liver,
          kidney: general.kidney,
          headaches: general.headaches,
          migraines: general.migraines,
          visionProblems: general.visionProblems,
          visionLoss: general.visionLoss,
          earProblems: general.earProblems,
          hearingLoss: general.hearingLoss,
          pain: general.pain,
          stiffness: general.stiffness,
          swelling: general.swelling,
          limitedMotion: general.limitedMotion,
          fatigue: general.fatigue,
          osteoarthritis: general.osteoarthritis,
          backPainUpper: general.backPainUpper,
          backPainMid: general.backPainMid,
          backPainLower: general.backPainLower,
          shoulderPain: general.shoulderPain,
          hepA: general.hepA,
          hepB: general.hepB,
          hepC: general.hepC,
          TB: general.TB,
          HIV: general.HIV,
          herpes: general.herpes,
          infectionsOther: general.infectionsOther,
          sensitiveSkin: general.sensitiveSkin,
          rashes: general.rashes,
          acne: general.acne,
          coldSores: general.coldSores,
          bleedingDisorder: general.bleedingDisorder,
          bruiseEasily: general.bruiseEasily,
          varicoseVeins: general.varicoseVeins,
          deepVeinThrombosis: general.deepVeinThrombosis,
          eczema: general.eczema,
          tattoos: general.tattoos,
          lossOfSensation: general.lossOfSensation,
          diabetes: general.diabetes,
          epilepsy: general.epilepsy,
          cancer: general.cancer,
          multipleSclerosis: general.multipleSclerosis,
          arthritis: general.arthritis,
          familyHistoryArthritis: general.familyHistoryArthritis,
          pregnant: general.pregnant,
          menstruation: general.menstruation,
          menopause: general.menopause,
          generalHealthStatus: general.generalHealthStatus,
          generalStressLevels: general.generalStressLevels,
          regularMeals: general.regularMeals,
          irregularEating: general.irregularEating,
          caffeine: general.caffeine,
          smoke: general.smoke,
          exercise: general.exercise,
          previousMassage: general.previousMassage,
          previousChiro: general.previousChiro,
          previousPhysio: general.previousPhysio,
          previousAcu: general.previousAcu,
          previousOsteo: general.previousOsteo,
          previousPsycho: general.previousPsycho,
          dateOfLastFullPhysical: general.dateOfLastFullPhysical,
          otherMedicalConditions: general.otherMedicalConditions,
          internalPins: general.internalPins,
          dentalSurgery: general.dentalSurgery,
          ofSpecialNote: general.ofSpecialNote,
          painReliefDiagram: general.painReliefDiagram,
          additionalInfo: general.additionalInfo,
          consentAcupuncture: general.consentAcupuncture,
          consentBleedEasily: general.consentBleedEasily,
          consentPregnant: general.consentPregnant,
          consentHIV: general.consentHIV,
          consentPacemaker: general.consentPacemaker,
          consentMetal: general.consentMetal,
          sigAcu: general.sigAcu,
          consentOsteopathy: general.consentOsteopathy,
          sigOsteo: general.sigOsteo,
          consentMassage: general.consentMassage,
          sigMassage: general.sigMassage,
          consentTelus: general.consentTelus,
          sigTelus: general.sigTelus,
          consentAcceptTerms: general.consentAcceptTerms,
          primaryCoverage: general.primaryCoverage,
          planMember: general.planMember,
          policyNumber: general.policyNumber,
          coverageCert: general.coverageCert,
          canadaLife: general.canadaLife,
        });
        if (general.painReliefDiagram) {
          this.sigCanvas.fromDataURL(general.painReliefDiagram, {
            width: 652,
            height: 446,
          });
        }
        if (general.sigAcu) {
          this.consentAcupunctureSig.fromDataURL(general.sigAcu, {
            width: 530,
            height: 240,
          });
        }
        if (general.sigOsteo) {
          this.consentOsteoSig.fromDataURL(general.sigOsteo, {
            width: 530,
            height: 240,
          });
        }
        if (general.sigMassage) {
          this.consentMassageSig.fromDataURL(general.sigMassage, {
            width: 530,
            height: 240,
          });
        }
        if (general.sigTelus) {
          this.consentTelusSig.fromDataURL(general.sigTelus, {
            width: 530,
            height: 240,
          });
        }
      });
    }

    const image = new Image();
    image.src = PainRelief;
    image.onload = () => {
      context.drawImage(image, 0, 0, 652, 446);
    };
  }

  getPatientByID(patientID) {
    var promise = new Promise((resolve) => {
      var payload = patientID;
      this.getPatientReq(resolve, payload);
    });
    return promise;
  }

  getPatientReq(resolve, payload) {
    // console.log(payload)
    axios
      .get(process.env.REACT_APP_DOMAIN + "/patients/" + payload)
      .then((response) => {
        // console.log(response)
        // console.log("get request")
        if (response.data.successful === false) {
          // console.log("could not get patient")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
          // console.log(response);
          // console.log('got patient')
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("get request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  clear = () => {
    // console.log(
    //     "clear attempt"
    // )
    this.sigCanvas.clear();
    const context = this.sigCanvas.getCanvas().getContext("2d");

    const image = new Image();
    image.src = PainRelief;
    image.onload = () => {
      context.drawImage(image, 0, 0, 652, 446);
    };
  };

  clearAcu = () => {
    this.consentAcupunctureSig.clear();
  };
  clearOsteo = () => {
    this.consentOsteoSig.clear();
  };
  clearMassage = () => {
    this.consentMassageSig.clear();
  };
  clearTelus = () => {
    this.consentTelusSig.clear();
  };

  handleCheckBoxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  /* Attempt to log in the user upon submit. */
  handleSubmit(event) {
    this.intakeSubmit().then((errorMsg) => {
      // console.log("submit hit");
      // Redirect and save username is no errors with sign up.
    });
  }

  intakeSubmit() {
    var promise = new Promise((resolve) => {
      var payload = {
        id: this.state.id,
        general: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          dob: this.state.dob,
          addressStreet: this.state.addressStreet,
          addressCity: this.state.addressCity,
          addressPostal: this.state.addressPostal,
          gender: this.state.gender,
          occupation: this.state.occupation,
          cellPhone: this.state.cellPhone,
          homePhone: this.state.homePhone,
          emergencyContact: this.state.emergencyContact,
          emergencyContactTel: this.state.emergencyContactTel,
          familyPhysicianName: this.state.familyPhysicianName,
          familyPhysicianPhone: this.state.familyPhysicianPhone,
          familyPhysicianAddress: this.state.familyPhysicianAddress,
          referred: this.state.referred,
          maritalStatus: this.state.maritalStatus,
          noDependants: this.state.noDependants,
          insurance: this.state.insurance,
          otherTreatment: this.state.otherTreatment,
          healthConditionRequiringTreatment:
            this.state.healthConditionRequiringTreatment,
          onMedication: this.state.onMedication,
          travel: this.state.travel,
          allergies: this.state.allergies,
          surgery: this.state.surgery,
          injury: this.state.injury,
          pacemaker: this.state.pacemaker,
          metal: this.state.metal,
          highBloodPressure: this.state.highBloodPressure,
          lowBloodPressure: this.state.lowBloodPressure,
          chronicCongestiveHeartFailure:
            this.state.chronicCongestiveHeartFailure,
          heartAttack: this.state.heartAttack,
          phlebitis: this.state.phlebitis,
          heartSurgery: this.state.heartSurgery,
          heartDisease: this.state.heartDisease,
          stroke: this.state.stroke,
          strokeWhen: this.state.strokeWhen,
          poorCirculation: this.state.poorCirculation,
          familyHistoryCario: this.state.familyHistoryCario,
          chronicCough: this.state.chronicCough,
          shortnessOfBreath: this.state.shortnessOfBreath,
          bronchitis: this.state.bronchitis,
          asthma: this.state.asthma,
          familyHistoryResp: this.state.familyHistoryResp,
          poorDigestion: this.state.poorDigestion,
          IBS: this.state.IBS,
          constipation: this.state.constipation,
          liver: this.state.liver,
          kidney: this.state.kidney,
          headaches: this.state.headaches,
          migraines: this.state.migraines,
          visionProblems: this.state.visionProblems,
          visionLoss: this.state.visionLoss,
          earProblems: this.state.earProblems,
          hearingLoss: this.state.hearingLoss,
          pain: this.state.pain,
          stiffness: this.state.stiffness,
          swelling: this.state.swelling,
          limitedMotion: this.state.limitedMotion,
          fatigue: this.state.fatigue,
          osteoarthritis: this.state.osteoarthritis,
          backPainUpper: this.state.backPainUpper,
          backPainMid: this.state.backPainMid,
          backPainLower: this.state.backPainLower,
          shoulderPain: this.state.shoulderPain,
          hepA: this.state.hepA,
          hepB: this.state.hepB,
          hepC: this.state.hepC,
          TB: this.state.TB,
          HIV: this.state.HIV,
          herpes: this.state.herpes,
          infectionsOther: this.state.infectionsOther,
          sensitiveSkin: this.state.sensitiveSkin,
          rashes: this.state.rashes,
          acne: this.state.acne,
          coldSores: this.state.coldSores,
          bleedingDisorder: this.state.bleedingDisorder,
          bruiseEasily: this.state.bruiseEasily,
          varicoseVeins: this.state.varicoseVeins,
          deepVeinThrombosis: this.state.deepVeinThrombosis,
          eczema: this.state.eczema,
          tattoos: this.state.tattoos,
          lossOfSensation: this.state.lossOfSensation,
          diabetes: this.state.diabetes,
          epilepsy: this.state.epilepsy,
          cancer: this.state.cancer,
          multipleSclerosis: this.state.multipleSclerosis,
          arthritis: this.state.arthritis,
          familyHistoryArthritis: this.state.familyHistoryArthritis,
          pregnant: this.state.pregnant,
          menstruation: this.state.menstruation,
          menopause: this.state.menopause,
          generalHealthStatus: this.state.generalHealthStatus,
          generalStressLevels: this.state.generalStressLevels,
          regularMeals: this.state.regularMeals,
          irregularEating: this.state.irregularEating,
          caffeine: this.state.caffeine,
          smoke: this.state.smoke,
          exercise: this.state.exercise,
          previousMassage: this.state.previousMassage,
          previousChiro: this.state.previousChiro,
          previousPhysio: this.state.previousPhysio,
          previousAcu: this.state.previousAcu,
          previousOsteo: this.state.previousOsteo,
          previousPsycho: this.state.previousPsycho,
          dateOfLastFullPhysical: this.state.dateOfLastFullPhysical,
          otherMedicalConditions: this.state.otherMedicalConditions,
          internalPins: this.state.internalPins,
          dentalSurgery: this.state.dentalSurgery,
          ofSpecialNote: this.state.ofSpecialNote,
          painReliefDiagram: this.sigCanvas
            .getCanvas()
            .toDataURL("image/png", 1.0),
          additionalInfo: this.state.additionalInfo,
          consentAcupuncture: this.state.consentAcupuncture,
          consentBleedEasily: this.state.consentBleedEasily,
          consentPregnant: this.state.consentPregnant,
          consentHIV: this.state.consentHIV,
          consentPacemaker: this.state.consentPacemaker,
          consentMetal: this.state.consentMetal,
          sigAcu: null,
          consentOsteopathy: this.state.consentOsteopathy,
          sigOsteo: null,
          consentMassage: this.state.consentMassage,
          sigMassage: null,
          consentTelus: this.state.consentTelus,
          sigTelus: null,
          consentAcceptTerms: this.state.consentAcceptTerms,
          primaryCoverage: this.state.primaryCoverage,
          planMember: this.state.planMember,
          policyNumber: this.state.policyNumber,
          coverageCert: this.state.coverageCert,
          canadaLife: this.state.canadaLife,
        },
      };

      try {
        payload.general.sigAcu = this.consentAcupunctureSig
          .getCanvas()
          .toDataURL("image/png", 1.0);
      } catch (err) {
        // console.log("no acu sig")
      }
      try {
        payload.general.sigOsteo = this.consentOsteoSig
          .getCanvas()
          .toDataURL("image/png", 1.0);
      } catch (err) {
        // console.log("no osteo sig")
      }
      try {
        payload.general.sigMassage = this.consentMassageSig
          .getCanvas()
          .toDataURL("image/png", 1.0);
      } catch (err) {
        // console.log("no massage sig")
      }
      try {
        payload.general.sigTelus = this.consentTelusSig
          .getCanvas()
          .toDataURL("image/png", 1.0);
      } catch (err) {
        // console.log("no telus sig")
      }
      // console.log("payload", payload)
      if (this.props.match.params.admin === "admin") {
        // if this is admin view, then send an index of -1 if this is the current form
        // or the history index.
        if (this.props.match.params.index) {
          payload.index = Number(this.props.match.params.index); // add 'index' property to payload
        } else {
          payload.index = -1; // add 'index' property to payload
        }
      }

      this.intakeSubmitReq(resolve, payload);
    });
    return promise;
  }

  intakeSubmitReq(resolve, payload) {
    axios
      .post(url + "/patients/intake", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("intake failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          // console.log(response);
          // console.log('intake succeeded!')
          if (this.props.match.params.admin === "admin") {
            this.props.history.push("/patient/" + this.props.match.params.id);
          } else {
            this.props.history.push("/sign_in/");
          }
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justify="left"
            alignItems="left"
            style={{ marginTop: 0, padding: 70 }}
          >
            <Grid item xs={12} sm={12}>
              {this.props.match.params.admin === "admin" ? (
                <>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "left",
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    Intake Form
                  </Typography>
                  {this.props.match.params.index ? (
                    <>
                      This is Form #{Number(this.props.match.params.index) + 1}
                    </>
                  ) : (
                    <>This is the current intake form</>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    WELCOME TO
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{ textAlign: "left", marginTop: 20 }}
                  >
                    The Appleby Wellness Clinic
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: "left", marginTop: 20 }}
                  >
                    Please answer all of the questions below. If you have any
                    questions or would like clarification, please ask the front
                    desk.
                  </Typography>
                </>
              )}
              <br />
              <hr style={{ opacity: "20%" }} />
              <br />
              <Typography variant="overline" style={{ textAlign: "left" }}>
                Patient Information
              </Typography>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="firstName"
                    label="First Name"
                    id="margin-none"
                    className="firstName"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="firstName"
                    value={this.state.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="lastName"
                    label="Last Name"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="lastName"
                    value={this.state.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="date"
                    label="Birth Date"
                    type="date"
                    defaultValue="2000-00-00"
                    variant={"outlined"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                    onChange={this.handleInputChange}
                    name="dob"
                    value={this.state.dob}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="street"
                    label="Occupation"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="occupation"
                    value={this.state.occupation}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // value={}
                      onChange={this.handleInputChange}
                      label="Gender"
                      name="gender"
                      value={this.state.gender}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="maritalStatus"
                    label="Marital Status"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="maritalStatus"
                    value={this.state.maritalStatus}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="dependant"
                    label="No. of Dependents"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="noDependants"
                    value={this.state.noDependants}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="insurance"
                    label="Insurance Company"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="insurance"
                    value={this.state.insurance}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="referred"
                    label="Referred By"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    helperText="internet/friend/etc"
                    name="referred"
                    value={this.state.referred}
                  />
                </Grid>
              </Grid>
              <Typography variant="overline" style={{ textAlign: "left" }}>
                Address
              </Typography>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="street"
                    label="Street"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="addressStreet"
                    value={this.state.addressStreet}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="street"
                    label="City"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="addressCity"
                    value={this.state.addressCity}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="street"
                    label="Postal Code"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="addressPostal"
                    value={this.state.addressPostal}
                  />
                </Grid>
              </Grid>
              <Typography variant="overline" style={{ textAlign: "left" }}>
                Contact Information
              </Typography>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="street"
                    label="Email"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="email"
                    value={this.state.email}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    mask="(999) 999-9999"
                    onChange={this.handleInputChange}
                    disabled={false}
                    maskChar=" "
                    value={this.state.cellPhone}
                  >
                    {() => (
                      <TextField
                        style={{ width: "100%" }}
                        variant={"outlined"}
                        name="cellPhone"
                        label="Cell Phone"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    mask="(999) 999-9999"
                    onChange={this.handleInputChange}
                    disabled={false}
                    maskChar=" "
                    value={this.state.homePhone}
                  >
                    {() => (
                      <TextField
                        style={{ width: "100%" }}
                        variant={"outlined"}
                        name="homePhone"
                        label="Home Phone"
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="street"
                    label="Emergency Contact"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="emergencyContact"
                    value={this.state.emergencyContact}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    mask="(999) 999-9999"
                    onChange={this.handleInputChange}
                    disabled={false}
                    maskChar=" "
                    value={this.state.emergencyContactTel}
                  >
                    {() => (
                      <TextField
                        style={{ width: "100%" }}
                        variant={"outlined"}
                        name="emergencyContactTel"
                        label="Tel"
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
              <Typography variant="overline" style={{ textAlign: "left" }}>
                Family Physician
              </Typography>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="phys_name"
                    label="Name"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="familyPhysicianName"
                    value={this.state.familyPhysicianName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    mask="(999) 999-9999"
                    onChange={this.handleInputChange}
                    disabled={false}
                    maskChar=" "
                    value={this.state.familyPhysicianPhone}
                  >
                    {() => (
                      <TextField
                        style={{ width: "100%" }}
                        variant={"outlined"}
                        name="familyPhysicianPhone"
                        label="Phone #"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    ref="phys_address"
                    label="Address"
                    id="margin-none"
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                    variant={"outlined"}
                    name="familyPhysicianAddress"
                    value={this.state.familyPhysicianAddress}
                  />
                </Grid>
              </Grid>
              <Typography variant="overline" style={{ textAlign: "left" }}>
                Health Questions
              </Typography>
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Are you currently receiving treatment from other health care
                professionals, such as fertility, pediatrics, neurology...?
                Which clinic? How long?
              </Typography>
              <TextField
                id="outlined-multiline-static"
                label="Answer"
                onChange={this.handleInputChange}
                multiline
                rows={3}
                style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                variant="outlined"
                name="otherTreatment"
                value={this.state.otherTreatment}
              />
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Health Condition Required Attention/Reason for Treatment:
              </Typography>
              <TextField
                id="outlined-multiline-static"
                onChange={this.handleInputChange}
                label="Answer"
                multiline
                rows={3}
                style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                variant="outlined"
                name="healthConditionRequiringTreatment"
                value={this.state.healthConditionRequiringTreatment}
              />
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Are you on medications? Yes/No. If yes, for what condition?
              </Typography>
              <TextField
                id="outlined-multiline-static"
                onChange={this.handleInputChange}
                label="Answer"
                multiline
                rows={3}
                style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                variant="outlined"
                name="onMedication"
                value={this.state.onMedication}
              />
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Did you travel outside of Canada in past 3 months? Where and
                When?
              </Typography>
              <TextField
                id="outlined-multiline-static"
                onChange={this.handleInputChange}
                label="Answer"
                multiline
                rows={3}
                style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                variant="outlined"
                name="travel"
                value={this.state.travel}
              />
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Do you have any allergies? If yes, please specify.
              </Typography>
              <TextField
                id="outlined-multiline-static"
                onChange={this.handleInputChange}
                label="Answer"
                multiline
                rows={3}
                style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                variant="outlined"
                name="allergies"
                value={this.state.allergies}
              />
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Did you have any surgery? Specify date and nature of the
                surgery.
              </Typography>
              <TextField
                id="outlined-multiline-static"
                onChange={this.handleInputChange}
                label="Answer"
                multiline
                rows={3}
                style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                variant="outlined"
                name="surgery"
                value={this.state.surgery}
              />
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Did you have any injury? Specify date and nature of the injury.
              </Typography>
              <TextField
                id="outlined-multiline-static"
                onChange={this.handleInputChange}
                label="Answer"
                multiline
                rows={3}
                style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                variant="outlined"
                name="injury"
                value={this.state.injury}
              />
              <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                Please indicate conditions you are experiencing or have
                experienced. Select the ones you have.
              </Typography>
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Cardiovascular
                  </Typography>{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.pacemaker}
                        onChange={this.handleCheckBoxChange}
                        name="pacemaker"
                        color="primary"
                        value={this.state.pacemaker}
                      />
                    }
                    label="Are you wearing a pacemaker?"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.metal}
                        onChange={this.handleCheckBoxChange}
                        name="metal"
                        color="primary"
                        value={this.state.metal}
                      />
                    }
                    label="Metal in your body?"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.highBloodPressure}
                        onChange={this.handleCheckBoxChange}
                        name="highBloodPressure"
                        color="primary"
                        value={this.state.highBloodPressure}
                      />
                    }
                    label="High blood pressure"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.lowBloodPressure}
                        onChange={this.handleCheckBoxChange}
                        name="lowBloodPressure"
                        color="primary"
                        value={this.state.lowBloodPressure}
                      />
                    }
                    label="Low blood pressure"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.chronicCongestiveHeartFailure}
                        onChange={this.handleCheckBoxChange}
                        name="chronicCongestiveHeartFailure"
                        color="primary"
                        value={this.state.chronicCongestiveHeartFailure}
                      />
                    }
                    label="Chronic congestive heart failure"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.heartAttack}
                        onChange={this.handleCheckBoxChange}
                        name="heartAttack"
                        color="primary"
                        value={this.state.heartAttack}
                      />
                    }
                    label="Heart attack"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.phlebitis}
                        onChange={this.handleCheckBoxChange}
                        name="phlebitis"
                        color="primary"
                        value={this.state.phlebitis}
                      />
                    }
                    label="Phlebitis / varicose veins"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.stroke}
                        onChange={this.handleCheckBoxChange}
                        name="stroke"
                        color="primary"
                        value={this.state.stroke}
                      />
                    }
                    label="stroke / CVA"
                  />
                  <br />
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Date of stroke"
                    style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="strokeWhen"
                    value={this.state.strokeWhen}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.heartDisease}
                        onChange={this.handleCheckBoxChange}
                        name="heartDisease"
                        color="primary"
                        value={this.state.heartDisease}
                      />
                    }
                    label="Heart Disease"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.heartSurgery}
                        onChange={this.handleCheckBoxChange}
                        name="heartSurgery"
                        color="primary"
                        value={this.state.heartSurgery}
                      />
                    }
                    label="Heart Surgery"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.poorCirculation}
                        onChange={this.handleCheckBoxChange}
                        name="poorCirculation"
                        color="primary"
                        value={this.state.poorCirculation}
                      />
                    }
                    label="Poor circulation"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.familyHistoryCario}
                        onChange={this.handleCheckBoxChange}
                        name="familyHistoryCario"
                        color="primary"
                        value={this.state.familyHistoryCario}
                      />
                    }
                    label="Family history of the above"
                  />{" "}
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Respiratory
                  </Typography>{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.chronicCough}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="chronicCough"
                        value={this.state.chronicCough}
                      />
                    }
                    label="Chronic Cough"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.shortnessOfBreath}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="shortnessOfBreath"
                        value={this.state.shortnessOfBreath}
                      />
                    }
                    label="Shortness of Breath"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.bronchitis}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="bronchitis"
                        value={this.state.bronchitis}
                      />
                    }
                    label="Bronchitis"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.asthma}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="asthma"
                        value={this.state.asthma}
                      />
                    }
                    label="Asthma Emphysema"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.familyHistoryResp}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="familyHistoryResp"
                        value={this.state.familyHistoryResp}
                      />
                    }
                    label="Family history of the above?"
                  />
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Digestion
                  </Typography>{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.poorDigestion}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="poorDigestion"
                        value={this.state.poorDigestion}
                      />
                    }
                    label="Poor digestion"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.IBS}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="IBS"
                        value={this.state.IBS}
                      />
                    }
                    label="IBS"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.constipation}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="constipation"
                        value={this.state.constipation}
                      />
                    }
                    label="Constipation or Diarrhea"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.liver}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="liver"
                        value={this.state.liver}
                      />
                    }
                    label="Liver/Gallbladder"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.kidney}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="kidney"
                        value={this.state.kidney}
                      />
                    }
                    label="Kidney/Bladder"
                  />
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Head/Neck
                  </Typography>{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.headaches}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="headaches"
                        value={this.state.headaches}
                      />
                    }
                    label="History of headaches"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.migraines}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="migraines"
                        value={this.state.migraines}
                      />
                    }
                    label="History of migraines"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.visionProblems}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="visionProblems"
                        value={this.state.visionProblems}
                      />
                    }
                    label="Vision Problems"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.visionLoss}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="visionLoss"
                        value={this.state.visionLoss}
                      />
                    }
                    label="Vision Loss"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.earProblems}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="earProblems"
                        value={this.state.earProblems}
                      />
                    }
                    label="Ear Problems"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.hearingLoss}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="hearingLoss"
                        value={this.state.hearingLoss}
                      />
                    }
                    label="Hearing Loss"
                  />
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Muscle and Joint
                  </Typography>{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.pain}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="pain"
                        value={this.state.pain}
                      />
                    }
                    label="Pain"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.stiffness}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="stiffness"
                        value={this.state.stiffness}
                      />
                    }
                    label="Stiffness"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.swelling}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="swelling"
                        value={this.state.swelling}
                      />
                    }
                    label="Swelling"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.limitedMotion}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="limitedMotion"
                        value={this.state.limitedMotion}
                      />
                    }
                    label="Limited motion"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.fatigue}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="fatigue"
                        value={this.state.fatigue}
                      />
                    }
                    label="Fatigue"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.osteoarthritis}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="osteoarthritis"
                        value={this.state.osteoarthritis}
                      />
                    }
                    label="Osteoarthritis"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.backPainUpper}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="backPainUpper"
                        value={this.state.backPainUpper}
                      />
                    }
                    label="Back pain upper"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.backPainMid}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="backPainMid"
                        value={this.state.backPainMid}
                      />
                    }
                    label="Back pain Mid"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.backPainLower}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="backPainLower"
                        value={this.state.backPainLower}
                      />
                    }
                    label="Back pain lower"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.shoulderPain}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="shoulderPain"
                        value={this.state.shoulderPain}
                      />
                    }
                    label="Shoulder pain"
                  />
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Infections
                  </Typography>{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.hepA}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="hepA"
                        value={this.state.hepA}
                      />
                    }
                    label="Hepatitis A"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.hepB}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="hepB"
                        value={this.state.hepB}
                      />
                    }
                    label="Hepatitis B"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.hepC}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="hepC"
                        value={this.state.hepC}
                      />
                    }
                    label="Hepatitis C"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.TB}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="TB"
                        value={this.state.TB}
                      />
                    }
                    label="TB"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.HIV}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="HIV"
                        value={this.state.HIV}
                      />
                    }
                    label="HIV"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.herpes}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="herpes"
                        value={this.state.herpes}
                      />
                    }
                    label="herpes"
                  />
                  <br />
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="others"
                    style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="infectionsOther"
                    value={this.state.infectionsOther}
                  />
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Skin Conditions
                  </Typography>{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.sensitiveSkin}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="sensitiveSkin"
                        value={this.state.sensitiveSkin}
                      />
                    }
                    label="Sensitive Skin"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.rashes}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="rashes"
                        value={this.state.rashes}
                      />
                    }
                    label="Rashes"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.acne}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="acne"
                        value={this.state.acne}
                      />
                    }
                    label="Acne"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.coldSores}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="coldSores"
                        value={this.state.coldSores}
                      />
                    }
                    label="Cold sores"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.bleedingDisorder}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="bleedingDisorder"
                        value={this.state.bleedingDisorder}
                      />
                    }
                    label="Bleeding Disorder"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.bruiseEasily}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="bruiseEasily"
                        value={this.state.bruiseEasily}
                      />
                    }
                    label="Bruise Easily"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.varicoseVeins}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="varicoseVeins"
                        value={this.state.varicoseVeins}
                      />
                    }
                    label="Varicose veins"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.deepVeinThrombosis}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="deepVeinThrombosis"
                        value={this.state.deepVeinThrombosis}
                      />
                    }
                    label="Deep Vein Thrombosis"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.eczema}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="eczema"
                        value={this.state.eczema}
                      />
                    }
                    label="Eczema/psoriasis"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.tattoos}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="tattoos"
                        value={this.state.tattoos}
                      />
                    }
                    label="Recent tattoos/piercing"
                  />
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Other Conditions
                  </Typography>{" "}
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Loss of sensation, when and where?
                  </Typography>{" "}
                  <br />
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Answer"
                    multiline
                    rows={3}
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="lossOfSensation"
                    value={this.state.lossOfSensation}
                  />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    diabetes, onset
                  </Typography>{" "}
                  <br />
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    multiline
                    rows={3}
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="diabetes"
                    value={this.state.diabetes}
                  />{" "}
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    cancer, where?
                  </Typography>{" "}
                  <br />
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Answer"
                    multiline
                    rows={3}
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="cancer"
                    value={this.state.cancer}
                  />{" "}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.epilepsy}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="epilepsy"
                        value={this.state.epilepsy}
                      />
                    }
                    label="Epilepsy"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.multipleSclerosis}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="multipleSclerosis"
                        value={this.state.multipleSclerosis}
                      />
                    }
                    label="Multiple Sclerosis"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.arthritis}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="arthritis"
                        value={this.state.arthritis}
                      />
                    }
                    label="Arthritis"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.familyHistoryArthritis}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="familyHistoryArthritis"
                        value={this.state.familyHistoryArthritis}
                      />
                    }
                    label="Family history of Arthritis?"
                  />
                  <br />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    Women
                  </Typography>{" "}
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    pregnant, due
                  </Typography>{" "}
                  <br />
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Answer"
                    multiline
                    rows={3}
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="pregnant"
                    value={this.state.pregnant}
                  />{" "}
                  <br />
                  <FormControl
                    variant="outlined"
                    style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Menstruation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // value={}
                      onChange={this.handleInputChange}
                      label="Menstruation"
                      name="menstruation"
                      value={this.state.menstruation}
                    >
                      <MenuItem value="Painful">Painful</MenuItem>
                      <MenuItem value="Heavy">Heavy</MenuItem>
                      <MenuItem value="Light">Light</MenuItem>
                      <MenuItem value="Normal">Normal</MenuItem>
                      <MenuItem value="Irregular">Irregular</MenuItem>
                      <MenuItem value="Absent">Absent</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Menopause
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Answer"
                    multiline
                    rows={3}
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="menopause"
                    value={this.state.menopause}
                  />
                  <Typography variant="overline" style={{ textAlign: "left" }}>
                    General Health
                  </Typography>
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    General Health Status
                  </Typography>
                  <FormControl
                    variant="outlined"
                    style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // value={}
                      onChange={this.handleInputChange}
                      label="Status"
                      name="generalHealthStatus"
                      value={this.state.generalHealthStatus}
                    >
                      <MenuItem value="Good">Good</MenuItem>
                      <MenuItem value="Average">Average</MenuItem>
                      <MenuItem value="Poor">Poor</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    General Stress Levels
                  </Typography>
                  <FormControl
                    variant="outlined"
                    style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Stress
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // value={}
                      onChange={this.handleInputChange}
                      label="Stress"
                      name="generalStressLevels"
                      value={this.state.generalStressLevels}
                    >
                      <MenuItem value="High">High</MenuItem>
                      <MenuItem value="Moderate">Moderate</MenuItem>
                      <MenuItem value="Low">Low</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Diet
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.regularMeals}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="regularMeals"
                        value={this.state.regularMeals}
                      />
                    }
                    label="Regular meals"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.irregularEating}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="irregularEating"
                        value={this.state.irregularEating}
                      />
                    }
                    label="Irregular Eating"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.caffeine}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="caffeine"
                        value={this.state.caffeine}
                      />
                    }
                    label="Caffeine"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.smoke}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="smoke"
                        value={this.state.smoke}
                      />
                    }
                    label="Smoke-Pkg(s)/day"
                  />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Exercise
                  </Typography>
                  <FormControl
                    variant="outlined"
                    style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Exercise
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // value={}
                      onChange={this.handleInputChange}
                      label="exercise"
                      name="exercise"
                      value={this.state.exercise}
                    >
                      <MenuItem value="Regular">Regular</MenuItem>
                      <MenuItem value="Occasional">Occasional</MenuItem>
                      <MenuItem value="Little">Little</MenuItem>
                      <MenuItem value="None">None</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Previous Health Care
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.previousMassage}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="previousMassage"
                        value={this.state.previousMassage}
                      />
                    }
                    label="Massage Therapy"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.previousChiro}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="previousChiro"
                        value={this.state.previousChiro}
                      />
                    }
                    label="Chiropractic"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.previousPhysio}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="previousPhysio"
                        value={this.state.previousPhysio}
                      />
                    }
                    label="Physiotherapy"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.previousAcu}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="previousAcu"
                        value={this.state.previousAcu}
                      />
                    }
                    label="Acupuncture"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.previousOsteo}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="previousOsteo"
                        value={this.state.previousOsteo}
                      />
                    }
                    label="Osteopathy"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.previousPsycho}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="previousPsycho"
                        value={this.state.previousPsycho}
                      />
                    }
                    label="Psychotherapy"
                  />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Date of last full physical
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="dateOfLastFullPhysical"
                    value={this.state.dateOfLastFullPhysical}
                  />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Do you have any other medical conditions? (e.g. depression,
                    mental illness, anemia, gout, haemophilia, osteoporosis…)
                    what?
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="otherMedicalConditions"
                    value={this.state.otherMedicalConditions}
                  />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Do you have any internal pins, wires, artificial joints or
                    special equipment? what? where?
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    onChange={this.handleInputChange}
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="internalPins"
                    value={this.state.internalPins}
                  />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Dental surgery/procedure
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="dentalSurgery"
                    value={this.state.dentalSurgery}
                  />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Of Special Note: (Pins, Wires, Prosthetics, Walker, Cane,
                    Oral Appliances etc.)
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="ofSpecialNote"
                    value={this.state.ofSpecialNote}
                  />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    For pain relief patients only: using these diagrams circle
                    any areas of discomfort
                  </Typography>
                  {/* Canvas */}
                  <SignatureCanvas
                    penColor="black"
                    ref={(ref) => {
                      this.sigCanvas = ref;
                    }}
                    canvasProps={{
                      width: 652,
                      height: 446,
                      className: "sigCanvas",
                    }}
                  />
                  {/* <canvas
                                        ref={canvasA => this.canvasA = canvasA} /> */}
                  <br />
                  <Button onClick={this.clear}>Clear</Button>
                  <br />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Additional information or details
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="additionalInfo"
                    value={this.state.additionalInfo}
                  />
                  <br />
                  <br /> <br />
                  <Typography variant="h3" style={{ textAlign: "left" }}>
                    Informed Consent Forms
                  </Typography>
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Which of the following services are you planning on
                    recieving from Appleby Wellness? If you are unsure, select
                    all.
                  </Typography>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentAcupuncture}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentAcupuncture"
                        value={this.state.consentAcupuncture}
                      />
                    }
                    label="Acupuncture/Traditional Chinese Medicine"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentOsteopathy}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentOsteopathy"
                        value={this.state.consentOsteopathy}
                      />
                    }
                    label="Osteopathy"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentMassage}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentMassage"
                        value={this.state.consentMassage}
                      />
                    }
                    label="Massage"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentTelus}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentTelus"
                        value={this.state.consentTelus}
                      />
                    }
                    label="Direct Billing Consent Form"
                  />
                  <br />
                </Grid>
              </Grid>
              {/* Acupuncture Consent Form */}
              {this.state.consentAcupuncture && (
                <div>
                  <Typography variant="h4" style={{ textAlign: "left" }}>
                    Acupuncture/Traditional Chinese Medicine Service Consent
                    Form
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please carefully read this information, and if you have any
                    questions, please ask our practitioner(s). <br />
                    <br />
                    <b>Privacy Policy</b> <br />
                    The information received and collected about our
                    clients/patients from their visit(s) to Appleby Wellness
                    Centre is private and confidential. It is only used and
                    viewed by the healthcare professionals and staff employed or
                    contracted by our centre, unless, in the best interest of
                    the client/patient, a practitioner determines that there is
                    a need to communicate with another person or healthcare
                    professional outside of Appleby Wellness Centre (also, we
                    will not give, share, sell, or transfer any personal
                    information to a third party unless required by law).
                    Appleby Wellness Centre communicates with our clients by
                    mail, email and or other electronic channels. On occasion,
                    we may use client/patient information to conduct clinical
                    studies to help us improve upon services provided. Appleby
                    Wellness Centre may allow interns and students to get access
                    to clinical files to clinical files for observations and
                    clinical placements purposes.
                    <br />
                    <br />
                    <b>Statement of Consent to Treatment</b>
                    <br />
                    I, or the person listed below, have discussed with my
                    Traditional Chinese Medicine Practitioner or Acupuncturist
                    the specifics of my assessment or treatment and understand
                    the nature, risks and reasons for this procedure. I
                    voluntarily consent to Traditional Chinese
                    Medicine/Acupuncture and understand that I may withdraw my
                    consent and halt my participation at any time.
                    <br />
                    <br />
                    <b>1.Techniques:</b> I understand that some of the
                    techniques used under the scope of Traditional Chinese
                    Medicine include the use of sterile, single-use needles to
                    penetrate the skin. Additional treatment methods can
                    include, but are not limited to: acupuncture, acupressure,
                    the electrical stimulation of needles, cupping or
                    moxibustion, gua sha, and acupressure including
                    Tuina(Chinese massage) and shiatsu massage. Before any of
                    these procedures are performed, may practitioner will
                    discuss my treatment options and only proceed with my
                    consent.
                    <br />
                    <br />
                    <b>2.Risks & Symptoms:</b> I understand that the risks and
                    symptoms of treatments, which can include, but are not
                    limited to: slight pain, light headaches, dizziness or
                    nausea, soreness, bruising, bleeding or discolouration of
                    the skin, and the possibility of other unforeseen risks,
                    such as spontaneous miscarriage, nerve damage and organ
                    puncture, and infection. I freely accept the risks involved
                    with my procedure.
                    <br />
                    <br />
                    <b>3.Health Issues:</b> I will inform my practitioner if I
                    currently have or develop any major heath issues, if I
                    suffer from any type of major bleeding disorder, if I am or
                    believe to be pregnant or if I use a pacemaker or I have
                    metal in my body. I understand that I must let my
                    practitioner know if I am carrying, or believe to have any
                    infectious agents, including but at not limited to HIV/AIDS,
                    TB and Hepatitis. In some cases where cross-infection is
                    high, my practitioner may withhold treatment.
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please check all that may apply
                  </Typography>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentBleedEasily}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentBleedEasily"
                        value={this.state.consentBleedEasily}
                      />
                    }
                    label="I bleed or bruise easily or have a major bleeding disorder"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentPregnant}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentPregnant"
                        value={this.state.consentPregnant}
                      />
                    }
                    label="I am pregnant or plan on becoming pregnant"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentHIV}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentHIV"
                        value={this.state.consentHIV}
                      />
                    }
                    label="I know/suspect I have HIV/AIDS, Hepatitis, TB, others"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentPacemaker}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentPacemaker"
                        value={this.state.consentPacemaker}
                      />
                    }
                    label="I use a pacemaker"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentMetal}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentMetal"
                        value={this.state.consentMetal}
                      />
                    }
                    label="I have metal in my body"
                  />
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    <b>4.Results:</b> I understand that there are no guarantees
                    for the results of my treatments. Traditional Chinese
                    Medicine/Acupuncture does not often provide an instant cure.
                    The length of my treatment depends on the severity of my
                    condition. In some cases my symptoms my temporarily worsen
                    before they begin to improve.
                    <br />
                    <br />
                    <b>5.Fees:</b> I understand that the fees charged for my
                    treatment are not covered under OHIP and must be covered in
                    full by myself or through third party insurance. I am
                    responsible for the full and prompt payment after services
                    have been rendered.
                    <br />
                    <br />
                    <b>6.Appointment Policy :</b> All the treatments are offered
                    by appointment.{" "}
                    <b>
                      <u>A 24 hour notice</u> for cancelled or rescheduled
                      appointments is required in order to avoid the $60.00
                      cancellation fee.
                    </b>{" "}
                    Occasionally, if you are going to be more than 15 minutes
                    late, please call to confirm availability.
                    <br />
                    <br />
                    <b>7.Informed Consent:</b> I have discussed the content of
                    this form with my practitioner. I acknowledge that I have
                    asked any questions I may have and received answers I
                    understand. By signing this form, I give my informed consent
                    for Traditional Chinese Medicine/Acupuncture Treatments.
                  </Typography>
                  <br /> <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please sign in the box below.
                  </Typography>
                  <div
                    style={{
                      borderStyle: "dotted",
                      borderWidth: 2,
                      borderColor: "#D3D3D3",
                      width: 530,
                      height: 240,
                      borderRadius: 4,
                    }}
                  >
                    <SignatureCanvas
                      penColor="black"
                      ref={(ref) => {
                        this.consentAcupunctureSig = ref;
                      }}
                      canvasProps={{
                        width: 530,
                        height: 240,
                        className: "consentAcupunctureSig",
                      }}
                    />
                  </div>
                  <br />
                  <Button onClick={this.clearAcu}>Clear</Button>
                  <br />
                  <br />
                </div>
              )}
              {this.state.consentOsteopathy && (
                <div>
                  <Typography variant="h4" style={{ textAlign: "left" }}>
                    Osteopathy Consent Form
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please carefully read this information, and if you have any
                    questions, please ask our practitioner(s).
                    <br /> <br />
                    The information received and collected about our
                    clients/patients from their visit(s) to our centre is
                    private and confidential. It is only used and viewed by the
                    healthcare professionals and staff employed or contracted by
                    our centre, unless, in the best interest of the
                    client/patient, a practitioner determines that there is a
                    need to communicate with another person or healthcare
                    professional outside of our centre (also, we will not give,
                    share, sell, or transfer any personal information to a third
                    party unless required by law). Our centre communicates with
                    our clients by mail, email and or other electronic channels.
                    On occasion, we may use client/patient information to
                    conduct clinical studies to help us improve upon services
                    provided. Our centre may allow interns and students to get
                    access to clinical files to clinical files for observations
                    and clinical placements purposes.
                    <br /> <br />
                    I understand that the Osteopath is providing a treatment
                    services within their scope of practice.
                    <br /> <br />
                    I hereby give consent to my Therapist to treat me for the
                    above noted purposes including such assessments,
                    examinations and techniques, which may be recommended, by my
                    Therapist.
                    <br /> <br />
                    I acknowledge that the Therapist is not a physician and does
                    not diagnose illness or disease or any other physical or
                    mental disorder. I clearly understand that an Osteopathy
                    treatment is not a substitute for a medical examination. I
                    acknowledge that no assurance or guarantee has been provided
                    to me as to the results of the treatment. I acknowledge that
                    with any treatment there can be risks and those risks have
                    been explained to me and I assume those risks.
                    <br /> <br />
                    I acknowledge and understand that the Therapist must be
                    fully aware of my existing medical conditions. I have
                    completed my medical history form, as provided by my
                    Therapist, and have disclosed to the Therapist all of those
                    medical conditions affecting me. It is my responsibility to
                    keep the Therapist updated on my medical history. The
                    information I have provided is true and complete to the best
                    of my knowledge.
                    <br /> <br />
                    I understand that patient files and subsequently information
                    contained in files is shared among practitioners for the
                    mutual benefit of patient treatment. I understand that
                    written consent must be obtained for a practitioner to
                    remove/transfer file information.
                    <br /> <br />I understand that{" "}
                    <b>
                      any sexual comments, innuendos or contact will not be
                      tolerated at any time
                    </b>
                    . The therapist reserves the right to terminate the
                    treatment immediately and charge the full fee to violators.
                    <br /> <br />
                    I understand that failure to provide 24 hours notice prior
                    to cancelling an appointment will result in a charge of 20%
                    of the total cost of the originally scheduled treatment.
                    <br /> <br />I have read the above noted consent and have
                    had the opportunity ask questions relating the contents of
                    my therapist. By signing this form, I confirm my consent to
                    treatment and intend this consent to cover the treatment
                    discussed with me and such additional treatment as proposed
                    by my Therapist from time to time, to deal with my physical
                    condition and for which I have sought treatment. I
                    understand that at any time I may withdraw my consent and
                    treatment will be stopped.
                  </Typography>
                  <br /> <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please sign in the box below.
                  </Typography>
                  <div
                    style={{
                      borderStyle: "dotted",
                      borderWidth: 2,
                      borderColor: "#D3D3D3",
                      width: 530,
                      height: 240,
                      borderRadius: 4,
                    }}
                  >
                    <SignatureCanvas
                      penColor="black"
                      ref={(ref) => {
                        this.consentOsteoSig = ref;
                      }}
                      canvasProps={{
                        width: 530,
                        height: 240,
                        className: "consentOsteoSig",
                      }}
                    />
                  </div>
                  <br />
                  <Button onClick={this.clearOsteo}>Clear</Button>
                  <br />
                  <br />
                </div>
              )}
              {this.state.consentMassage && (
                <div>
                  <Typography variant="h4" style={{ textAlign: "left" }}>
                    Massage Therapy Consent Form
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please carefully read this information, and if you have any
                    questions, please ask our practitioner(s).
                    <br /> <br />
                    The information received and collected about our
                    clients/patients from their visit(s) to Appleby Wellness
                    Centre is private and confidential. It is only used and
                    viewed by the healthcare professionals and staff employed or
                    contracted by Appleby Wellness Centre, unless, in the best
                    interest of the client/patient, a practitioner determines
                    that there is a need to communicate with another person or
                    healthcare professional outside of Appleby Wellness Centre
                    (also, we will not give, share, sell, or transfer any
                    personal information to a third party unless required by
                    law). Appleby Wellness Centre communicates with our clients
                    by mail, email and or other electronic channels. On
                    occasion, we may use client/patient information to conduct
                    clinical studies to help us improve upon services provided.
                    Appleby Wellness Centre may allow interns and students to
                    get access to clinical files to clinical files for
                    observations and clinical placements purposes.
                    <br /> <br />
                    “The practice of massage therapy is the assessment of the
                    soft tissue and joints of the body and the treatment and
                    prevention of physical dysfunction and pain of the soft
                    tissues and joints by manipulation to develop, maintain,
                    rehabilitate or augment physical function, or relieve pain.”
                    Massage Therapy Act, 1991
                    <br /> <br />
                    All information collected is to help formulate a clinical
                    understanding of your condition and will be kept
                    confidential, except where required by law, or to facilitate
                    a diagnosis. A signed consent form is required before I
                    contact any other health provider for your personal health
                    information. An up to date and accurate health history is
                    essential in the delivery of appropriate techniques involved
                    with massage therapy treatments. Please advise Appleby
                    Wellness Centre and practitioner(s) if you are receiving
                    care from another health care provider for any condition you
                    have and a complete list of all medications, including over
                    the counter drugs and supplements and what condition they
                    are treating, which will allow the practitioner(s) to
                    determine if any modifications need to be made to your
                    treatment. Please inform the practitioner(s) of any allergy
                    or hypersensitivity reaction you may have to any oils,
                    lotions, or laundry detergents, fabric softeners etc.
                    <br /> <br />
                    An assessment of your condition or physical discomforts may
                    be necessary to determine the best course of action for
                    treatment and your first treatment has been allotted extra
                    time for assessment purposes. Throughout the course of
                    treatments, additional time for reassessments may be
                    required and may cut into your appointment time. Benefits of
                    massage therapy services can include but are not limited to
                    increased mobility, increased flexibility, decreased stress
                    or anxiety, and relief of muscle or joint pain. Potential
                    risks and or side effects associated with massage therapy
                    can include, but are not limited to, bruising, swelling,
                    tissue congestion, nausea, dizziness, temporary muscle
                    tenderness or soreness, and aggravation of existing
                    symptoms.
                    <br /> <br />
                    After every massage treatment it is important to stay
                    hydrated by drinking plenty of water to help flush out any
                    toxins released from the tissues during the massage, unless
                    fluid intake is restricted by a physician. It is also
                    advised that you apply cold to any tender or sore areas to
                    decrease tissue congestion and chances of bruising. Results
                    often vary from person to person depending on the goals of
                    the individual. Following home care suggestions will help
                    you achieve your goals; however, it may be necessary to seek
                    out other health care providers to assist in achieving your
                    health goals.
                    <br /> <br />I have read the above statement and acknowledge
                    that I am able to withdraw my consent at any time and
                    discontinue treatment at any time. I also understand that I
                    have the right to ask any questions I have about any aspect
                    of the assessment, treatment or about any of the information
                    being collected as they relate to me and my treatment plan.
                  </Typography>
                  <br /> <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please sign in the box below.
                  </Typography>
                  <div
                    style={{
                      borderStyle: "dotted",
                      borderWidth: 2,
                      borderColor: "#D3D3D3",
                      width: 530,
                      height: 240,
                      borderRadius: 4,
                    }}
                  >
                    <SignatureCanvas
                      penColor="black"
                      ref={(ref) => {
                        this.consentMassageSig = ref;
                      }}
                      canvasProps={{
                        width: 530,
                        height: 240,
                        className: "consentMassageSig",
                      }}
                    />
                  </div>
                  <br />
                  <Button onClick={this.clearMassage}>Clear</Button>
                  <br />
                  <br />
                </div>
              )}
              {/* Acupuncture Consent Form */}
              {this.state.consentTelus && (
                <div>
                  <Typography variant="h4" style={{ textAlign: "left" }}>
                    Electronic transmission authorization and consent form
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Instructions: This form must be filled out when claims are
                    submitted electronically by the provider on the patient’s
                    behalf. Please retain this form in the patient’s file for
                    verification purposes for two years following closure of the
                    patient file. <br />
                    <br />
                    <b>
                      Consent to collect and exchange personal information
                    </b>{" "}
                    <br />
                    <i>Purpose</i>
                    Personal information that we collect and disclose about you,
                    and if applicable, is used by the insurer, and/or plan
                    administrator of your group benefits plan, its affiliates
                    and their service provider(s) for the purposes of assessing
                    eligibility for your claims, underwriting, investigating,
                    auditing and otherwise administering the group benefits
                    plan, including the investigation of fraud and / or plan
                    abuse and for internal data management and data analytical
                    purposes.
                    <br />
                    <br />
                    <i>Authorization and consent</i>
                    <br />
                    I authorize my healthcare provider to collect, use and
                    disclose personal information concerning any claims
                    submitted on my behalf with the insurer and/or plan
                    administrator and their service provider(s) for the above
                    purposes. I authorize such insurer and / or plan
                    administrator and their service provider(s) to:
                    <br />
                    <br />• use my personal information for the above purposes.
                    <br />• exchange personal information with any individual or
                    organization, including healthcare professionals,
                    investigative agencies, insurers and reinsurers, and
                    administrators of government benefits, or other benefits
                    programs, other organizations, or service providers working
                    with such insurer and/or plan administrator or any of the
                    foregoing, when relevant for the above purposes.
                    <br />• where applicable exchange personal information
                    concerning any claims with any assignee of benefits payable
                    and exchange personal information for the above purposes
                    electronically or in any other manner.
                    <br />
                    <br />
                    I understand that personal information may be subject to
                    disclosure to those authorized under applicable law.
                    <br />
                    <br />I agree that a photocopy or electronic version of this
                    authorization shall be as valid as the original, and may
                    remain in effect for the continued administration of the
                    group benefits plan.
                    <br />
                    <br />
                    In the event there is suspicion and/or evidence of fraud
                    and/or plan abuse concerning any claims submitted, I
                    acknowledge and agree that the insurer and/or plan
                    administrator and their service provider(s) may use and
                    disclose relevant personal information to any relevant
                    organization including law enforcement bodies, regulatory
                    bodies, government organizations, medical suppliers and
                    other insurers, and where applicable my employer or benefit
                    plan sponsor, for the purposes of investigation and
                    prevention of fraud and/or benefit plan abuse. I understand
                    that the submission of fraudulent claims is a criminal
                    offence.
                    <br />
                    <br />
                    If there is an overpayment, I authorize the recovery of the
                    full amount of the overpayment from any amount payable under
                    the group benefits plan, and the exchange of personal
                    information with other persons or organizations, including
                    credit agencies and, where applicable, my benefit plan
                    sponsor, for that purpose.
                    <br />
                    <br />
                    If the patient is a person other than myself, I confirm that
                    the patient has given their consent to provide their
                    personal information for the healthcare provider and the
                    insurer and/or plan administrator and their service
                    provider(s) to use and disclose their personal information
                    as set out above.
                    <br />
                    <br />
                    <b>Benefit assignment form</b>
                    <br />
                    I hereby assign benefits payable for the eligible claims to
                    the healthcare provider responsible for submitting my claims
                    electronically to the group benefits plan and I authorize
                    the insurer/plan administrator to issue payment directly to
                    such provider. In the event my claim(s) are declined by the
                    insurer/plan administrator, I understand that I remain
                    responsible for payment to the healthcare provider for any
                    ser- vices rendered and/ or supplies provided.
                    <br />
                    <br />I acknowledge and agree that the insurer/plan
                    administrator is under no obligation to accept this benefit
                    assignment form, that any benefit payment made in accordance
                    with this benefit assignment form will discharge the
                    insurer/plan administrator of its obligations with respect
                    to that benefit payment, and that in the event the benefit
                    payment is made to me, the insurer/plan administrator will
                    also be discharged of its obligation with respect to that
                    benefit payment.
                    <br />
                    <br />I understand that this assignment will apply to all
                    eligible claims submitted electronically by my healthcare
                    provider and that I may revoke it at any time by providing
                    written notice to the insurer/plan administrator.
                    <br />
                    <br />
                    If I am a spouse or dependent, I confirm that I am
                    authorized by the plan member to execute an assignment of
                    benefit payments to the healthcare provider.
                  </Typography>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.consentAcceptTerms}
                        onChange={this.handleCheckBoxChange}
                        color="primary"
                        name="consentAcceptTerms"
                        value={this.state.consentAcceptTerms}
                      />
                    }
                    label="I accept the terms and conditions"
                  />
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please fill in the following information
                  </Typography>
                  <br />
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="First Name"
                    style={{ marginBottom: 20, marginTop: 10, marginRight: 20 }}
                    variant="outlined"
                    name="firstName"
                    value={this.state.firstName}
                  />
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Last Name"
                    style={{ marginBottom: 20, marginTop: 10 }}
                    variant="outlined"
                    name="lastName"
                    value={this.state.lastName}
                  />
                  <br />
                  <TextField
                    id="date"
                    label="Birth Date"
                    type="date"
                    defaultValue="2000-00-00"
                    variant={"outlined"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginRight: 20 }}
                    onChange={this.handleInputChange}
                    name="dob"
                    value={this.state.dob}
                  />
                  <FormControl variant="outlined" style={{ width: 195 }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // value={}
                      onChange={this.handleInputChange}
                      label="Gender"
                      name="gender"
                      value={this.state.gender}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Primary coverage insurer/payer:
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="primaryCoverage"
                    value={this.state.primaryCoverage}
                  />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Primary coverage plan member name:
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="planMember"
                    value={this.state.planMember}
                  />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Primary coverage policy number (also referred to as group or
                    contract number):
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="policyNumber"
                    value={this.state.policyNumber}
                  />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    Primary coverage certificate (also referred to as
                    member/identification number):
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="coverageCert"
                    value={this.state.coverageCert}
                  />
                  <Typography variant="subtitle2" style={{ textAlign: "left" }}>
                    (Canada Life only) secondary coverage plan member name
                  </Typography>
                  <TextField
                    onChange={this.handleInputChange}
                    id="outlined-multiline-static"
                    label="Answer"
                    style={{ marginBottom: 20, marginTop: 10, width: "100%" }}
                    variant="outlined"
                    name="canadaLife"
                    value={this.state.canadaLife}
                  />
                  <br /> <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Please sign in the box below.
                  </Typography>
                  <div
                    style={{
                      borderStyle: "dotted",
                      borderWidth: 2,
                      borderColor: "#D3D3D3",
                      width: 530,
                      height: 240,
                      borderRadius: 4,
                    }}
                  >
                    <SignatureCanvas
                      penColor="black"
                      ref={(ref) => {
                        this.consentTelusSig = ref;
                      }}
                      canvasProps={{
                        width: 530,
                        height: 240,
                        className: "consentTelusSig",
                      }}
                    />
                  </div>
                  <br />
                  <Button onClick={this.clearTelus}>Clear</Button>
                  <br />
                  <br />
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    All information contained herein is protected by privacy
                    laws including the Personal Information Protection and
                    Electronic Documents Act (PIPEDA) and all the corresponding
                    provincial legislation. All users agree to protect the
                    personal health information contained herein from
                    unauthorized use, disclosure, loss, theft, or compromise in
                    accordance with the above noted laws and with at least the
                    same care employed to protect their own confidential
                    information. Any unauthorized access, disclosure or use of
                    this information is illegal.
                  </Typography>
                  <br />
                  <br />
                  <hr style={{ opacity: "20%" }} />
                </div>
              )}
              <Grid container style={{ marginBottom: 20 }} spacing={2}>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>
              </Grid>
              <Typography variant="subtitle1">
                Once you click submit, you will be redirected back to the home
                page. Please select your name and sign in from there.
              </Typography>{" "}
              <br />
              <br />
              {this.state.displayError && (
                <p style={{ color: "red" }}>
                  Please make sure you filled in the form correctly.
                </p>
              )}
              {this.state.email &&
              this.state.firstName &&
              this.state.lastName &&
              this.state.cellPhone ? (
                <Button
                  style={{
                    marginTop: 30,
                    width: 120,
                    borderRadius: 20,
                    color: "002BB8",
                  }}
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  Submit!
                </Button>
              ) : (
                <Button
                  disabled
                  style={{
                    marginTop: 30,
                    width: 120,
                    borderRadius: 20,
                    color: "002BB8",
                  }}
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  Submit!
                </Button>
              )}
              {/* <Button style={{ marginTop: 30, width: 120, borderRadius: 20, color: "002BB8" }} type="submit" variant="contained" disableElevation color="primary" onClick={this.handleSubmit}
                            >
                                Submit!
                            </Button> */}
              <br />
              <br />
              <hr style={{ opacity: "20%" }} />
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patients: state.patients,
    visits: state.visits,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
  })
)(withRouter(IntakeForm));
