import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";
import SignatureImage from "./img/sig.png";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";

import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";

import Moment from "react-moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Invoice from "./components/invoice";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#7200B8",
    },
    info: {
      main: "#3177EA",
    },
  },
});

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MyDoc = (
  <Document>
    <Page>// My document data</Page>
  </Document>
);

const styles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

class Patient extends Component {
  constructor(props) {
    // console.log(
    //     "Hit Patient Component"
    // )
    super(props);
    this.classes = props;
    // console.log("id", this.props.match.params.id)
    this.state = {
      id: null,
      search: null,
      moreInfoDialogueOpen: false,
      visits: "",
      patient: "",
      patients: this.props.patients,
      resendInvoiceDisabled: false,
      resendSnackbar: false,
      resendSnackbarError: false,
      // allow editing of events
      id: null,
      recordPaymentDialogueOpen: false,
      focusedVisit: {
        _id: "",
        notes: "First Visit",
        patient: {
          name: "loading..",
          cellPhone: "loading..",
          email: null,
          firstVisit: false,
          id: "loading",
        },
        date: { start: "", end: "" },
        service: [
          {
            type: "loading...",
            price: { gross: 0, tax: 0, net: 0 },
            paid: false,
            invoice: { yn: false, link: null },
            practitioner: { id: "", name: "Loading..." },
          },
        ],
        signIn: { yn: false, time: null, signature: null },
        signOut: { yn: false, time: null, signature: null },
        blocked: false,
        __v: 0,
      },
      subtotal: 0,
      totalPrice: 0,
      invoiceYN: false,
      paymentType: "",
      totalTime: "",
      includeTaxYN: false,
      search: null,
      patients: this.props.patients,
      insuranceInfo: "",
      chosenService: null,
      serviceChanged: false,
      services: [
        "Acupuncture",
        "Osteopathy",
        "Massage",
        "Herbs",
        "Products",
        "Other",
      ],
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSubtotalChange = this.handleSubtotalChange.bind(this);
    this.handleNewEventServicesChange =
      this.handleNewEventServicesChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleTotalTimeChange = this.handleTotalTimeChange.bind(this);
    this.handleInsuranceInfoChange = this.handleInsuranceInfoChange.bind(this);
    this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
    this.handleInvoiceChange = this.handleInvoiceChange.bind(this);
  }
  componentDidMount() {
    this.setState({
      id: this.props.match.params.id,
    });
    // console.log("Prop", this.props.match.params.id)

    // get patient info for chosen patient
    axios
      .get(
        process.env.REACT_APP_DOMAIN + `/patients/${this.props.match.params.id}`
      )
      .then(({ data }) => {
        // console.log("visits", data)
        this.setState({
          patient: data,
        });
      });

    // get all visits for a particular patient
    axios
      .get(
        process.env.REACT_APP_DOMAIN +
          `/visits/patient/${this.props.match.params.id}`
      )
      .then(({ data }) => {
        // console.log("visits", data)
        this.setState({
          visits: data,
        });
      });
    // console.log(this.props.admins);
  }

  handleSubtotalChange = (arg) => {
    this.setState({
      subtotal: arg.target.value,
    });
    // recalculate total price
    this.calculateTotalPrice(arg.target.value, this.state.includeTaxYN);
    // if (this.state.totalPrice < arg.target.value) {
    //   this.setState({
    //     totalPrice: arg.target.value,
    //   });
    // }
  };

  returnAdminInfo = (id, _name) => {
    const admin = this.props.admins.find(({ _id }) => _id === id);
    const adminByName = this.props.admins.find(({ name }) => name === _name);
    if (admin) {
      const adminDetails = {
        name: admin.name,
        registration: admin.description,
        title: admin.title,
        hst: admin.hst,
      };
      return adminDetails;
    } else if (adminByName) {
      const adminDetails = {
        name: adminByName.name,
        registration: adminByName.description,
        title: adminByName.title,
        hst: adminByName.hst,
      };
      return adminDetails;
    } else {
      return {
        name: "Annie Zhu",
        title:
          "Registered Acupuncturist & Traditional Chinese Medicine Practitioner",
        hst: "",
        registration: "Reg#784",
      };
    }
  };

  handleNewEventServicesChange = (arg) => {
    this.setState((prevState) => ({
      chosenService: arg,
      serviceChanged: true,
      // focusedVisit: {
      //     service[0]: {

      //     }
      //     ...prevState.focusedVisit,
      //     type: clickedDate.start,
      //     endTime: clickedDate.end,
      //     date: clickedDate.date,
      // }
    }));
  };

  calculateTotalPrice = (subtotal, tax) => {
    // recalculate the total price
    //  use subtotal and includeTaxYN
    console.log("update total price");
    if (tax) {
      let tax = subtotal * 1.13;
      this.setState({
        totalPrice: (Math.round(tax * 100) / 100).toFixed(2),
      });
    } else {
      this.setState({
        totalPrice: subtotal,
      });
    }
  };

  handleTaxChange = (arg) => {
    this.setState({
      includeTaxYN: arg.target.checked,
    });
    this.calculateTotalPrice(this.state.subtotal, arg.target.checked);
  };

  handleTotalTimeChange = (arg) => {
    this.setState({
      totalTime: arg.target.value,
    });
  };
  handleInsuranceInfoChange = (arg) => {
    this.setState({
      insuranceInfo: arg.target.value,
    });
  };
  handlePaymentTypeChange = (arg) => {
    this.setState({
      paymentType: arg.target.value,
    });
  };
  handleInvoiceChange = (arg) => {
    this.setState({
      invoiceYN: arg.target.checked,
    });
  };

  componentWillReceiveProps(nextState) {
    // console.log('next state', nextState.visits)

    this.setState({
      patient: nextState.patients.find(
        ({ _id }) => _id === this.props.match.params.id
      ),
      visits: nextState.visits.filter(
        ({ patient }) => patient.id === this.props.match.params.id
      ),
    });
  }

  deletePatientClick = () => {
    console.log("DELETE PATIENT STATE", this.props.match.params.id);
    var promise = new Promise((resolve) => {
      var payload = {
        id: this.props.match.params.id,
      };
      console.log(payload);
      this.deletePatientReq(resolve, payload);
    });
    return promise;
  };

  deletePatientReq(resolve, payload) {
    console.log("URL", process.env.REACT_APP_DOMAIN + "/patient/delete");
    console.log("payload", payload);
    axios
      .post(process.env.REACT_APP_DOMAIN + "/patient/delete", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // this.props.fetchPatients();
        this.props.history.push("/patients");
        console.log(response);

        if (response.data.successful === false) {
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  handleSearchChange = (arg) => {
    // console.log(arg)
    this.setState({ search: arg });
    if (arg) {
      this.props.history.push("/patient/" + arg._id);
    }
  };

  handleRecordPaymentClick = (visitID) => {
    // console.log(visitID)
    const selectedEvent = this.state.visits.find(({ _id }) => _id === visitID);
    console.log("selected Event", selectedEvent, this.state.focusedVisit);
    console.log(
      "details",
      selectedEvent.service[0].price.taxYn,
      selectedEvent.service[0].price.gross,
      selectedEvent.service[0].price.net,
      selectedEvent.service[0].invoice.totalTime,
      selectedEvent.service[0].invoice.paymentMethod
    );
    this.setState({
      recordPaymentDialogueOpen: true,
      focusedVisit: selectedEvent,
      chosenService: selectedEvent.service[0].type,
      includeTaxYN: selectedEvent.service[0].price.taxYn,
      subtotal: selectedEvent.service[0].price.gross,
      totalPrice: selectedEvent.service[0].price.net,
      totalTime: selectedEvent.service[0].invoice.totalTime,
      paymentType: selectedEvent.service[0].invoice.paymentMethod,
      insuranceInfo: selectedEvent.service[0].invoice.insuranceInfo,
    });
  };

  handleRecordPaymentClose = () => {
    this.setState({
      recordPaymentDialogueOpen: false,
      focusedVisit: {
        _id: "",
        notes: "First Visit",
        patient: {
          name: "loading..",
          cellPhone: "loading..",
          email: null,
          firstVisit: false,
          id: "loading",
        },
        date: { start: "", end: "" },
        service: [
          {
            type: "loading...",
            price: { gross: 0, tax: 0, net: 0 },
            paid: false,
            invoice: { yn: false, link: null },
            practitioner: { id: "", name: "Loading..." },
          },
        ],
        signIn: { yn: false, time: null, signature: null },
        signOut: { yn: false, time: null, signature: null },
        blocked: false,
        __v: 0,
      },
      includeTaxYN: null,
      subtotal: null,
      totalPrice: null,
      insuranceInfo: "",
      paymentType: null,
      totalTime: null,
    });
  };

  recordPaymentEvent = () => {
    console.log("hit payment event", this.state.focusedVisit);
    var promise = new Promise((resolve) => {
      var payload = {
        _id: this.state.focusedVisit._id,
        service: this.state.chosenService,
        email: this.state.focusedVisit.patient.email,
        price: {
          taxYn: this.state.includeTaxYN,
          gross: this.state.subtotal,
          tax: (
            Math.round((this.state.totalPrice - this.state.subtotal) * 100) /
            100
          ).toFixed(2),
          net: this.state.totalPrice,
        },
        invoice: {
          yn: this.state.invoiceYN,
          link: null,
          insuranceInfo: this.state.insuranceInfo,
          paymentMethod: this.state.paymentType,
          totalTime: this.state.totalTime,
        },
      };
      this.paymentEventReq(resolve, payload);
    });
    return promise;
  };

  paymentEventReq(resolve, payload) {
    console.log("payload", payload);
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/paymentedit", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        // this.props.fetchVisits()
        // this.props.fetchVisits(
        //   moment().subtract(7, "days").format("YYYY-MM-DD"),
        //   moment().add(7, "days").format("YYYY-MM-DD")
        // );

        // console.log("post request")
        if (response.data.successful === false) {
          console.log("payment failed");
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve(response);
          console.log(response);
          console.log("payment successful");
          this.setState({
            recordPaymentDialogueOpen: false,
            focusedVisit: {
              _id: "",
              notes: "First Visit",
              patient: {
                name: "loading..",
                cellPhone: "loading..",
                email: null,
                firstVisit: false,
                id: "loading",
              },
              date: { start: "", end: "" },
              service: [
                {
                  type: "loading...",
                  price: { gross: 0, tax: 0, net: 0 },
                  paid: false,
                  invoice: { yn: false, link: null },
                  practitioner: { id: "", name: "Loading..." },
                },
              ],
              signIn: { yn: false, time: null, signature: null },
              signOut: { yn: false, time: null, signature: null },
              blocked: false,
              __v: 0,
            },
            includeTaxYN: null,
            subtotal: null,
            totalPrice: null,
            insuranceInfo: "",
            paymentType: null,
            totalTime: null,
          });
        }
        // get all visits for the patient with updated info
        axios
          .get(
            process.env.REACT_APP_DOMAIN +
              `/visits/patient/${this.props.match.params.id}`
          )
          .then(({ data }) => {
            // console.log("visits", data)
            this.setState({
              visits: data,
            });
          });
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
      });
  }

  // Create styles
  docStyles = StyleSheet.create({
    page: {
      // flexDirection: 'row',
      backgroundColor: "#ffffff",
      padding: 30,
      fontSize: 10,
    },
    section: {
      flexGrow: 1,
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginTop: 30,
      borderTop: "1px solid #E4E4E4",
      padding: 20,
    },
    table: {
      display: "table",
      width: "auto",
      // borderStyle: "solid",
      // borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      textAlign: "left",
    },
    tableRow: {
      // margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "25%",
      // borderStyle: "solid",
      // borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      // margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
  });

  MyDocument = ({ visit }) => (
    <Document>
      <Page size="A4" style={this.docStyles.page}>
        <View style={{ textAlign: "right" }}>
          <Text style={{ fontSize: 30 }}>Invoice</Text>
          <Text style={{ marginTop: 20 }}>Appleby Wellness Centre</Text>
          <Text style={{ paddingTop: 5 }}>
            4265 Thomas Alton Blvd,. Unit 208,. Burlington,. Ont L7M 0M9
          </Text>
          <Text style={{ paddingTop: 5 }}>+1 (289) 812-5518</Text>
        </View>
        <div style={this.docStyles.flex}>
          <View style={this.docStyles.section}>
            <Text style={{ opacity: 0.5, fontSize: 12 }}>BILL TO</Text>
            <Text style={{ paddingTop: 5 }}>{visit.patient.name}</Text>
            <Text style={{ paddingTop: 5 }}>{visit.patient.cellPhone}</Text>
          </View>
        </div>
        <div style={[this.docStyles.flex, { padding: 0, paddingTop: 20 }]}>
          <View style={this.docStyles.section}>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                backgroundColor: "#424242",
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 20,
              }}
            >
              Date
            </Text>
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              {visit.date.start.slice(0, 10)}
            </Text>
          </View>
          <View style={this.docStyles.section}>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                backgroundColor: "#424242",
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 20,
              }}
            >
              Items
            </Text>
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              {visit.service[0].type}
            </Text>
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              ({visit.service[0].invoice.totalTime} Mins)
            </Text>
          </View>
          <View style={this.docStyles.section}>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                backgroundColor: "#424242",
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 20,
              }}
            >
              Unit Price
            </Text>
            <Text style={{ paddingTop: 5, paddingLeft: 20 }}>
              ${visit.service[0].price.gross}
            </Text>
          </View>
        </div>
        <div
          style={{
            marginTop: 50,
            borderTop: "1px solid #E4E4E4",
            padding: 20,
            textAlign: "right",
          }}
        >
          <View style={this.docStyles.table}>
            <View style={this.docStyles.tableRow}>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>Payment Type: </Text>
              </View>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>
                  {visit.service[0].invoice.paymentMethod}
                </Text>
              </View>
            </View>
            <View style={this.docStyles.tableRow}>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>Subtotal: </Text>
              </View>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>
                  ${visit.service[0].price.gross}
                </Text>
              </View>
            </View>
            {visit.service[0].price.taxYn && (
              <View style={this.docStyles.tableRow}>
                <View style={this.docStyles.tableCol}>
                  <Text style={this.docStyles.tableCell}>Tax (13%): </Text>
                </View>
                <View style={this.docStyles.tableCol}>
                  <Text style={this.docStyles.tableCell}>
                    ${visit.service[0].price.tax}
                  </Text>
                </View>
              </View>
            )}
            <View style={this.docStyles.tableRow}>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>Total (CAD): </Text>
              </View>
              <View style={this.docStyles.tableCol}>
                <Text style={this.docStyles.tableCell}>
                  ${visit.service[0].price.net}
                </Text>
              </View>
            </View>
          </View>
        </div>

        <View
          style={{
            marginTop: 20,
            borderTop: "1px solid #E4E4E4",
            padding: 20,
            textAlign: "left",
          }}
        >
          <>
            {visit.service[0].invoice.insuranceInfo && (
              <Text style={{ paddingTop: 5 }}>
                CC #: {visit.service[0].invoice.insuranceInfo}
              </Text>
            )}
            {visit.service[0].practitioner.name === "Lin Lin Wang" ? (
              <>
                <Text style={{ paddingTop: 5 }}>Lin Lin Wang (Reg#6807)</Text>
                <Text style={{ paddingTop: 5 }}>
                  Registered Acupuncturist & Traditional Chinese Medicine
                  Practitioner
                </Text>
                <Text style={{ paddingTop: 5 }}></Text>
                <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
                <Text style={{ paddingTop: 5 }}></Text>
              </>
            ) : visit.service[0].practitioner.name === "Yanzhi Xu" ? (
              <>
                <Text style={{ paddingTop: 5 }}>Yanzhi Xu (Reg#7228)</Text>
                <Text style={{ paddingTop: 5 }}>
                  Registered Acupuncturist & Traditional Chinese Medicine
                  Practitioner
                </Text>
                <Text style={{ paddingTop: 5 }}></Text>
                <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
                <Text style={{ paddingTop: 5 }}>HST#: 860950526 RT0001</Text>
                <Text style={{ paddingTop: 5 }}></Text>
              </>
            ) : visit.service[0].practitioner.name === "Naitong Guo" ? (
              <>
                <Text style={{ paddingTop: 5 }}>
                  Naitong Guo (Reg No. B-3669)
                </Text>
                <Text style={{ paddingTop: 5 }}>
                  Registered Massage Therapist
                </Text>
                <Text style={{ paddingTop: 5 }}></Text>
                <Text style={{ paddingTop: 5 }}>Appleby Wellness Centre</Text>
                <Text style={{ paddingTop: 5 }}>HST#: 860950526 RT0001</Text>
                <Text style={{ paddingTop: 5 }}></Text>
              </>
            ) : (
              <>
                {(visit.service[0].type === "Osteopathy" ||
                  visit.service[0].type === "Massage") && (
                  <>
                    <Text style={{ paddingTop: 5 }}>Li Gang Wang</Text>
                    {visit.service[0].type === "Osteopathy" ? (
                      <>
                        <Text style={{ paddingTop: 5 }}>
                          Osteopathic Manual Practitioner, RMT,.
                        </Text>
                        <Text style={{ paddingTop: 5 }}>
                          Reg No. DOMP-0316441 (O.O.A.M.A)
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text style={{ paddingTop: 5 }}>
                          Registered Massage Therapist
                        </Text>
                        <Text style={{ paddingTop: 5 }}>Reg No. P591</Text>
                      </>
                    )}
                    <Image style={{ width: 200 }} src={SignatureImage} />
                  </>
                )}
                {visit.service[0].type === "Acupuncture" && (
                  <>
                    <Text style={{ paddingTop: 5 }}>
                      Annie YW. Zhu (Reg#784)
                    </Text>
                    <Text style={{ paddingTop: 5 }}>
                      Registered Acupuncturist & Traditional Chinese Medicine
                      Practitioner
                    </Text>
                    <Text style={{ paddingTop: 5 }}></Text>
                  </>
                )}
                {(visit.service[0].type === "Herbs" ||
                  visit.service[0].type === "Products" ||
                  visit.service[0].type === "Other") && (
                  <>
                    <Text style={{ paddingTop: 5 }}>
                      Appleby Wellness Centre
                    </Text>
                    <Text style={{ paddingTop: 5 }}></Text>
                  </>
                )}
                <Text style={{ paddingTop: 5 }}>HST#: 860950526 RT0001</Text>
              </>
            )}
          </>
        </View>
      </Page>
    </Document>
  );

  handleMoreInfoClick = (arg) => {
    // this.props.history.push("/admin/intake/" + this.state.focusedVisit.patient.id + "/admin" );
    // this.setState({
    //     moreInfoDialogueOpen: true
    // })
    // console.log(visit.service[0].invoice.insuranceInfo);
  };

  setResendSnackbarState = (arg) => {
    this.setState({
      resendSnackbar: arg,
    });
  };
  setResendSnackbarStateError = (arg) => {
    this.setState({
      resendSnackbarError: arg,
    });
  };

  blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  waitForBlobResend = (visit) => {
    // if (this.blobData !== null) {
    // console.log("wait for blob resend", visit);
    // const recPDF = pdf();
    // recPDF.updateContainer(<this.MyDocument visit={visit} />);
    // const testPDF = pdf(<this.MyDocument visit={visit} />);
    // const testPDF = pdf(this.MyDocument({ visit }));
    // console.log("PDF was created", testPDF);
    // testPDF.toBlob({}).then((res) => {
    //   console.log("blob created", res);
    // });
    // pdf({this.MyDocument(visit)})
    // pdf(<this.MyDocument visit={visit} />)
    pdf(
      Invoice({
        visit,
        signature: this.returnAdminInfo(
          visit.service[0].practitioner.id,
          visit.service[0].practitioner.name
        ),
      })
    )
      .toBlob()
      .then((res) => {
        console.log("blob", res);
        this.blobToBase64(res).then((converted) => {
          console.log("blob converted", converted);
          this.handleResendEmailClick(visit, converted);
        });
      });
    // this.blobToBase64(pdf(this.MyDocument({ visit }).toBlob())).then((res) => {
    //   // do what you wanna do
    //   console.log("new blob", res); // res is base64 now
    //   this.recordPaymentEvent(res);
    //   return "Success";
    // });
    // } else {
    //   // console.log("no new blob")
    //   setTimeout(this.waitForBlob, 250);
    // }
  };

  handleResendEmailClick = (visit, blob) => {
    console.log("RESEND INVOICE", visit._id);
    // resend the most recent invoice to the user.
    var promise = new Promise((resolve) => {
      var payload = {
        _id: visit._id,
        email: this.state.patient.intake.general.email,
        pdf: blob,
      };
      console.log("resend payload", payload);
      this.resentInvoiceReq(resolve, payload);
    });
    return promise;
  };

  resentInvoiceReq(resolve, payload) {
    console.log("payload", payload);
    axios
      .post(process.env.REACT_APP_DOMAIN + "/visits/resend", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);

        if (response.data.successful === false) {
          var errorMsg = response.data.reason;
          // activate snackbar with error message.
          this.setResendSnackbarStateError(true);
          resolve(errorMsg);
        } else {
          // activate snackbar with success message.
          this.setResendSnackbarState(true);

          resolve(response);
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        this.setResendSnackbarStateError(true);
        resolve(errorMsg);
      });
  }

  handleTreatmentClick = (arg) => {
    //navigate to page with treatment notes
    this.props.history.push(
      "/admin/treatmentnotes/" + this.props.match.params.id + "/" + arg
    );
  };

  handleVisitLogPress = (arg) => {
    //navigate to page with visit log data
    this.props.history.push("/visitlog/" + this.props.match.params.id);
  };

  handleIntakeClick = (arg) => {
    this.props.history.push(
      "/admin/intake/" + this.props.match.params.id + "/admin"
    );
    // this.setState({
    //     moreInfoDialogueOpen: true
    // })
  };

  handleIntakeChange = (event) => {
    this.setState({ selectedIntake: event.target.value });
    if (event.target.value === "current") {
      this.props.history.push(
        "/admin/intake/" + this.props.match.params.id + "/admin/"
      );
    } else if (!isNaN(event.target.value)) {
      this.props.history.push(
        "/admin/intake/" +
          this.props.match.params.id +
          "/admin/" +
          event.target.value
      );
    }
  };

  handleMoreInfoClickClose = () => {
    this.setState({
      moreInfoDialogueOpen: false,
    });
  };

  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  render() {
    const { classes } = this.props;

    // console.log(this.state.visits)
    return (
      <>
        {!this.state.patient ? (
          <div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: 500,
                height: 200,
                marginTop: -100,
                marginLeft: -250,
                textAlign: "center",
              }}
            >
              <Typography component="h1" style={{ fontSize: 25 }}>
                Welcome to Appleby Wellness
              </Typography>
              <Typography component="p" style={{ fontWeight: "lighter" }}>
                Loading in your information.
              </Typography>
              <CircularProgress style={{ margin: "3rem" }} />
            </div>
          </div>
        ) : (
          <>
            <div>
              <ThemeProvider theme={theme}>
                <Grid
                  container
                  direction="row-reverse"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Autocomplete
                      id="searchAuto"
                      size="small"
                      options={this.props.patients}
                      getOptionLabel={(option) =>
                        option.intake.general.firstName +
                        " " +
                        option.intake.general.lastName
                      }
                      renderOption={(option) => (
                        <React.Fragment>
                          <div>
                            <Typography variant="body1">
                              {option.intake.general.firstName +
                                " " +
                                option.intake.general.lastName}
                            </Typography>
                            <Typography variant="caption">
                              {option.intake.general.cellPhone}
                            </Typography>
                          </div>
                        </React.Fragment>
                      )}
                      style={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) =>
                        this.handleSearchChange(newValue)
                      }
                      value={this.state.search}
                    />
                  </Grid>
                </Grid>
                <hr
                  style={{
                    borderTopColor: "#cccccc",
                    borderTop: 1,
                    marginTop: 40,
                    marginBottom: 40,
                  }}
                />

                <Grid
                  container
                  justify="center"
                  spacing={5}
                  style={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <Card
                      className={classes.root}
                      variant="outlined"
                      style={{ borderRadius: 20 }}
                    >
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          style={{ textAlign: "left", padding: 15 }}
                          variant="h4"
                          component="h2"
                        >
                          {this.state.patient.intake.general.firstName +
                            " " +
                            this.state.patient.intake.general.lastName}
                          {this.state.patient.intake.history ? (
                            <Select
                              value={this.state.selectedIntake}
                              onChange={this.handleIntakeChange}
                              variant="outlined"
                              style={{ marginTop: -7, float: "right" }}
                              defaultValue={"choose"}
                            >
                              <MenuItem value={"choose"}>
                                Choose an Intake
                              </MenuItem>
                              <MenuItem value={"current"}>
                                Current Intake
                              </MenuItem>
                              {this.state.patient.intake.history &&
                                this.state.patient.intake.history.map(
                                  (intake, index) => (
                                    <MenuItem value={index}>{`Intake ${
                                      index + 1
                                    }`}</MenuItem>
                                  )
                                )}
                            </Select>
                          ) : (
                            <Button
                              style={{ marginLeft: 20, float: "right" }}
                              onClick={this.handleIntakeClick}
                            >
                              Intake Form
                            </Button>
                          )}
                        </Typography>
                        <div
                          style={{
                            width: "100%",
                            flexGrow: 1,
                            textAlign: "left",
                            padding: 15,
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs>
                              <div>
                                <Typography variant="overline">
                                  CONTACT INFORMATION{" "}
                                </Typography>
                                <br />
                                <br />
                                {this.state.patient.intake.general.email ? (
                                  <Typography variant="body1">
                                    {this.state.patient.intake.general.email}
                                  </Typography>
                                ) : (
                                  <Typography variant="body1">
                                    No Email
                                  </Typography>
                                )}
                                <Typography variant="body1">
                                  <b>cell</b>:{" "}
                                  {this.state.patient.intake.general.cellPhone}
                                </Typography>
                                <Typography variant="body1">
                                  <b>home</b>:
                                  {this.state.patient.intake.general
                                    .homePhone ? (
                                    <Typography variant="body1">
                                      {
                                        this.state.patient.intake.general
                                          .homePhone
                                      }
                                    </Typography>
                                  ) : (
                                    <> N/A</>
                                  )}
                                  {this.state.patient.intake.general.homePhone}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs>
                              <Typography variant="overline">
                                ADDRESS
                              </Typography>
                              <br />
                              <br />
                              <Typography variant="body1">
                                {this.state.patient.intake.general
                                  .addressStreet ? (
                                  <>
                                    {
                                      this.state.patient.intake.general
                                        .addressStreet
                                    }{" "}
                                    <br />
                                    {
                                      this.state.patient.intake.general
                                        .addressCity
                                    }
                                    <br />
                                    {
                                      this.state.patient.intake.general
                                        .addressPostal
                                    }
                                    <br />
                                  </>
                                ) : (
                                  <p>N/A</p>
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography variant="overline">
                                GENDER{" "}
                              </Typography>
                              <br />
                              <br />
                              {this.state.patient.intake.general.gender ? (
                                <Typography variant="body1">
                                  {this.state.patient.intake.general.gender}
                                </Typography>
                              ) : (
                                <Typography variant="body1">N/A</Typography>
                              )}
                            </Grid>
                            <Grid item xs>
                              <Typography variant="overline">
                                DATE OF BIRTH{" "}
                              </Typography>
                              <br />
                              <br />
                              {this.state.patient.intake.general.dob ? (
                                <Typography variant="body1">
                                  <Moment format="MMMM Do YYYY">
                                    {this.state.patient.intake.general.dob}
                                  </Moment>
                                </Typography>
                              ) : (
                                <Typography variant="body1">N/A</Typography>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <div
                    style={{
                      flexGrow: 1,
                      width: "100%",
                      padding: 17,
                      marginTop: 40,
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        {/* <Button style={{ margin: 3 }} color="primary">Upcoming Visits</Button> */}
                        <Button style={{ margin: 3 }}>Visits</Button>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Button
                          onClick={this.handleVisitLogPress}
                          style={{ margin: 3, marginRight: 10 }}
                          variant="contained"
                          color="primary"
                          disableElevation
                        >
                          Visit Log
                        </Button>
                        {/* <Button style={{ margin: 3, marginLeft: 10 }} variant="contained" color="primary" disableElevation>
                                                    New Event
                                    </Button> */}
                      </Grid>
                    </Grid>
                  </div>
                  {/* Number: {this.state.id} */}
                </Grid>

                <Grid
                  container
                  justify="center"
                  spacing={5}
                  style={{ width: "100%" }}
                >
                  {this.state.visits ? (
                    <>
                      {[...this.state.visits]
                        .filter((visit) => !visit.noshow && !visit.rescheduled)
                        .reverse()
                        .map((visit) => (
                          <Grid
                            key={visit.id}
                            item
                            xs={12}
                            style={{ width: "100%" }}
                          >
                            <Grid item xs={12} style={{ width: "100%" }}>
                              <Card
                                className={classes.root}
                                variant="outlined"
                                style={{ borderRadius: 20 }}
                              >
                                <CardContent style={{ textAlign: "left" }}>
                                  <Typography
                                    style={{ textAlign: "left", padding: 15 }}
                                    variant="h4"
                                    component="h2"
                                  >
                                    {String(
                                      months[
                                        new Date(visit.date.start).getMonth()
                                      ]
                                    )}{" "}
                                    {String(
                                      new Date(visit.date.start).getDate()
                                    )}
                                    ,{" "}
                                    {String(
                                      new Date(visit.date.start).getFullYear()
                                    )}
                                    {visit.service[0].paid && (
                                      <div>
                                        <Button
                                          // variant="contained"
                                          // color="primary"
                                          // endIcon={<MailIcon />}
                                          disableElevation
                                          onClick={() => {
                                            this.handleRecordPaymentClick(
                                              visit._id
                                            );
                                          }}
                                          style={{
                                            float: "right",
                                            marginLeft: 20,
                                          }}
                                        >
                                          Edit Receipt
                                        </Button>
                                        <Button
                                          // variant="contained"
                                          // color="primary"
                                          // endIcon={<MailIcon />}
                                          disableElevation
                                          onClick={() =>
                                            this.waitForBlobResend(visit)
                                          }
                                          style={{
                                            float: "right",
                                            marginLeft: 20,
                                          }}
                                        >
                                          Resend Invoice
                                        </Button>
                                        <Button
                                          style={{
                                            marginLeft: 20,
                                            float: "right",
                                          }}
                                          onClick={this.handleMoreInfoClick}
                                        >
                                          <PDFDownloadLink
                                            style={{
                                              textDecoration: "none",
                                              color: "#000",
                                            }}
                                            document={
                                              <Invoice
                                                visit={visit}
                                                signature={this.returnAdminInfo(
                                                  visit.service[0].practitioner
                                                    .id,
                                                  visit.service[0].practitioner
                                                    .name
                                                )}
                                              />
                                            }
                                            fileName={
                                              "receipt-" +
                                              visit.patient.name +
                                              ".pdf"
                                            }
                                          >
                                            {({
                                              blob,
                                              url,
                                              loading,
                                              error,
                                            }) => {
                                              return error
                                                ? String(error)
                                                : "Receipt";
                                            }}
                                          </PDFDownloadLink>
                                        </Button>
                                      </div>
                                    )}
                                    <Button
                                      style={{
                                        marginLeft: 20,
                                        float: "right",
                                      }}
                                      onClick={() =>
                                        this.handleTreatmentClick(visit._id)
                                      }
                                    >
                                      Treatment Notes
                                    </Button>
                                  </Typography>
                                  <div
                                    style={{
                                      width: "100%",
                                      flexGrow: 1,
                                      textAlign: "left",
                                      padding: 15,
                                    }}
                                  >
                                    <Grid container spacing={3}>
                                      <Grid item xs>
                                        <div>
                                          <Typography variant="overline">
                                            Service{" "}
                                          </Typography>
                                          <br />
                                          <br />
                                          <Typography variant="body1">
                                            {visit.service[0].type}
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant="overline">
                                          Practitioner
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography variant="body1">
                                          {visit.service[0].practitioner.name}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant="overline">
                                          Price{" "}
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography variant="body1">
                                          ${visit.service[0].price.net}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant="overline">
                                          Invoice?
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography variant="body1">
                                          {visit.service[0].invoice.yn ? (
                                            <>Yes</>
                                          ) : (
                                            <>No</>
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant="overline">
                                          Paid{" "}
                                        </Typography>
                                        <br />
                                        <br />
                                        {visit.service[0].paid ? (
                                          <CheckIcon
                                            style={{ color: "#00AD26" }}
                                          />
                                        ) : (
                                          <ClearIcon
                                            style={{ color: "#D20000" }}
                                          />
                                        )}
                                      </Grid>
                                    </Grid>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        ))}

                      {[...this.state.visits].filter(
                        (visit) => visit.noshow || visit.rescheduled
                      ).length > 0 && (
                        <Typography
                          variant="button"
                          style={{
                            textAlign: "left",
                            paddingLeft: "32px",
                            paddingTop: "20px",
                            paddingBottom: "0",
                            width: "100%",
                          }}
                        >
                          No Show / Rescheduled
                        </Typography>
                      )}

                      {[...this.state.visits]
                        .filter((visit) => visit.noshow || visit.rescheduled)
                        .reverse()
                        .map((visit) => (
                          <Grid
                            key={visit.id}
                            item
                            xs={12}
                            style={{ width: "100%" }}
                          >
                            <Grid
                              item
                              xs={12}
                              style={{ width: "100%", opacity: 0.5 }}
                            >
                              <Card
                                className={classes.root}
                                variant="outlined"
                                style={{ borderRadius: 20 }}
                              >
                                <CardContent
                                  style={{
                                    textAlign: "left",
                                    paddingBottom: 16,
                                  }}
                                >
                                  <Typography
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: 15,
                                    }}
                                    variant="h6"
                                    component="h6"
                                  >
                                    {String(
                                      months[
                                        new Date(visit.date.start).getMonth()
                                      ]
                                    )}{" "}
                                    {String(
                                      new Date(visit.date.start).getDate()
                                    )}
                                    ,{" "}
                                    {String(
                                      new Date(visit.date.start).getFullYear()
                                    )}
                                    {" - "}
                                    {visit.service[0].type}
                                    <Button
                                      disabled
                                      style={{
                                        marginLeft: 20,
                                        float: "right",
                                        color: "#D20000",
                                      }}
                                    >
                                      {visit.noshow ? (
                                        <>No Show</>
                                      ) : (
                                        <>Rescheduled</>
                                      )}
                                    </Button>
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        ))}
                    </>
                  ) : (
                    <p>No Visits available for this patient.</p>
                  )}
                </Grid>
                <Grid
                  container
                  justify="center"
                  spacing={5}
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      width: "100%",
                      padding: 17,
                      marginTop: 40,
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        {/* <Button style={{ margin: 3 }} color="primary">Upcoming Visits</Button> */}
                        <Typography variant="overline" style={{ margin: 3 }}>
                          Danger Zone!
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
                    </Grid>
                  </div>
                  <Grid item xs={12}>
                    <Card
                      className={classes.root}
                      variant="outlined"
                      style={{
                        borderRadius: 20,
                        marginTop: -20,
                        marginBottom: 40,
                        backgroundColor: "#FDEDEE",
                        // borderBlockColor: "#E6475A",
                      }}
                    >
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          style={{ textAlign: "left", padding: 15 }}
                          variant="h6"
                          component="h6"
                        >
                          Delete this Patient.
                          <Button
                            style={{
                              marginLeft: 20,
                              float: "right",
                              color: "#E6475A",
                            }}
                            onClick={this.deletePatientClick}
                          >
                            Delete
                          </Button>
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ paddingLeft: 15, paddingRight: 15 }}
                        >
                          Completing this action will remove the patient from
                          the platform. You will no longer be able to search for
                          or view them without access to their direct link.
                          Their visits will continue to exist on the calendar.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </div>
            <div>
              <Dialog
                open={this.state.moreInfoDialogueOpen}
                onClose={this.handleMoreInfoClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                {/* <DialogTitle id="alert-dialog-title">{this.state.patient.name}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <b>Email</b>: {this.state.patient.email} <br /><br />
                                <b>Cell Phone</b>: {this.state.patient.cellPhone} <br /><br />
                                <b>Home Phone</b>: {this.state.patient.homePhone} <br /><br />
                                <b>Address</b>: {this.state.patient.address[0] + ", " + this.state.patient.address[1] + ", " + this.state.patient.address[2]} <br /><br />
                                <b>Gender</b>: {this.state.patient.gender} <br /><br />
                                <b>Date of Birth</b>: {this.state.patient.dob} <br /><br />
                                <b>Last Visit</b>: {this.state.patient.lastVisit} <br /><br />

                            </DialogContentText>
                        </DialogContent> */}
                <DialogActions>
                  <Button
                    onClick={this.handleMoreInfoClickClose}
                    color="primary"
                    autoFocus
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.resendSnackbar}
              autoHideDuration={6000}
              onClose={() => {
                this.setResendSnackbarState(false);
              }}
            >
              <this.Alert severity="success">Receipt Resent!</this.Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.resendSnackbarError}
              autoHideDuration={6000}
              onClose={() => {
                this.setResendSnackbarStateError(false);
              }}
            >
              <this.Alert severity="error">
                Could not Resend Receipt.
              </this.Alert>
            </Snackbar>
            <div>
              <Dialog
                open={this.state.recordPaymentDialogueOpen}
                onClose={this.handleRecordPaymentClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {this.state.focusedVisit.patient.name}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <Autocomplete
                        id="combo-box-demo"
                        options={this.state.services}
                        getOptionLabel={(option) => option}
                        style={{ width: 195 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Service"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, newValue) =>
                          this.handleNewEventServicesChange(newValue)
                        }
                        value={this.state.chosenService}
                      />
                      {/* <Typography variant="overline">Service</Typography>
                                        <Typography variant="body1">{this.state.focusedVisit.service[0].type}</Typography> */}
                    </Grid>
                    <Grid item xs>
                      <Typography variant="overline">Practitioner</Typography>
                      <Typography variant="body1">
                        {this.state.focusedVisit.service[0].practitioner.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <Typography variant="overline">Subtotal ($)</Typography>
                      <TextField
                        onChange={this.handleSubtotalChange}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        value={this.state.subtotal}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="overline">Include Tax?</Typography>
                      <br />
                      <Switch
                        checked={this.state.includeTaxYN}
                        onChange={this.handleTaxChange}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <Typography variant="overline">
                        Total Time (mins)
                      </Typography>
                      <TextField
                        onChange={this.handleTotalTimeChange}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        value={this.state.totalTime}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="overline">Payment Method</Typography>
                      <br />
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.paymentType}
                          // onChange={}
                          label="Age"
                          onChange={this.handlePaymentTypeChange}
                        >
                          {/* <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem> */}
                          <MenuItem value="Debit">Debit</MenuItem>
                          <MenuItem value="Credit">Credit</MenuItem>
                          <MenuItem value="Cash">Cash</MenuItem>
                          <MenuItem value="Insurance">Insurance</MenuItem>
                          <MenuItem value="Insurance and Credit">
                            Insurance + Credit
                          </MenuItem>
                          <MenuItem value="Insurance and Debit">
                            Insurance + Debit
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <Typography variant="overline">Total</Typography>
                      <Typography variant="body1">
                        {this.state.totalPrice}
                      </Typography>
                    </Grid>
                  </Grid>
                  {(this.state.paymentType == "Insurance" ||
                    this.state.paymentType == "Insurance and Credit" ||
                    this.state.paymentType == "Insurance and Debit") && (
                    <>
                      <Typography variant="overline">Insurance Info</Typography>{" "}
                      <br />
                      <TextField
                        onChange={this.handleInsuranceInfoChange}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        style={{ width: "100%" }}
                        value={this.state.insuranceInfo}
                      />
                    </>
                  )}
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  {/* <IconButton color="primary" aria-label="add receipt">
                                    <AddIcon />
                                </IconButton> */}
                  {/* <Button onClick={this.handleRecordPaymentClose} color="primary">
                                    Send Receipt
                                </Button> */}
                  <Button
                    variant="outlined"
                    onClick={this.recordPaymentEvent}
                    color="primary"
                  >
                    Submit
                  </Button>
                  <Button onClick={this.handleRecordPaymentClose}>Close</Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        )}
      </>
    );
  }
}

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ submitLogin: submitLogin, isLoggedIn: isLoggedIn },
//         dispatch);
// }

function mapStateToProps(state) {
  return {
    visits: state.visits,
    patients: state.patients,
    admins: state.admins,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(withRouter(Patient));
