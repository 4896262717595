import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { Route } from "react-router-dom";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/AssignmentInd";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ReactExport from "react-export-excel-xlsx-fix";
import "./main.scss";
import { Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import randomColor from "randomcolor";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

import { CSVLink, CSVDownload } from "react-csv";

import Moment from "react-moment";

import { fetchVisits, fetchPatients, fetchAdmins } from "./actions/index";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#002BB8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#7200B8",
    },
    info: {
      main: "#3177EA",
    },
  },
});

// const actions = [
//     {
//         icon: <ExcelFile filename="Patient Visits" element={<SaveIcon style={{ marginTop: 4 }} />}>
//             <ExcelSheet data={this.props.visits} name="Summary">
//                 <ExcelColumn label="Visit ID" value="id" />
//                 <ExcelColumn label="Patient ID" value={'patient.id'} />
//                 <ExcelColumn label="Patient Name" value={'patient.name'} />
//                 <ExcelColumn label="Date" value={'date.start'} />
//                 <ExcelColumn label="Practitioner" value={'practitioner.name'} />
//                 <ExcelColumn label="Service" value={'service[0].type'} />
//                 <ExcelColumn label="Gross Price" value={'service[0].price.gross'} />
//                 <ExcelColumn label="Tax Price" value={'service[0].price.tax'} />
//                 <ExcelColumn label="Net Price" value={'service[0].price.net'} />
//                 <ExcelColumn label="Paid"
//                     value={(col) => col.service[0].paid ? "True" : "False"} />
//                 <ExcelColumn label="Invoice"
//                     value={(col) => col.service[0].invoice.yn ? "True" : "False"} />
//             </ExcelSheet>

//         </ExcelFile>, name: 'Download XSLX'
//     },
//     { icon: <AddIcon />, name: 'New Patient' },
// ];

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  rootDial: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
});

class Patients extends Component {
  csvLink = React.createRef();
  ExcelLink = React.createRef();
  ExcelLinkPayroll = React.createRef();

  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      downloadReady: false,
      downloadPayrollReady: false,
      open: false,
      search: null,
      patients: this.props.patients,
      visits: this.props.visits,
      sheet: [],
      acupunctureSheet: [],
      osteopathySheet: [],
      massageSheet: [],
      herbsSheet: [],
      productsSheet: [],
      otherSheet: [],
      actions: [],
      columns: [
        "Visit ID",
        "Patient ID",
        "Patient Name",
        "Date",
        "Practitioner",
        "Service",
        "Price Gross",
        "Price Tax",
        "Price Net",
        "Paid?",
        "Invoice?",
      ],
      columnsPat: ["First Name", "Last Name", "Phone", "Email"],
      // Yes - this is not a great solution, but the excel converter has trouble dealing
      //  with deeply nested state, so a single payroll state to dynamically map admins won't work.
      payroll_one: [["Payroll"]],
      payroll_two: [["Payroll"]],
      payroll_three: [["Payroll"]],
      payroll_four: [["Payroll"]],
      payroll_five: [["Payroll"]],
      payroll_six: [["Payroll"]],
      payroll_seven: [["Payroll"]],
      payroll_eight: [["Payroll"]],
      payroll_nine: [["Payroll"]],
      payroll_ten: [["Payroll"]],

      // payroll: [{name: "Alex Gordienko", visits: [["Payroll"]]}, {name: "Jane Dawson", visits: [["Payroll"]]}, {name: "John B", visits: [["Payroll"]]}],
      patientsCSV: [],
      counter: 0,
      colors: randomColor({
        count: 100,
        hue: "purple",
        luminosity: "light",
      }),
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleSearchChange = (arg) => {
    // // console.log(arg)
    this.setState({ search: arg });
    if (arg) {
      this.props.history.push("/patient/" + arg._id);
    }
  };

  checkIfComplete = () => {};

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  getVisitData = () => {
    // reset excel download sheets
    this.setState({
      sheet: [],
      acupunctureSheet: [],
      osteopathySheet: [],
      massageSheet: [],
      herbsSheet: [],
      productsSheet: [],
      otherSheet: [],
    });
    // console.log("visits fired", this.props.visits.length);

    var visitsClean = new Promise((resolve, reject) => {
      this.props.visits.forEach((value, index, array) => {
        this.visitsToExcelConversion(value);
        if (index === this.props.visits.length - 1) resolve();
      });
    });

    visitsClean.then(() => {
      // console.log("All done!");
      this.setState({
        downloadReady: true,
      });
      // this.ExcelLink.click();
    });
  };

  getPractitionerWeekData = () => {
    let currentDate = moment();

    let weekStart = currentDate.clone().startOf("isoWeek");
    let weekEnd = currentDate.clone().endOf("isoWeek");

    // reset payroll array
    this.setState({
      payroll: [[["Payroll"]], [["Payroll"]], [["Payroll"]]],
      payroll_one: [["Payroll"]],
      payroll_two: [["Payroll"]],
      payroll_three: [["Payroll"]],
    });
    // console.log("visits fired", this.props.visits.length);
    var visitsPayrollClean = new Promise((resolve, reject) => {
      this.props.visits.forEach((value, index, array) => {
        // only process visits that happened between weekStart and weekEnd
        let visitDate = moment(value.date.start);
        // console.log(
        //   currentDate,
        //   weekStart.diff(visitDate),
        //   visitDate.diff(weekEnd)
        // );
        if (
          weekStart.diff(visitDate) < 0 &&
          weekEnd.diff(visitDate) > 0 &&
          !value.blocked &&
          // value.service[0].paid &&
          !value.noshow
        ) {
          console.log("this weeks event hit");
          console.log(value);
          this.visitsToPayrollExcelConversion(value);
        }
        if (index === this.props.visits.length - 1) resolve();
      });
    });

    visitsPayrollClean.then(() => {
      console.log("All done!");
      console.log(this.state.payroll_one);
      this.setState({
        downloadPayrollReady: true,
      });
      // this.ExcelLink.click();
    });
  };

  visitsToPayrollExcelConversion = (visit) => {
    // only process relevant visits
    if (
      !visit.blocked &&
      // visit.service[0].paid &&
      !visit.noshow
    ) {
      let formattedItem = [
        visit._id,
        visit.patient.id,
        visit.patient.name,
        String(visit.date.start),
        visit.service[0].practitioner.name,
        visit.service[0].type,
        visit.service[0].price.gross,
        visit.service[0].price.tax,
        visit.service[0].price.net,
        visit.service[0].paid,
        visit.service[0].invoice.yn,
      ];
      console.log("formatted item", formattedItem);
      // add array to the relevant practitioner name
      // allows for 10 admins
      if (visit.service[0].practitioner.name === this.props.admins[0].name) {
        this.setState((prevState) => ({
          payroll_one: [...prevState.payroll_one, formattedItem],
        }));
      } else if (this.props.admins[1]) {
        if (visit.service[0].practitioner.name === this.props.admins[1].name) {
          this.setState((prevState) => ({
            payroll_two: [...prevState.payroll_two, formattedItem],
          }));
        }
      } else if (this.props.admins[2]) {
        if (visit.service[0].practitioner.name === this.props.admins[2].name) {
          this.setState((prevState) => ({
            payroll_three: [...prevState.payroll_three, formattedItem],
          }));
        }
      } else if (this.props.admins[3]) {
        if (visit.service[0].practitioner.name === this.props.admins[3].name) {
          this.setState((prevState) => ({
            payroll_four: [...prevState.payroll_four, formattedItem],
          }));
        }
      } else if (this.props.admins[4]) {
        if (visit.service[0].practitioner.name === this.props.admins[4].name) {
          this.setState((prevState) => ({
            payroll_five: [...prevState.payroll_five, formattedItem],
          }));
        }
      } else if (this.props.admins[5]) {
        if (visit.service[0].practitioner.name === this.props.admins[5].name) {
          this.setState((prevState) => ({
            payroll_six: [...prevState.payroll_six, formattedItem],
          }));
        }
      } else if (this.props.admins[6]) {
        if (visit.service[0].practitioner.name === this.props.admins[6].name) {
          this.setState((prevState) => ({
            payroll_seven: [...prevState.payroll_seven, formattedItem],
          }));
        }
      } else if (this.props.admins[7]) {
        if (visit.service[0].practitioner.name === this.props.admins[7].name) {
          this.setState((prevState) => ({
            payroll_eight: [...prevState.payroll_eight, formattedItem],
          }));
        }
      } else if (this.props.admins[8]) {
        if (visit.service[0].practitioner.name === this.props.admins[8].name) {
          this.setState((prevState) => ({
            payroll_nine: [...prevState.payroll_nine, formattedItem],
          }));
        }
      } else if (this.props.admins[9]) {
        if (visit.service[0].practitioner.name === this.props.admins[9].name) {
          this.setState((prevState) => ({
            payroll_ten: [...prevState.payroll_ten, formattedItem],
          }));
        }
      }
    }
  };

  visitsToExcelConversion = (visit) => {
    // only process relevant visits
    if (!visit.blocked && visit.service[0].paid && !visit.noshow) {
      let formattedItem = [
        visit._id,
        visit.patient.id,
        visit.patient.name,
        String(visit.date.start),
        visit.service[0].practitioner.name,
        visit.service[0].type,
        visit.service[0].price.gross,
        visit.service[0].price.tax,
        visit.service[0].price.net,
        visit.service[0].paid,
        visit.service[0].invoice.yn,
      ];
      if (visit.service[0].type === "Acupuncture") {
        this.setState((prevState) => ({
          acupunctureSheet: [...prevState.acupunctureSheet, formattedItem],
        }));
      } else if (visit.service[0].type === "Osteopathy") {
        this.setState((prevState) => ({
          osteopathySheet: [...prevState.osteopathySheet, formattedItem],
        }));
      } else if (visit.service[0].type === "Massage") {
        this.setState((prevState) => ({
          massageSheet: [...prevState.massageSheet, formattedItem],
        }));
      } else if (visit.service[0].type === "Herbs") {
        this.setState((prevState) => ({
          herbsSheet: [...prevState.herbsSheet, formattedItem],
        }));
      } else if (visit.service[0].type === "Products") {
        this.setState((prevState) => ({
          productsSheet: [...prevState.productsSheet, formattedItem],
        }));
      } else {
        this.setState((prevState) => ({
          otherSheet: [...prevState.otherSheet, formattedItem],
        }));
      }
      this.setState((prevState) => ({
        sheet: [...prevState.sheet, formattedItem],
      }));
    }
  };

  getPatientData = () => {
    var patientsClean = new Promise((resolve, reject) => {
      this.props.patients.forEach((value, index, array) => {
        this.patientToCSVConversion(value);
        if (index === this.props.patients.length - 1) resolve();
      });
    });

    patientsClean.then(() => {
      // console.log("All done!");
      this.csvLink.current.link.click();
    });
  };

  patientToCSVConversion = (patient) => {
    const newelement = [
      patient.intake.general.firstName,
      patient.intake.general.lastName,
      patient.intake.general.cellPhone,
      patient.intake.general.email,
    ];
    this.setState((prevState) => ({
      patientsCSV: [...prevState.patientsCSV, newelement],
      counter: prevState.counter + 1,
    }));
  };

  visitsPrePro() {
    this.setState({
      sheet: [],
      acupunctureSheet: [],
      osteopathySheet: [],
      massageSheet: [],
      herbsSheet: [],
      productsSheet: [],
      otherSheet: [],
    });
    // // console.log("all visits", this.props.visits);
    const countedVisits = this.props.visits.filter(function (visit) {
      return !visit.blocked && visit.service[0].paid && !visit.noshow;
    });
    // prep all visits for download
    // // console.log("counted Visits", countedVisits);
    countedVisits.forEach((visit) => {
      visit.service.forEach((service) => {
        let formattedItem = [
          visit._id,
          visit.patient.id,
          visit.patient.name,
          String(visit.date.start),
          service.practitioner.name,
          service.type,
          service.price.gross,
          service.price.tax,
          service.price.net,
          service.paid,
          service.invoice.yn,
        ];
        if (service.type === "Acupuncture") {
          this.setState((prevState) => ({
            acupunctureSheet: [...prevState.acupunctureSheet, formattedItem],
          }));
        } else if (service.type === "Osteopathy") {
          this.setState((prevState) => ({
            osteopathySheet: [...prevState.osteopathySheet, formattedItem],
          }));
        } else if (service.type === "Massage") {
          this.setState((prevState) => ({
            massageSheet: [...prevState.massageSheet, formattedItem],
          }));
        } else if (service.type === "Herbs") {
          this.setState((prevState) => ({
            herbsSheet: [...prevState.herbsSheet, formattedItem],
          }));
        } else if (service.type === "Products") {
          this.setState((prevState) => ({
            productsSheet: [...prevState.productsSheet, formattedItem],
          }));
        } else {
          this.setState((prevState) => ({
            otherSheet: [...prevState.otherSheet, formattedItem],
          }));
        }
        this.setState((prevState) => ({
          sheet: [...prevState.sheet, formattedItem],
        }));

        // this.setState({
        //     sheet: this.state.sheet.concat(
        //         []
        //     )
        // });
        // // console.log("hit")
      });
    });
  }

  patientsPrePro = new Promise((resolve, reject) => {
    this.setState({
      sheetPat: [],
    });
    // prep all visits for download
    this.props.patients.forEach((patient) => {
      let formattedItem = [
        patient.intake.general.firstName +
          " " +
          patient.intake.general.lastName,
        patient.intake.general.addressStreet +
          ", " +
          patient.intake.general.addressCity +
          ", " +
          patient.intake.general.addressPostal,
        patient.intake.general.cellPhone,
        patient.intake.general.email,
      ];

      this.setState((prevState) => ({
        sheetPat: [...prevState.sheetPat, formattedItem],
      }));
    });
  }, Promise.resolve());

  // patientsPrePro() {
  //     this.setState({
  //         sheetPat: [],
  //     })
  //     // prep all visits for download
  //     // console.log("patient props", this.props.patients)
  //     this.props.patients.forEach(
  //         patient => {
  //             let formattedItem = [
  //                 patient.intake.general.firstName + " " + patient.intake.general.lastName,
  //                 patient.intake.general.addressStreet + ", " + patient.intake.general.addressCity + ", " + patient.intake.general.addressPostal,
  //                 patient.intake.general.cellPhone,
  //                 patient.intake.general.email,
  //             ]

  //             this.setState(prevState => ({
  //                 sheetPat: [...prevState.sheetPat, formattedItem]
  //             }))

  //         }
  //     ).then(() => { this.csvLink.current.link.click() })
  //     // console.log("this.state.sheetPat", this.state.sheetPat);

  // }

  componentDidMount() {
    this.props.fetchPatients(); //.then(() => {this.patientsPrePro()})
    this.props.fetchVisits(); //.then(() => {this.visitsPrePro()})
  }

  componentWillReceiveProps(nextState) {
    // console.log("next state", nextState.visits);
    this.setState({
      patients: nextState.patients,
      visits: nextState.visits,
    });
  }

  handleDownload = () => {
    // console.log(this.state.sheet);
    // console.log(this.state.acupunctureSheet);
  };

  handleDownloadPatients = () => {
    this.patientsPrePro().then(() => {
      this.csvLink.current.link.click();
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
    // // console.log(this.state.sheetPat);
    // // console.log(this.props.visits);
    // if (this.state.sheetPat) {
    //     // console.log("hit")
    //     this.patientsPrePro()
    // }
    // if(!this.props.visits){
    //     this.visitsPrePro()
    // }
  };

  routeChangePatient = (id) => {
    const { history } = this.props;
    history.push("patient/" + id);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.admin.permissions === "Administrator" ||
        this.props.admin.permissions === "Developer" ? (
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="row-reverse"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Autocomplete
                  id="searchAuto"
                  size="small"
                  options={this.props.patients}
                  getOptionLabel={(option) =>
                    option.intake.general.firstName +
                    " " +
                    option.intake.general.lastName
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      <div>
                        <Typography variant="body1">
                          {option.intake.general.firstName +
                            " " +
                            option.intake.general.lastName}
                        </Typography>
                        <Typography variant="caption">
                          {option.intake.general.cellPhone}
                        </Typography>
                      </div>
                    </React.Fragment>
                  )}
                  style={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search" variant="outlined" />
                  )}
                  onChange={(event, newValue) =>
                    this.handleSearchChange(newValue)
                  }
                  value={this.state.search}
                />
              </Grid>
            </Grid>
            <hr
              style={{
                borderTopColor: "#cccccc",
                borderTop: 1,
                marginTop: 40,
                marginBottom: 40,
              }}
            />

            <Grid
              container
              justify="left"
              spacing={5}
              style={{ paddingLeft: 70, paddingRight: 70 }}
            >
              {this.state.patients
                .sort((a, b) =>
                  a.intake.general.firstName.localeCompare(
                    b.intake.general.firstName
                  )
                )
                .map((patient) => (
                  <Grid key={patient._id} item>
                    <Card className={classes.root} variant="outlined">
                      <CardContent>
                        <Typography
                          variant="overline"
                          style={{ fontSize: 14 }}
                          color="textSecondary"
                          gutterBottom
                        >
                          patient
                        </Typography>
                        <Typography variant="h5" component="h2">
                          {patient.intake.general.firstName}{" "}
                          {patient.intake.general.lastName}
                        </Typography>
                        <Typography
                          style={{ marginBottom: 12 }}
                          color="textSecondary"
                        >
                          {patient.intake.general.cellPhone}
                        </Typography>
                        {/* <Typography variant="body2" component="p">
                                            well meaning and kindly.
                                            <br />
                                            {'"a benevolent smile"'}
                                        </Typography> */}
                      </CardContent>
                      <CardActions>
                        <Button
                          onClick={this.routeChangePatient.bind(
                            this,
                            patient._id
                          )}
                          size="small"
                        >
                          Visit Profile
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            <div className={classes.exampleWrapper}>
              <SpeedDial
                ariaLabel="SpeedDial"
                className={classes.speedDial}
                hidden={false}
                icon={<SpeedDialIcon />}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                open={this.state.open}
                direction={"up"}
              >
                {/* {this.state.actions.map(action => ( */}
                <SpeedDialAction
                  key={"Download Visits"}
                  icon={<SaveIcon style={{ marginTop: 4 }} />}
                  tooltipTitle={"Download Visits"}
                  onClick={this.getVisitData}
                />
                <SpeedDialAction
                  key={"Download Patients"}
                  icon={
                    <AssignmentIcon style={{ marginTop: 6 }} />

                    // <ExcelFile filename="Patients" element={}>
                    //     <ExcelSheet dataSet={
                    //         [{
                    //             columns: this.state.columnsPat,
                    //             data: this.state.sheetPat,
                    //         }]
                    //     } name="All Patients" />
                    // </ExcelFile>
                  }
                  tooltipTitle={"Download Patients"}
                  onClick={this.getPatientData}
                />
                <SpeedDialAction
                  key={"This Week's Payroll"}
                  icon={<AccountBalanceWalletIcon style={{ marginTop: 4 }} />}
                  tooltipTitle={"This Week's Payroll"}
                  onClick={this.getPractitionerWeekData}
                />
                {/* ))} */}
              </SpeedDial>
              <CSVLink
                data={this.state.patientsCSV}
                headers={this.state.columnsPat}
                filename="patients.csv"
                className="hidden"
                ref={this.csvLink}
                target="_blank"
              />
              {this.state.downloadReady && (
                <ExcelFile
                  filename="Patient Visits"
                  className="hidden"
                  hideElement="true"
                  ref={this.ExcelLink}
                >
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.sheet,
                      },
                    ]}
                    name="Summary"
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.acupunctureSheet,
                      },
                    ]}
                    name="Acupuncture"
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.osteopathySheet,
                      },
                    ]}
                    name="Osteopathy"
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.massageSheet,
                      },
                    ]}
                    name="Massage"
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.herbsSheet,
                      },
                    ]}
                    name="Herbs"
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.productsSheet,
                      },
                    ]}
                    name="Products"
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.otherSheet,
                      },
                    ]}
                    name="Other"
                  />
                </ExcelFile>
              )}

              {this.state.downloadPayrollReady && (
                <ExcelFile
                  filename="Practitioner Payroll"
                  className="hidden"
                  hideElement="true"
                  ref={this.ExcelLinkPayroll}
                >
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_one,
                      },
                    ]}
                    name={
                      this.props.admins[0]
                        ? this.props.admins[0].name
                        : "Empty Practitioner 0"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_two,
                      },
                    ]}
                    name={
                      this.props.admins[1]
                        ? this.props.admins[1].name
                        : "Empty Practitioner 1"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_three,
                      },
                    ]}
                    name={
                      this.props.admins[2]
                        ? this.props.admins[2].name
                        : "Empty Practitioner 2"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_four,
                      },
                    ]}
                    name={
                      this.props.admins[3]
                        ? this.props.admins[3].name
                        : "Empty Practitioner 3"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_five,
                      },
                    ]}
                    name={
                      this.props.admins[4]
                        ? this.props.admins[4].name
                        : "Empty Practitioner 4"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_six,
                      },
                    ]}
                    name={
                      this.props.admins[5]
                        ? this.props.admins[5].name
                        : "Empty Practitioner 5"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_seven,
                      },
                    ]}
                    name={
                      this.props.admins[6]
                        ? this.props.admins[6].name
                        : "Empty Practitioner 6"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_eight,
                      },
                    ]}
                    name={
                      this.props.admins[7]
                        ? this.props.admins[7].name
                        : "Empty Practitioner 7"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_nine,
                      },
                    ]}
                    name={
                      this.props.admins[8]
                        ? this.props.admins[8].name
                        : "Empty Practitioner 8"
                    }
                  />
                  <ExcelSheet
                    dataSet={[
                      {
                        columns: this.state.columns,
                        data: this.state.payroll_ten,
                      },
                    ]}
                    name={
                      this.props.admins[9]
                        ? this.props.admins[9].name
                        : "Empty Practitioner 9"
                    }
                  />
                </ExcelFile>
              )}
            </div>
          </ThemeProvider>
        ) : (
          <div> There is no content on this page. </div>
        )}
      </div>
    );
  }
}

// function matchDispatchToProps(dispatch) {
//     return bindActionCreators({ submitLogin: submitLogin, isLoggedIn: isLoggedIn },
//         dispatch);
// }

function mapStateToProps(state) {
  return {
    patients: state.patients,
    visits: state.visits,
    admins: state.admins,
    admin: state.admin,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
    fetchAdmins,
  })
)(withRouter(Patients));
